import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {RestApiService} from '../../_services/rest-api.service';
import {finalize} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {IdentityService} from '../../_services/identity.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {BehaviorSubject} from 'rxjs';
import {UIService} from 'src/app/_services/ui.service';
import {SigningProcessService} from '../../_services/signing-process.service';
import {TitleService} from '../../_services/title.service';
import {Router} from '@angular/router';
import {Routes} from 'src/app/_enums/routes.enum';

import {StyleServiceService} from '../../_services/style-service.service';
import {ErrorPageService} from 'src/app/_services/error-page.service';
import {AuthorizationService} from 'src/app/_services/authorization.service';
import {DialogService} from '../../_services/dialog.service';
import {DialogComponent} from 'src/app/component/dialog/dialog.component';
import {Step} from '../../_enums/step.enum';
import {LogType} from '../../_enums/log-type.enum';

@Component({
	selector: 'app-creditos',
	templateUrl: './creditos.component.html',
	styleUrls: ['./creditos.component.css'],
})
export class CreditosComponent implements OnInit {
	public useCredilowStyle: boolean;
	@Output() public backToHome: EventEmitter<undefined> = new EventEmitter<undefined>();
	@ViewChild(MatSort) sort: MatSort;
	public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public commerces: any;
	public comercioSelected: any;
	public disabled: boolean = false;

	public indexi: number;
	public indexj: number;

	constructor(
		public styleService: StyleServiceService,

		private readonly restApiService: RestApiService,
		private readonly dialog: MatDialog,
		private readonly identitySerivce: IdentityService,
		private readonly signingProcess: SigningProcessService,
		private readonly uIService: UIService,
		private titleService: TitleService,
		private router: Router,
		private errorPageService: ErrorPageService,
		private authorizationService: AuthorizationService,
		private dialogService: DialogService
	) {}

	public ngOnInit(): void {
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		});
		this.titleService.updateTitle('Créditos Por vendedor');
		this.getCreditos();
	}

	public getCreditos(): void {
		this.loading.next(true);
		this.restApiService
			.getCreditos()
			.pipe(finalize(() => this.loading.next(false)))
			.subscribe({
				next: (data: any) => {
					if (data.length >= 1) this.comercioSelected = data[0];
					this.commerces = data;
					this.disabled = data.length === 1;
				},
				error: (error: HttpErrorResponse) => {
					this.uIService.responseLog('getCreditos - ERROR', error);
					this.errorPageService.setError(null, error.error.message, error.error.code);
				},
			});
	}

	public goBack(): void {
		this.router.navigate([Routes.Home]);
	}

	public getNeutralSelfie(identityServiceId: string) {
		this.loading.next(true);
		this.identitySerivce
			.getNeutralSelfie(identityServiceId)
			.pipe(finalize(() => this.loading.next(false)))
			.subscribe({
				next: (data: any) => {
					//retorna 204 si no hay nada
					if (data) {
						this.selfieDialog(data.imageUrl);
					} else {
						this.uIService.responseLog('getNeutralSelfie - ERROR', data);
						this.uIService.errorMessage('Error!', 'No se pudo obtener la selfie');
					}
				},
				error: (error: HttpErrorResponse) => {
					console.log(error);
				},
			});
	}

	private selfieDialog(selfieUrl: string): void {
		const dialogRef = this.dialogService.openDialog(DialogComponent, {
			data: selfieUrl,
			buttonLabel: 'Cerrar',
			selfie: true,
		});
	}

	public signCallCenter(authId: number): void {
		this.loading.next(true);
		this.signingProcess
			.signCallCenter(authId)
			.pipe(finalize(() => this.loading.next(false)))
			.subscribe({
				next: (data: any) => {
					this.uIService.responseLog('signCallCenter - OK', data);
					this.uIService.successMessage('Éxito!', 'La aprobación está lista para firma en papel');
				},
				error: (error: HttpErrorResponse) => {
					this.uIService.responseLog('signCallCenter - ERROR', error);
					this.uIService.errorMessage('Error!', error.error.message);
				},
			});
	}

	public sendMutuoToVendedor(authId: number): void {
		this.loading.next(true);
		this.signingProcess
			.sendMutuoToVendedor(authId)
			.pipe(finalize(() => this.loading.next(false)))
			.subscribe({
				next: (data: any) => {
					this.uIService.responseLog('sendMutuoToVendedor - OK', data);
					this.uIService.successMessage('Éxito!', 'Se ha enviado el mutuo al e-mail del vendedor');
				},
				error: (error: HttpErrorResponse) => {
					this.uIService.responseLog('sendMutuoToVendedor - ERROR', error);
					this.uIService.errorMessage('Error! Por favor reintentar', error.error.message);
				},
			});
	}

	public toggleCredit(indexi: number, indexj: number): void {
		if (this.indexi === indexi && this.indexj === indexj) {
			this.indexi = undefined;
			this.indexj = undefined;
		} else {
			this.indexi = indexi;
			this.indexj = indexj;
		}
	}

	public docusign(idAprobacion: number, validationId: string): void {
		this.loading.next(true);
		this.signingProcess
			.postSignMethod(idAprobacion, validationId)
			.pipe(finalize(() => this.loading.next(false)))
			.subscribe({
				next: (data: any) => {
					this.restApiService.monitoringLog({authId: idAprobacion, step: Step.VerifyClient, logType: LogType.Flujo}).subscribe();
					this.uIService.responseLog('postSignMethod - OK', data);
					this.informativeDialog();
				},
				error: (error: HttpErrorResponse) => {
					this.restApiService.monitoringLog({authId: idAprobacion, step: Step.VerifyClient, logType: LogType.Consola, error}).subscribe();
					this.uIService.responseLog('ERROR', error);
					this.uIService.errorMessage('Error!', error.error.message);
				},
			});
	}

	public showDocusign(credit) {
		return credit.postSeleccionPlanTerminado && !credit.metodoVerificacionCliente;
	}

	public showFirmaEnPapel(credit) {
		return (
			(credit.postSeleccionPlanTerminado && !credit.metodoVerificacionCliente) ||
			(credit.metodoVerificacionCliente == 'WEB' && !credit.tycAceptados)
		);
	}

	public showSendMutuo(credit) {
		return credit.postSeleccionPlanTerminado && credit.metodoVerificacionCliente == 'PAPER';
	}

	public informativeDialog(): void {
		const dialogRef = this.dialogService.openDialog(DialogComponent, {
			data: 'Se envió un e-mail; seguí el proceso con el dispositivo del cliente.',
			buttonLabel: 'Cerrar',
		});
	}
}
