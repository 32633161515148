import {DocumentSide} from '../_enums/document-side.enum';

export class Document {
	private _side: DocumentSide;
	private _recognizedDocument: boolean;

	public get side() {
		return this._side;
	}

	public set side(side: DocumentSide) {
		this._side = side;
	}

	public set recognizedDocument(recognizedDocument: boolean) {
		this._recognizedDocument = recognizedDocument;
	}

	public isRecognizedDocument(): boolean {
		return this._recognizedDocument;
	}

	public isSideFront(): boolean {
		return this.side === DocumentSide.Front;
	}

	public isSideBack(): boolean {
		return this.side === DocumentSide.Back;
	}
}
