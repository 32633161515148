export enum OnboardingStep {
	Home = 0,
	DocumentPhoto = 1,
	PersonalData = 2,
	References = 3,
	HomeData = 4,
	ClientData = 5,
	AmountRequested = 6,
	PaymentPlan = 7,
	ProductSpecification = 8,
	ClientEmail = 9,
	Authorization = 10,
	Sign = 11,
}
