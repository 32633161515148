<div class="signaturePadTyc h-100 d-flex flex-column">
	<div>
		<div>
			<div class="text">
				<h6>Monto Solicitado:</h6>
				<span style="margin-top: -2px">{{ signingProcess.value.capital | money }}</span>
			</div>
		</div>
		<div>
			<div class="text">
				<h6>Plan:</h6>
				<span style="margin-top: -2px">
					{{ signingProcess.value.cantidad_cuotas }} cuotas de
					{{ signingProcess.value.monto_cuota | money }}
				</span>
			</div>
		</div>
		<div>
			<div class="text">
				<h6>Cuenta:</h6>
				<span style="margin-top: -2px">{{ signingProcess.value.metodo_retiro }}</span>
			</div>
		</div>
	</div>
	<div>
		<signature-pad #signature [options]="signaturePadOptions" class="signaturePad" id="signature-pad"></signature-pad>
		<span>Firme arriba</span>
		<div class="signatureButtons mt-3">
			<button mat-raised-button color="primary" [disabled]="confirmedSignature" (click)="drawClear()">Borrar</button>
			<button
				mat-raised-button
				color="primary"
				style="margin-left: 2%"
				[disabled]="confirmedSignature"
				id="draw-complete-btn"
				(click)="drawComplete()"
			>
				Confirmar
			</button>
		</div>
	</div>
	<section class="signatureTyc mt-3">
		<mat-checkbox class="mat-checkbox-text" [(ngModel)]="tycChecked" id="checkbox"></mat-checkbox>
		<span class="mat-checkbox-span">
			Acepto
			<a class="links" (click)="getMutualAgreement()">Términos y Condiciones</a>
			y
			<a class="links" (click)="showCredilowPolicies()">Políticas de Privacidad.</a>
		</span>
	</section>
	<div class="credilow-footer credilow-navigate mt-auto mb-3">
		<button
			class="credilow-next-button credilow-shadow"
			[ngClass]="{sanii: !useCredilowStyle}"
			[disabled]="!tycChecked || !confirmedSignature"
			(click)="complete()"
			id="next-confirm-btn"
			appEnterClick
		>
			Confirmar
		</button>
	</div>
</div>
<app-loader *ngIf="loading | async"></app-loader>
