<div class="fill position-relative d-flex flex-column">
	<form [formGroup]="nuevaSolicitud">
		<div class="text-center">
			<h6>Ingrese el celular del cliente</h6>
			<div class="mb-2">
				<input
					class="formInput"
					[ngClass]="{
						formInputValidation: isInSmsValidationProcess | async
					}"
					type="text"
					[readOnly]="mobilePhoneReadOnly | async"
					[ngClass]="{
						'is-invalid': isInValidInput(nuevaSolicitud.controls.mobilePhone)
					}"
					placeholder="Teléfono Celular"
					formControlName="mobilePhone"
					#tooltip="matTooltip"
					matTooltip="Ingrese Su Teléfono Celular (Ejemplo: 1194524123)"
					matTooltipHideDelay="1000"
					[ngStyle]="{
						width: isMobilePhoneInputValidated.value ? '80%' : '90%'
					}"
					inputmode="numeric"
					autocomplete="off"
				/>
				<span *ngIf="isMobilePhoneInputValidated | async; else sendSms" class="material-icons">check_circle</span>
				<ng-template #sendSms>
					<button class="small-btn p-2 send-btn" (click)="sendCode()" [disabled]="nuevaSolicitud.controls.mobilePhone.invalid">
						{{ (isInSmsValidationProcess | async) ? 'Reenviar SMS' : 'Enviar SMS de validación' }}
					</button>
				</ng-template>
			</div>
			<div class="mb-2">
				<input
					*ngIf="isInSmsValidationProcess | async"
					class="formInputCode"
					type="text"
					[ngClass]="{
						'is-invalid': isInValidInput(nuevaSolicitud.controls.smsCode)
					}"
					placeholder="Codigo de verificación"
					formControlName="smsCode"
					digitOnly
					inputmode="numeric"
					autocomplete="off"
				/>
				<button *ngIf="isInSmsValidationProcess | async" class="small-btn p-2 send-btn" (click)="validateSmsCode()">Validar</button>
			</div>
		</div>
		<div class="text-center">
			<h6>Ingrese el email del cliente</h6>
			<div class="mb-2">
				<input
					class="formInput"
					[ngClass]="{
						formInputValidation: isInEmailValidationProcess | async
					}"
					type="text"
					[readOnly]="emailReadOnly | async"
					[ngClass]="{
						'is-invalid': isInValidInput(nuevaSolicitud.controls.email)
					}"
					placeholder="Email"
					formControlName="email"
					#tooltip="matTooltip"
					matTooltip="Ingrese Su Email (Ejemplo: suEmail@dominio.com)"
					matTooltipHideDelay="1000"
					[ngStyle]="{
						width: isEmailInputValidated.value ? '80%' : '90%'
					}"
				/>
				<span *ngIf="isEmailInputValidated | async; else sendEmailCode" class="material-icons">check_circle</span>
				<button *ngIf="canEditEmail | async" class="small-btn p-2 modify-btn" (click)="editEmailInput()">Modificar</button>
				<ng-template #sendEmailCode>
					<button class="small-btn p-2 send-btn" (click)="sendEmail()" [disabled]="nuevaSolicitud.controls.email.invalid">
						{{ (isInEmailValidationProcess | async) ? 'Reenviar e-mail' : 'Enviar e-mail de validación' }}
					</button>
				</ng-template>
			</div>
			<div class="mb-2">
				<input
					*ngIf="isInEmailValidationProcess | async"
					class="formInputCode"
					type="text"
					[ngClass]="{
						'is-invalid': isInValidInput(nuevaSolicitud.controls.emailCode)
					}"
					placeholder="Codigo de verificación"
					formControlName="emailCode"
					digitOnly
				/>
				<button *ngIf="isInEmailValidationProcess | async" class="small-btn p-2 send-btn" (click)="validateEmailCode()">Validar</button>
			</div>
		</div>
	</form>
</div>

<app-loader *ngIf="loading | async"></app-loader>
