import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {User} from '../_models/user.model';
import {UIService} from './ui.service';
import {Router} from '@angular/router';
import {LocalStorageService} from './local-storage.service';
import {Optional} from '../_models/optional.model';
import {Credentials} from '../_models/credentials.model';
import {EnvironmentService} from './enviroment.service';
import {AuthenticationToken} from '../_models/authentication-token.model';
import {TimerService} from './timer.service';
import {AuthorizationService} from './authorization.service';
import jwt_decode from 'jwt-decode';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
	private currentUserSubject: BehaviorSubject<Optional<User>>;
	public currentUser: Observable<Optional<User>>;
	private authToken: AuthenticationToken;

	constructor(
		private readonly http: HttpClient,
		private readonly authorizationService: AuthorizationService,
		private readonly timerService: TimerService,
		private readonly localStorageService: LocalStorageService,
		private readonly utils: UIService,
		private readonly envService: EnvironmentService,
		private readonly router: Router
	) {
		const optionalCurrentUser = this.localStorageService
			.getCurrentUser()
			.map((currentUser) => User.from(JSON.parse(<string>currentUser) as User));
		this.currentUserSubject = new BehaviorSubject<Optional<User>>(optionalCurrentUser);
		this.currentUser = this.currentUserSubject.asObservable();
	}

	public get currentUserValue(): Optional<User> {
		return this.currentUserSubject.value;
	}

	public login(username: string, password: string): Observable<User> {
		const url = `${this.envService.automaticAuthorizerURI()}/authenticate`;
		const data = new Credentials(username, password);

		return this.http.post<User>(url, data).pipe(
			map((user: User) => {
				this.localStorageService.setCurrentUser(JSON.stringify(user));
				this.localStorageService.setToken(user.token);
				this.currentUserSubject.next(Optional.of(User.from(user)));
				this.authToken = new AuthenticationToken(user.token);
				this.timerService.startRefreshTokenTimer(this.authToken.timeToExpire(), () => this.refreshToken().subscribe());
				return user;
			})
		);
	}

	public logout(): void {
		this.utils.clearNotifications();
		this.localStorageService.clearLocalStorage();
		this.currentUserSubject.next(Optional.empty());
		this.timerService.stopRefreshTokenTimer();
		this.authorizationService.resetAutorizacion();
		this.router.navigate(['./login']);
	}

	private refreshToken(): any {
		return this.http.post<any>(`${this.envService.identityServiceURI()}/authentication/refresh`, {}).pipe(
			map((data) => {
				this.localStorageService.setToken(data.token);
				this.authToken = new AuthenticationToken(data.token);
				this.timerService.startRefreshTokenTimer(this.authToken.timeToExpire(), () => this.refreshToken().subscribe());
				return data;
			})
		);
	}

	public hasPermission(permission: string): boolean {
		const jwt = localStorage.getItem('token');
		if (!jwt) {
			return false;
		}
		const decodedJwt = jwt_decode<{permissions: string[]}>(jwt);
		if (decodedJwt && decodedJwt.permissions && decodedJwt.permissions.includes(permission)) {
			return true;
		} else {
			return false;
		}
	}

	public tokenNotExpired(): boolean {
		const jwt = localStorage.getItem('token');
		if (!jwt) {
			this.logout();
			return false;
		}
		const decodedJwt = jwt_decode<{exp: number}>(jwt);
		const now = Date.now().valueOf() / 1000;
		if (decodedJwt.exp < now) {
			this.logout();
			return false;
		}
		return true;
	}
}
