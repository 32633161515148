export enum Step {
	CreateSolicitud = 'CreateSolicitud',
	TakePictureDniFront = 'TakePictureDniFront',
	TakePictureDniBack = 'TakePictureDniBack',
	AcceptPreviewDniPicture = 'AcceptPreviewDniPicture',
	PreValidateDNI = 'PreValidateDNI',
	ValidateDNI = 'ValidateDNI',
	StartDatosPersonales = 'StartDatosPersonales',
	StartSendValidationSMS = 'StartSendValidationSMS',
	SendValidationSMS = 'SendValidationSMS',
	SendCODESMSValidation = 'SendCODESMSValidation',
	NuevaSolicitudInicio = 'NuevaSolicitudInicio',
	NuevaSolicitud = 'NuevaSolicitud',
	StartValidarFuentes = 'StartValidarFuentes',
	ValidarFuentes = 'ValidarFuentes',
	StartReporteCrediticio = 'StartReporteCrediticio',
	ReporteCrediticio = 'ReporteCrediticio',
	StartMontoSolicitado = 'StartMontoSolicitado',
	MontoSolicitado = 'MontoSolicitado',
	StartPlanDePago = 'StartPlanDePago',
	PlanDePago = 'PlanDePago',
	StartEspecificacionProducto = 'StartEspecificacionProducto',
	EspecificacionProducto = 'EspecificacionProducto',
	StartClientEmailCellphone = 'StartClientEmailCellphone',
	StartSendValidationEmail = 'StartSendValidationEmail',
	SendValidationEmail = 'SendValidationEmail',
	SendCODEEmailValidation = 'SendCODEEmailValidation',
	ClientEmail = 'ClientEmail',
	StartHomeData = 'StartHomeData',
	HomeData = 'HomeData',
	StartReferencias = 'StartReferencias',
	Referencias = 'Referencias',
	StartSubirServicio = 'StartSubirServicio',
	SubirServicio = 'SubirServicio',
	StartSubirAdicional = 'StartSubirAdicional',
	SubirAdicional = 'SubirAdicional',
	StartAprobacionCheck = 'StartAprobacionCheck',
	PostSeleccionPlanAC = 'PostSeleccionPlanAC',
	StartVerifyClient = 'StartVerifyClient',
	VerifyClient = 'VerifyClient',
	Cancel = 'Cancel',
	StartStartSigningProcess = 'StartStartSigningProcess',
	StartSigningProcess = 'StartSigningProcess',
	StartNeutralSelfie = 'StartNeutralSelfie',
	PostNeutralSelfie = 'PostNeutralSelfie',
	NeutralSelfie = 'NeutralSelfie',
	StartAcceptTyC = 'StartAcceptTyC',
	AcceptTyC = 'AcceptTyC',
	SignatureComplete = 'SignatureComplete',
	GetMutuo = 'GetMutuo',
	StartProductReception = 'StartProductReception',
	ProductReception = 'ProductReception',
	ViewReciboProducto = 'ViewReciboProducto',

	StartDocusign = 'StartDocusign',

	BeforeMontoMaximo = 'BeforeMontoMaximo',
	MontoMaximo = 'MontoMaximo',

	BeforeFuentesVerificadas = 'BeforeFuentesVerificadas',
	FuentesVerificadas = 'FuentesVerificadas',
}
