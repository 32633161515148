import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {EnvironmentService} from '../_services/enviroment.service';
import {Endpoint} from '../_helpers/endpoint.support';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	private static readonly urlsToAnalize = ['/ac/actualizarReferencias', '/ac/actualizarDomicilio', '/ac/accept-tyc'];
	private uris: URL[];

	constructor(private readonly envService: EnvironmentService) {
		this.uris = ErrorInterceptor.urlsToAnalize.map((url) => Endpoint.join([this.envService.automaticAuthorizerURI(), url]));
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			tap(
				(event) => {
					if (event instanceof HttpResponse) {
						if (this.isValidRequestForInterceptor(event.url)) {
							if (event.body.status === 'error') {
								throw new HttpErrorResponse({
									headers: event.headers,
									status: 400,
									statusText: event.body.message,
									url: event.url,
									error: event.body.message,
								});
							}
						}
					}
				},
				(error) => {
					if (error instanceof HttpErrorResponse) {
						if (this.isValidRequestForInterceptor(error.url)) {
							// de PHP si rompe en los FormRequest el error viene en Object.values(error.error.errors)[0][0]
							// si rompe con un throw error, el error viene en error.message
							if (error.error) {
								if (error.error.errors) {
									throw new HttpErrorResponse({
										headers: error.headers,
										status: 400,
										statusText: Object.values(error.error.errors)[0][0]
											? Object.values(error.error.errors)[0][0]
											: error.statusText,
										url: error.url,
									});
								} else {
									throw new HttpErrorResponse({
										headers: error.headers,
										status: 400,
										statusText: error.error.message,
										url: error.url,
									});
								}
							}
						}
					}
				}
			)
		);
	}

	private isValidRequestForInterceptor(requestUrl: string): boolean {
		return this.uris.some((uri) => uri.toString() === requestUrl);
	}
}
