import {Component, ElementRef} from '@angular/core';
import {EnvironmentService} from './_services/enviroment.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent {
	public useCredilowStyle: boolean;
	title = 'Autorizador Automático';
	scripts: any;

	constructor(private readonly envService: EnvironmentService) {}

	ngOnInit() {
		
	}
}
