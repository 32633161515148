import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, finalize} from 'rxjs';
import {Routes} from 'src/app/_enums/routes.enum';
import {TitleService} from '../../_services/title.service';
import {Comercio} from '../../_models/comercio.model';

import {StyleServiceService} from '../../_services/style-service.service';
import {QRCodeComponent} from 'angularx-qrcode';
import {PDFDocument, rgb, StandardFonts} from 'pdf-lib';
import {saveAs} from 'file-saver';
import {RestApiService} from '../../_services/rest-api.service';
import {HttpErrorResponse} from '@angular/common/http';
import {UIService} from '../../_services/ui.service';

@Component({
	selector: 'app-qr',
	templateUrl: './qr.component.html',
	styleUrls: ['./qr.component.css'],
})
export class QrComponent implements OnInit {
	public useCredilowStyle: boolean;
	public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public qrCode: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
	public comercio: BehaviorSubject<Comercio> = new BehaviorSubject<Comercio>(undefined);

	@ViewChild('qrcodeElement') qrCodeElement: QRCodeComponent;

	constructor(
		public styleService: StyleServiceService,
		private titleService: TitleService,
		private router: Router,
		private restApiService: RestApiService,
		private uIService: UIService
	) {}

	public ngOnInit(): void {
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		});
		this.titleService.updateTitle('Código QR');
		this.loading.next(true);
	}

	public goBack(): void {
		this.router.navigate([Routes.Home]);
	}

	public selectComercio(comercio: Comercio): void {
		this.comercio.next(comercio);
		this.loading.next(false);
		this.qrCode.next(comercio.qr);
	}

	public saveAsImage() {
		const parentElement = this.qrCodeElement.qrcElement.nativeElement.querySelector('canvas').toDataURL('image/png');
		if (parentElement) {
			let blobData = this.convertBase64ToBlob(parentElement);
			const blob = new Blob([blobData], {type: 'image/png'});
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.download = 'QR Comercio - ' + this.comercio.getValue().Nombre;
			link.click();
		}
	}

	private convertBase64ToBlob(Base64Image: string) {
		// split into two parts
		const parts = Base64Image.split(';base64,');
		// hold the content type
		const imageType = parts[0].split(':')[1];
		// decode base64 string
		const decodedData = window.atob(parts[1]);
		// create unit8array of size same as row data length
		const uInt8Array = new Uint8Array(decodedData.length);
		// insert all character code into uint8array
		for (let i = 0; i < decodedData.length; ++i) {
			uInt8Array[i] = decodedData.charCodeAt(i);
		}
		// return blob image after conversion
		return new Blob([uInt8Array], {type: imageType});
	}

	async saveFlyer() {
		this.loading.next(true);
		this.restApiService.fetchPresignedURLpdfFlyerAndParams().subscribe({
			next: async (response: any) => {
				if (response.presignedUrl) {
					this.downloadPDFFromPresignedURL(response.presignedUrl,response.params);
				} else {
					this.loading.next(false);
					this.uIService.errorMessage('Error!', 'No se pudo obtener el flyer');
				}
			},
			error: (error: HttpErrorResponse) => {
				this.loading.next(false);
				this.uIService.responseLog('saveFlyer - ERROR', error);
				this.uIService.errorMessage('Error!', 'No se pudo obtener el flyer');
			},
		});
	}

	private async downloadPDFFromPresignedURL(presignedURL,params) {
		const existingPDFBytes = await fetch(presignedURL).then((res) => res.arrayBuffer());
		const qrCodeDataURL = this.qrCodeElement.qrcElement.nativeElement.querySelector('canvas').toDataURL('image/png');

		const pdfDoc = await PDFDocument.load(existingPDFBytes);
		const qrCodeImage = await pdfDoc.embedPng(qrCodeDataURL);

		const pages = pdfDoc.getPages();
		for (let i = 0; i < pages.length; i++) {
			const page = pages[i];
			const {width, height} = page.getSize();
			const qrCodeDims = qrCodeImage.scale(Number(params.scale) ?? 1); 
			page.drawImage(qrCodeImage, {
				x: width - qrCodeDims.width - (Number(params.width) ?? 140), 
				y: height - qrCodeDims.height - (Number(params.height) ?? 233), 
				width: qrCodeDims.width,
				height: qrCodeDims.height,
			});
		}

		const modifiedPDFBytes = await pdfDoc.save();
		const blob = new Blob([modifiedPDFBytes], {type: 'application/pdf'});
		saveAs(blob, 'flyer comercio - ' +this.comercio.getValue().Nombre+' .pdf');
		this.loading.next(false);
	}
}
