import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import {StyleServiceService} from 'src/app/_services/style-service.service';

@Component({
	selector: 'app-dialog',
	templateUrl: './dialog.component.html',
	styleUrls: ['./dialog.component.css'],
})
export class DialogComponent {
	public useCredilowStyle: boolean;
	constructor(
		public styleService: StyleServiceService,
		private sanitizer: DomSanitizer,

		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<DialogComponent>
	) {
	}

	closeDialog() {
		this.dialogRef.close();
	}

	onSecondButtonClicked() {
		if (this.data.secondButtonAction) {
			this.data.secondButtonAction();
		}
	}
}
