/**
 * @description
 *	Clase para almacenar los datos de logeo.
 */

export class Credentials {
	public username: string;
	public password: string;
	public constructor(username: string, password: string) {
		this.username = username;
		this.password = password;
	}
}
