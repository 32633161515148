<mat-toolbar *ngIf="siginingProcess.autorizada">
	<mat-toolbar-row>
		<span class="material-icons headerIcon">check_circle_outline</span>
	</mat-toolbar-row>
	<mat-toolbar-row>
		<span class="headerTitle">¡Listo!</span>
	</mat-toolbar-row>
	<mat-toolbar-row>
		<span class="headerSubTitle">Tu préstamo personal fue aprobado.</span>
	</mat-toolbar-row>
</mat-toolbar>
<mat-toolbar class="notAuthorized" *ngIf="!siginingProcess.autorizada">
	<mat-toolbar-row>
		<span class="material-icons headerIcon" style="color: black">error_outline</span>
	</mat-toolbar-row>
	<mat-toolbar-row>
		<span class="headerTitle" style="color: black">¡En camino!</span>
	</mat-toolbar-row>
	<mat-toolbar-row class="p-0">
		<span class="headerSubTitle" style="color: black; display: inline-block; line-height: 1.2; white-space: pre-wrap">
			Tu préstamo personal fue preaprobado.
			<br />
			Nos comunicaremos con vos para validar datos.
		</span>
	</mat-toolbar-row>
</mat-toolbar>
<div class="container" style="text-align: center">
	<div class="textMargin">
		<span>Apretá el botón de abajo para confirmar la recepcion del producto y avisale al vendedor</span>
	</div>
	<hr />
	<div class="textMargin">
		<span>{{ siginingProcess.comercioNombre }}</span>
		<br />
		<span>{{ siginingProcess.comercioDescripcion }}</span>
	</div>
	<div class="textMargin">
		<span>
			Número de solicitud
			<br />
			<b># {{ siginingProcess.authId }}</b>
		</span>
	</div>
	<div class="textMargin">
		<span>
			<b>$ {{ siginingProcess.capital }}</b>
			- {{ siginingProcess.cantidad_cuotas }} cuotas
		</span>
	</div>
	<div class="buttonMargin" *ngIf="!recivedProduct; else seeReceipt">
		<button mat-raised-button (click)="productRecived()" id="btn-recibi">
			<span class="material-icons">check</span>
			<span>Recibí El Producto</span>
		</button>
	</div>
	<ng-template #seeReceipt>
		<div class="buttonMargin">
			<button mat-raised-button (click)="recordReceiptProduct()" id="btn-ver-comp">
				<span class="material-icons">check</span>
				<span>Ver Comprobante</span>
			</button>
		</div>
	</ng-template>
</div>
<app-loader *ngIf="loading | async"></app-loader>
