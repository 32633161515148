<h1 ng-if="data.title" mat-dialog-title class="text-center">{{ data.title }}</h1>
<ng-container *ngIf="data.selfie; else otherData">
	<img [src]="data.data" alt="No se ha encontrado la imagen" width="100%" />
</ng-container>
<div mat-dialog-content>
	<ng-template #otherData>
		<div [innerHTML]="data.data" class="text-center p-1"></div>
	</ng-template>
</div>
<div mat-dialog-actions class="justify-content-center">
	<button
		mat-raised-button
		class="small-btn send-btn"
		id="dialogCloseBtn"
		[ngClass]="{'first-button--grey': data.secondButtonLabel}"
		(click)="closeDialog()"
	>
		{{ data.buttonLabel }}
	</button>
	<button *ngIf="data.secondButtonLabel" mat-raised-button class="small-btn second-btn" id="dialogSecondBtn" (click)="onSecondButtonClicked()">
		{{ data.secondButtonLabel }}
	</button>
</div>
