import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {UIService} from 'src/app/_services/ui.service';
import {Comercio} from '../../_models/comercio.model';
import {LocalStorageService} from '../../_services/local-storage.service';
import {RestApiService} from '../../_services/rest-api.service';

import {StyleServiceService} from 'src/app/_services/style-service.service';
import {FormControl} from '@angular/forms';
import {Subject, takeUntil} from 'rxjs';

@Component({
	selector: 'x-comercio-select',
	templateUrl: './comercio-select.component.html',
	styleUrls: ['./comercio-select.component.css'],
})
export class ComercioSelectComponent implements OnInit {
	public useCredilowStyle: boolean;
	public commerces: Comercio[];
	public selectedComercio: Comercio;
	public firstCommerce: Comercio;
	public disabled: boolean = false;
	public commerceFilterCtrl: FormControl = new FormControl();
	public originalCommerces: Comercio[];
	protected _onDestroy = new Subject<void>();

	@Output() public selectedChanges: EventEmitter<Comercio> = new EventEmitter<Comercio>();

	constructor(
		public styleService: StyleServiceService,

		private readonly restApiService: RestApiService,
		private readonly localStorageService: LocalStorageService,
		private readonly uIService: UIService
	) {}

	ngOnInit(): void {
		this.getComercios();
		this.commerceFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
			this.filterCommerces();
		});
	}

	ngOnDestroy() {
		this._onDestroy.next();
		this._onDestroy.complete();
	}

	protected filterCommerces() {
		let search = this.commerceFilterCtrl.value;
		if (!search) {
			this.commerces = this.originalCommerces.slice();
			return;
		} else {
			search = search.toLowerCase();
			this.commerces = this.originalCommerces.filter((commerce) => (commerce.Codigo + ' - ' + commerce.Nombre).toLowerCase().includes(search));
		}
	}

	private getComercios(): void {
		const commercesInLocalStorage = JSON.parse(this.localStorageService.getCommerces());
		if (this.localStorageService.getCommerces()) {
			this.setCommerces(commercesInLocalStorage);
		} else {
			this.restApiService.getComercios().subscribe({
				next: (commerces: Comercio[]) => {
					this.setCommerces(commerces);
					this.localStorageService.setCommerces(JSON.stringify(commerces));
				},
				error: (error: HttpErrorResponse) => {
					this.uIService.responseLog('getComercios - ERROR', error);
					this.uIService.errorMessage('Error!', error.error.message);
				},
			});
		}
	}

	private setCommerces(commerces: Comercio[]): void {
		if (commerces.length >= 1) {
			this.firstCommerce = commerces[0];
			this.selectedComercio = commerces[0];
			this.selectedChanges.emit(commerces[0]);
		}
		this.disabled = commerces.length === 1;
		this.commerces = commerces;
		this.originalCommerces = commerces.slice();
	}

	public onSelectionChanged($event) {
		const selectedComercio = $event.value;
		if (selectedComercio) {
			this.selectedComercio = selectedComercio;
			this.firstCommerce = selectedComercio;
			this.selectedChanges.emit(selectedComercio);
			this.commerceFilterCtrl.setValue(selectedComercio.Nombre);
		}
	}
}
