import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Routes} from 'src/app/_enums/routes.enum';

import {StyleServiceService} from 'src/app/_services/style-service.service';

@Component({
	selector: 'app-header-container',
	template: `
		<app-header *ngIf="!showHeader" showBlackLogo="showBlackLogo"></app-header>
	`,
})
export class HeaderContainerComponent implements OnInit {
	public useCredilowStyle: boolean;
	public showHeader = false;
	public showHeaderOptions = true;
	public showBlackLogo: boolean = true;

	constructor(public styleService: StyleServiceService, private router: Router, private activatedRoute: ActivatedRoute) {}

	ngOnInit() {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				const routeWithoutParams = this.router.url.split('?')[0];
				this.showHeader = event.url === Routes.Login || routeWithoutParams === Routes.SigningProcess || event.url === Routes.Install ;
				this.showBlackLogo = event.url === Routes.Home;
			}
		});
	}
}
