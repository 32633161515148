<div class="fill position-relative d-flex flex-column">
	<div class="row justify-content-center credilow-fuentes-verificadas-banner credilow-shadow">
		<p>Nombre: {{ cliente.nombre }} / DNI: {{ cliente.dni }}</p>
	</div>
	<div *ngIf="!cliente.nuevo" class="row flex-column flex-grow-1">
		<x-existing-client
			(filledExistentClientFields)="fillClient($event, false)"
			(filledNewClientFields)="fillClient($event, true)"
			class="flex-grow-1"
		></x-existing-client>
	</div>
	<div *ngIf="cliente.nuevo" class="row" id="x-new-client-div" style="align-self: center">
		<x-new-client (filledNewClientFields)="fillClient($event, true)"></x-new-client>
	</div>
</div>
<app-loader *ngIf="loading | async"></app-loader>
