import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Routes} from '../_enums/routes.enum';

@Injectable({
	providedIn: 'root',
})
export class ErrorPageService {
	public errorMessage: string;
	public errorCode: string;
	public errorTitle: string;
	public redirectTo: Routes;

	setError(errorTitle?: string, errorMessage?: string, errorCode?: string, redirectTo?: Routes) {
		this.errorMessage = errorMessage;
		this.errorCode = errorCode;
		this.errorTitle = errorTitle;
		this.redirectTo = redirectTo;
		this.router.navigate([Routes.Error]);
	}

	constructor(private router: Router) {}
}
