import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Comercio} from '../../_models/comercio.model';
import {RestApiService} from '../../_services/rest-api.service';
import {UIService} from '../../_services/ui.service';
import {Plan} from '../../_models/plan.model';
import {Simulacion} from '../../_models/simulacion.model';
import {ClientType} from 'src/app/_models/client-type.model';
import {ScoreLevel} from 'src/app/_models/score-level.model';
import {BehaviorSubject, interval, Subscription} from 'rxjs';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {debounce, finalize} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {CreditType} from 'src/app/_enums/credit-type.enum';
import {TitleService} from '../../_services/title.service';
import {Router} from '@angular/router';
import {Routes} from 'src/app/_enums/routes.enum';
import {StyleServiceService} from '../../_services/style-service.service';

@Component({
	selector: 'app-simulador',
	templateUrl: './simulador.component.html',
	styleUrls: ['./simulador.component.css'],
})
export class SimuladorComponent implements OnInit {
	public useCredilowStyle: boolean;
	public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public hideScore: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public hideEarnings: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public hideClientType: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public hideAmountRequest: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public hideAmmountAvailable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	public simulacion: Simulacion = new Simulacion();
	public planes: Plan[] = [];
	public tiposCliente: ClientType[] = [];
	public simulatorForm: UntypedFormGroup;
	public obs: Subscription;
	public clientTypeIndex: number;
	public newClient: boolean;
	public ammountAvailable: number;


	public readonly scoreLevel: ScoreLevel[] = [
		{value: 600, level: 'ALTO'},
		{value: 400, level: 'MEDIO'},
		{value: 0, level: 'BAJO'},
	];

	constructor(
		public styleService: StyleServiceService,
		private restApiService: RestApiService,
		private uIService: UIService,
		private titleService: TitleService,
		private router: Router
	) {}

	public ngOnInit(): void {
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		});
		this.titleService.updateTitle('Simulador');
		this.getTiposCliente();
		this.simulatorForm = new UntypedFormGroup({
			commerce: new UntypedFormControl(undefined, [Validators.required]),
			amountRequest: new UntypedFormControl(undefined, [Validators.required]),
			earnings: new UntypedFormControl(undefined, [Validators.required]),
			clientType: new UntypedFormControl(undefined, [Validators.required]),
			score: new UntypedFormControl(undefined, [Validators.required]),
			dni: new UntypedFormControl(undefined, [Validators.required, Validators.minLength(7), Validators.maxLength(8)]),
		});
		this.obs = this.simulatorForm.controls['dni'].valueChanges.pipe(debounce(() => interval(1000))).subscribe(() => this.getDatosCliente());
	}

	public ngOnDestroy(): void {
		this.obs.unsubscribe();
	}

	public goBack(): void {
		if (this.planes.length === 0) {
			this.router.navigate([Routes.Home]);
		}
		this.planes = [];
	}

	public selectComercio(commerce: Comercio): void {
		this.simulatorForm.controls.commerce.setValue(commerce);
		this.simulacion.comercioId = commerce.Id;
	}

	public selectedClientType(tipoCliente: ClientType): void {
		this.simulatorForm.controls.clientType.setValue(tipoCliente.Id);
		this.clientTypeIndex = this.tiposCliente.findIndex((x) => x.Id === tipoCliente.Id);

		if (tipoCliente.Id === CreditType.ReciboDeSueldo) {
			this.hideScore.next(true);
			if(this.newClient){
				this.hideScore.next(false);
				this.simulatorForm.controls.score.setValue(600);
			} else {
				this.simulatorForm.controls.score.reset();
			}
		} else {
			this.hideScore.next(false);
			this.hideEarnings.next(true);
			this.simulatorForm.controls.score.setValue(0);
			this.simulatorForm.controls.earnings.reset();
		}
	}

	public selectedScore(scoreLevel: ScoreLevel): void {
		this.simulatorForm.controls.score.setValue(scoreLevel.value);
	}

	private getTiposCliente(): void {
		this.restApiService.getTiposCliente().subscribe({
			next: (data: any) => {
				this.uIService.responseLog('getTiposCliente - OK', data);
				this.tiposCliente = data;
			},
			error: (error: HttpErrorResponse) => {
				this.uIService.responseLog('getTiposCliente - ERROR', error);
				this.uIService.errorMessage('Error!', 'No se han encontrado tipos de Clientes');
			},
		});
	}

	private getDatosCliente(): void {
		this.resetValues();
		this.restApiService.getDatosCliente(this.simulatorForm.controls.dni.value,this.simulacion.comercioId).subscribe({
			next: (data: any) => {
				this.uIService.responseLog('getDatosCliente - OK', undefined);
				this.hideClientType.next(true);
				this.hideAmountRequest.next(true);
				// si idtipocredito vuelve en null, significa que es nuevo
				if(!data.idTipoCredito){
					this.newClient = true;
					this.hideScore.next(false);
					this.simulatorForm.controls.score.setValue(600);
				}
				if (data.ingreso) {
					this.hideEarnings.next(false);
					this.simulatorForm.controls.earnings.setValue(Math.round(data.ingreso));
				} else {
					this.hideEarnings.next(true);
				}
				if (data.idTipoCredito) {
					this.simulatorForm.controls.clientType.setValue(data.idTipoCredito);
					//uno es un string, otro es un numero, se necesita == no ===
					if (data.idTipoCredito == CreditType.ReciboDeSueldo) {
						if (data.scoreVeraz) {
							this.simulatorForm.controls.score.setValue(data.scoreVeraz);
							this.hideScore.next(false);
						} else {
							this.hideScore.next(true);
						}
					} else {
						this.hideScore.next(false);
					}
					if (data.scoreVeraz) {
						this.simulatorForm.controls.score.setValue(data.scoreVeraz);
					} else {
						if(data.idTipoCredito != CreditType.ReciboDeSueldo){
							this.simulatorForm.get('score').setValidators(null);
							this.simulatorForm.get('score').updateValueAndValidity();
						} else{
							this.hideScore.next(false);
							this.simulatorForm.controls.score.setValue(600);
						}
					}
					this.clientTypeIndex = this.tiposCliente.findIndex((x) => x.Id === +data.idTipoCredito);
				}
				if(data.saldoDisponible){
					this.hideAmmountAvailable.next(true);
					this.ammountAvailable = data.saldoDisponible;
				}
			},
			error: (error: HttpErrorResponse) => {
				this.uIService.responseLog('getDatosCliente - ERROR', error);
				this.uIService.errorMessage('Error!', 'No se pudo encontrar al cliente');
			},
		});
	}

	public buscarPlanes(): void {
		this.loading.next(true);
		this.restApiService
			.buscarPlanes(
				this.simulatorForm.controls.clientType.value,
				this.simulatorForm.controls.earnings.value,
				this.simulatorForm.controls.commerce.value.Id,
				this.simulatorForm.controls.amountRequest.value,
				this.simulatorForm.controls.dni.value,
				this.simulatorForm.controls.score.value
			)
			.pipe(finalize(() => this.loading.next(false)))
			.subscribe({
				next: (data: any) => {
					this.uIService.responseLog('buscarPlanes - OK', data);
					this.planes = data;
					if (this.planes.length < 1) {
						this.uIService.errorMessage('Error!', 'No se han encontrado planes');
					}
				},
				error: (error: HttpErrorResponse) => {
					this.uIService.responseLog('buscarPlanes - ERROR', error);
				},
			});
	}

	private resetValues() {
		this.simulatorForm.controls.earnings.reset();
		this.simulatorForm.controls.amountRequest.reset();
		this.simulatorForm.controls.clientType.reset();
		this.clientTypeIndex = undefined;
		this.simulatorForm.controls.score.reset();
	}
}
