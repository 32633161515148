import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from './authentication.service';
import {Permission} from '../_enums/permission.enum';
@Injectable()
export class AdminUserGuardService {
	constructor(private authenticationService: AuthenticationService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
		const hasPermission = this.authenticationService.hasPermission(Permission.AdministrarVendedores);
		if (hasPermission) {
			return true;
		} else {
			this.authenticationService.logout();
			return false;
		}
	}
}
