<div>
	<mat-form-field class="dataSelect">
		<mat-label>{{ selectTitle }}</mat-label>
		<mat-select
			(selectionChange)="onSelectionChanged($event)"
			[value]="data[inputSelectedData]"
			disabled="{{ disabled }}"
		>
			<div *ngIf="data; else loadingData">
				<div *ngIf="data.length === 0; else optionsData">
					<small>
						<mat-option disabled class="dataNotFound">
							[NO SE HAN ENCONTRADO OPCIONES]
						</mat-option>
					</small>
				</div>
				<ng-template #optionsData>
					<mat-option *ngFor="let item of data; index as i" [value]="item">
						<small>{{ item[selectOptionKey] }}</small>
					</mat-option>
				</ng-template>
			</div>
			<ng-template #loadingData>
				<small><mat-option class="loadingdata" disabled>Cargando...</mat-option></small>
			</ng-template>
		</mat-select>
	</mat-form-field>
</div>
