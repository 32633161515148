import {Component, EventEmitter, Input, Output} from '@angular/core';

import {StyleServiceService} from 'src/app/_services/style-service.service';

@Component({
	selector: 'x-default-select',
	templateUrl: './default-select.component.html',
	styleUrls: ['./default-select.component.css'],
})
export class DefaultSelectComponent {
	public useCredilowStyle: boolean;
	
	@Output() public selectedData: EventEmitter<any> = new EventEmitter<any>();
	
	@Input() public disabled: boolean = false;
	@Input() public inputSelectedData: any;
	@Input() public data: any;
	@Input() public selectTitle: string;
	@Input() public selectOptionKey: string;

	constructor(public styleService: StyleServiceService) {}

	public onSelectionChanged($event) {
		this.selectedData.emit($event.value);
	}
}
