import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Sources} from '../_models/sources.model';

@Injectable({
	providedIn: 'root',
})
export class SourcesService {
	private fuentes: BehaviorSubject<Sources> = new BehaviorSubject<Sources>(new Sources());

	getFuentes(): Observable<Sources> {
		return this.fuentes.asObservable();
	}

	setFuentes(fuentes: Sources): void {
		this.fuentes.next(fuentes);
	}
}
