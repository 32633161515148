export class NuevaSolicitud {
	nuevo: boolean;
	dni: number;
	comercio: number;
	sexo: string;
	celular: number;
	fijo: number;
	empleo: boolean;
	olvidoTarjeta: boolean;
	olvidoTarjetaMotivo: string;
	olvidoTarjetaMotivoOtro: string;
	tarjeta: number;
	tipoSolicitud: number;
	origen: number;
	nombre: string;
	fechaNacimiento: Date | null;
	validationId: string;
	cantServicios: number;
	acceptedUnderCommerceRiskDate: Date | undefined;
}
