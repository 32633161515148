import {HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {BehaviorSubject} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {InputHide} from '../../_helpers/input-hide.support';
import {ValidationResultMessage} from '../../_enums/validation-result-message.enum';
import {ValidationResult} from '../../_enums/validation-result.enum';
import {Cliente} from '../../_models/cliente.model';
import {Comercio} from '../../_models/comercio.model';
import {Optional} from '../../_models/optional.model';
import {ClienteService} from '../../_services/cliente.service';
import {IdentityService} from '../../_services/identity.service';
import {OnboardingService} from '../../_services/onboarding-steps.service';
import {UIService} from '../../_services/ui.service';
import {NoValidatedInputsDialogComponent} from '../no-validated-inputs-dialog/no-validated-inputs-dialog.component';
import {PhoneValidator} from '../../_validator/mobile-phone.validator';
import {StyleServiceService} from 'src/app/_services/style-service.service';
import {RestApiService} from '../../_services/rest-api.service';
import {Step} from '../../_enums/step.enum';
import {LogType} from '../../_enums/log-type.enum';

@Component({
	selector: 'x-new-client',
	templateUrl: './new-client.component.html',
	styleUrls: ['./new-client.component.css'],
})
export class NewClientComponent implements OnInit {
	public useCredilowStyle: boolean;
	@Output() public filledNewClientFields: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();

	public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public cliente: Cliente;
	public nuevaSolicitud: UntypedFormGroup;

	public canEditMobilePhone: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public isMobilePhoneInputValidated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public mobilePhoneReadOnly: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		public styleService: StyleServiceService,

		private readonly formBuilder: UntypedFormBuilder,
		private readonly clienteService: ClienteService,
		private readonly uIService: UIService,
		private readonly onboardingService: OnboardingService,
		private readonly dialog: MatDialog,
		private readonly identityService: IdentityService,
		private restApiService: RestApiService
	) {}

	public ngOnInit(): void {
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		});
		this.clienteService.getCliente().subscribe({
			next: (cliente: Cliente) => (this.cliente = cliente),
			error: (error: Error) => console.log(error),
		});
		this.nuevaSolicitud = this.formBuilder.group({
			mobilePhone: [InputHide.fakeValue(this.cliente.celular, false), [Validators.required, PhoneValidator.mobile()]],
			homePhone: new UntypedFormControl(this.cliente.telefonoFijo, PhoneValidator.home()),
			commerce: new UntypedFormControl(undefined, [Validators.required]),
			blankJob: new UntypedFormControl(false, [Validators.required]),
			amountOfServices: new UntypedFormControl(undefined),
		});

		if (Optional.of(this.cliente.celular).isPresent()) {
			this.nuevaSolicitud.controls.mobilePhone.clearValidators();
		}

		const mobilePhoneValidated = this.cliente.validatedCelular
		this.canEditMobilePhone.next(Optional.of(this.cliente.celular).isPresent());
		this.isMobilePhoneInputValidated.next(mobilePhoneValidated);
		this.mobilePhoneReadOnly.next(mobilePhoneValidated);
	}

	public changeBlankJob(state: boolean): void {
		this.nuevaSolicitud.controls.blankJob.setValue(state);
		if (state) {
			this.nuevaSolicitud.controls.amountOfServices.reset();
		} else {
			this.nuevaSolicitud.controls.amountOfServices.setValue(0);
		}
	}

	public selectComercio(commerce: Comercio): void {
		this.nuevaSolicitud.controls.commerce.setValue(commerce);
		if (this.commerceOfferDniPlan()) {
			this.nuevaSolicitud.controls.amountOfServices.setValue(0);
		}
	}

	public commerceOfferDniPlan(): boolean {
		return (
			this.nuevaSolicitud.controls.commerce.valid &&
			!this.nuevaSolicitud.controls.blankJob.value &&
			this.nuevaSolicitud.controls.commerce.value.ofrecePlanDNINuevos === 1
		);
	}

	public changeAmountOfServices(amount: number): void {
		this.nuevaSolicitud.controls.amountOfServices.setValue(amount);
	}

	public editMobilePhoneInput(): void {
		this.canEditMobilePhone.next(false);
		this.isMobilePhoneInputValidated.next(false);
		this.mobilePhoneReadOnly.next(false);
		this.nuevaSolicitud.controls.mobilePhone.reset();
		this.nuevaSolicitud.controls.mobilePhone.setValidators([Validators.required,PhoneValidator.mobile()]);
		this.nuevaSolicitud.controls.mobilePhone.updateValueAndValidity(); 
		this.cliente.celular = undefined;
		this.cliente.validatedCelular = false;
		this.clienteService.setCliente(this.cliente);
	}

	public isInValidInput(formControl: AbstractControl): boolean {
		return formControl.touched && formControl.invalid;
	}

	public cancel(): void {
		this.restApiService.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.Cancel, logType: LogType.Flujo}).subscribe();
		this.onboardingService.restart();
		this.clienteService.restart();
	}

	public nextStep(): void {
		if(this.nuevaSolicitud.valid){
			this.filledNewClientFields.emit(this.nuevaSolicitud);
		}
	}

	// public sendCode(): void {
	// 	if (this.nuevaSolicitud.controls.mobilePhone.invalid) return;
	// 	this.loading.next(true);
	// 	this.restApiService
	// 		.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.StartSendValidationSMS, logType: LogType.Flujo})
	// 		.subscribe();
	// 	this.identityService
	// 		.validateMobilePhone(this.nuevaSolicitud.controls.mobilePhone.value, this.cliente.identityId)
	// 		.pipe(finalize(() => this.loading.next(false)))
	// 		.subscribe({
	// 			next: (data: any) => {
	// 				if (data.success) {
	// 					this.isInSmsValidationProcess.next(true);
	// 					this.canEditMobilePhone.next(true);
	// 					this.mobilePhoneReadOnly.next(true);
	// 					this.tupleSmsValidationId = data.id;
	// 					this.restApiService
	// 						.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.SendValidationSMS, logType: LogType.Flujo})
	// 						.subscribe();
	// 					this.uIService.successMessage('Importante!', 'Se ha enviado el código correctamente.');
	// 				} else {
	// 					this.restApiService
	// 						.monitoringLog({
	// 							creditFlowId: this.cliente.creditFlowId,
	// 							step: Step.SendValidationSMS,
	// 							logType: LogType.Consola,
	// 							error: data,
	// 						})
	// 						.subscribe();
	// 					this.uIService.responseLog('sendCode - ERROR', data.messages[0]);
	// 					this.uIService.errorMessage('Error!', data.messages[0]);
	// 				}
	// 			},
	// 			error: (error: HttpErrorResponse) => {
	// 				this.restApiService
	// 					.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.SendValidationSMS, logType: LogType.Consola, error})
	// 					.subscribe();
	// 				this.uIService.responseLog('sendCode - ERROR', error);
	// 				this.uIService.errorMessage('Error!', error.error._message);
	// 			},
	// 		});
	// }

	// public validateSmsCode(): void {
	// 	if (this.nuevaSolicitud.controls.smsCode.invalid) return;
	// 	this.loading.next(true);
	// 	const code = this.nuevaSolicitud.controls.smsCode.value;
	// 	this.identityService
	// 		.validateMobilePhoneCode(code, this.tupleSmsValidationId)
	// 		.pipe(finalize(() => this.loading.next(false)))
	// 		.subscribe({
	// 			next: (data: any) => {
	// 				if (data.success) {
	// 					this.isInSmsValidationProcess.next(false);
	// 					this.isMobilePhoneInputValidated.next(true);
	// 					this.uIService.responseLog('validarCodigoCelular - OK', data);
	// 					this.uIService.successMessage('Importante!', ValidationResultMessage[data.validationResult]);
	// 					this.cliente.celular = this.nuevaSolicitud.controls.mobilePhone.value;
	// 					this.clienteService.setCliente(this.cliente);
	// 					this.nuevaSolicitud.controls.mobilePhone.setValue(InputHide.fakeValue(this.cliente.celular, false));
	// 					this.nuevaSolicitud.controls.mobilePhone.clearValidators();
	// 					this.nuevaSolicitud.controls.mobilePhone.updateValueAndValidity();
	// 					this.restApiService
	// 						.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.SendCODESMSValidation, logType: LogType.Flujo})
	// 						.subscribe();
	// 				} else {
	// 					if (data.validationResult === ValidationResult.FAILED) {
	// 						this.uIService.errorMessage('Error!', ValidationResultMessage.FAILED);
	// 					}
	// 					if (data.validationResult === ValidationResult.NOT_FOUND) {
	// 						this.uIService.errorMessage('Error!', ValidationResultMessage.NOT_FOUND);
	// 					}
	// 					if (data.validationResult === ValidationResult.EXPIRATED_LINK) {
	// 						this.uIService.errorMessage('Error!', ValidationResultMessage.EXPIRATED_LINK);
	// 					}
	// 					this.uIService.responseLog('validateSmsCode - ERROR', data.validationResult);
	// 					this.restApiService
	// 						.monitoringLog({
	// 							creditFlowId: this.cliente.creditFlowId,
	// 							step: Step.SendCODESMSValidation,
	// 							logType: LogType.Consola,
	// 							error: data,
	// 						})
	// 						.subscribe();
	// 				}
	// 			},
	// 			error: (error: HttpErrorResponse) => {
	// 				this.restApiService
	// 					.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.SendCODESMSValidation, logType: LogType.Consola, error})
	// 					.subscribe();
	// 				this.uIService.responseLog('validateSmsCode - ERROR', error);
	// 				this.uIService.errorMessage('Error!', error.error._message);
	// 			},
	// 		});
	// }

	public invalidForm(): boolean {
		return this.nuevaSolicitud.invalid ;
	}

	public warningDialogOrNextStep(): void {
		this.cliente.nuevo = true;
		this.nextStep();
	}

	public warningDialog(): void {
		const dialogRef = this.dialog.open(NoValidatedInputsDialogComponent, {
			width: '500px',
		});

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (result) this.nextStep();
		});
	}
}
