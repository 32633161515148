<div class="preview-container text-center d-flex flex-column h-100">
	<div class="fill-10 credilow-text">¿Se ve bien?</div>
	<img [src]="imageSrc" class="preview-img" />
	<div class="button-container credilow-footer credilow-navigate space-between-buttons mt-4 mb-4">
		<div class="credilow-50">
			<button class="credilow-previous-button credilow-shadow" (click)="retake.emit()">Repetir</button>
		</div>
		<div class="credilow-50">
			<button class="credilow-next-button credilow-shadow" (click)="accept.emit()">Continuar</button>
		</div>
	</div>
</div>
