//MATERIAL
import {MatSliderModule} from '@angular/material/slider';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatStepperModule} from '@angular/material/stepper';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatNativeDateModule} from '@angular/material/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

//PIPES
import {LogPipe} from './_pipes/log.pipe';
import {CeilPipe} from './_pipes/ceil.pipe';
import {MoneyPipe} from './_pipes/money.pipe';

//COMPONENT
import {CreditosComponent} from './container/creditos/creditos.component';
import {DatosPersonalesComponent} from './container/datos-personales/datos-personales.component';
import {PlanDePagoComponent} from './container/plan-de-pago/plan-de-pago.component';
import {ReferenciasComponent} from './container/referencias/referencias.component';
import {MontoSolicitadoComponent} from './container/monto-solicitado/monto-solicitado.component';
import {AprobacionComponent} from './container/aprobacion/aprobacion.component';
import {SimuladorComponent} from './container/simulador/simulador.component';
import {VendedoresComponent} from './container/vendedores/vendedores.component';
import {ExistingClientComponent} from './component/existing-client/existing-client.component';
import {ComercioSelectComponent} from './component/comercio-select/comercio-select.component';
import {NoValidatedInputsDialogComponent} from './component/no-validated-inputs-dialog/no-validated-inputs-dialog.component';
import {HomeComponent} from './container/home/home.component';
import {QrComponent} from './container/qr/qr.component';
import {HeaderComponent} from './container/header/header.component';
import {FotoDniComponent} from './container/foto-dni/foto-dni.component';
import {ContainerComponent} from './container/container.component';
import {LoginComponent} from './login/login.component';
import {NewClientComponent} from './component/new-client/new-client.component';
import {ClientDataComponent} from './container/client-data/client-data.component';
import {LoaderComponent} from './loader/loader.component';
import {SignComponent} from './component/sign/sign.component';
import {SourcesHeaderComponent} from './component/sources-header/sources-header.component';
import {HomeDataComponent} from './component/home-data/home-data.component';
import {NeutralSelfieComponent} from './component/neutral-selfie/neutral-selfie.component';
import {SignatureComponent} from './component/signature/signature.component';
import {CreateOrEditFormComponent} from './container/vendedores/create-or-edit-form/create-or-edit-form.component';

//@ANGULAR
import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ServiceWorkerModule} from '@angular/service-worker';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule} from '@angular/common/http';
import {GoogleMapsModule} from '@angular/google-maps';

//SERVICES
import {QRCodeModule} from 'angularx-qrcode';
import {SourcesService} from './_services/sources.service';
import {AuthorizationService} from './_services/authorization.service';
import {AuthGuardService} from './_services/auth-guard.service';
import {AdminUserGuardService} from './_services/admin-user-guard.service';
import {VendedoresService} from './_services/vendedores.service';
import {TitleService} from './_services/title.service';
import {DialogService} from './_services/dialog.service';
import {StyleServiceService} from './_services/style-service.service';
import {ErrorPageService} from './_services/error-page.service';

//INTERCEPTORS
import {ErrorInterceptor} from './interceptor/error.interceptor';
import {AuthenticationInterceptor} from './interceptor/authentication.interceptor';

//APP
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

//ENV
import {environment} from '../environments/environment';

//EXTRA MODULES
import {DigitOnlyModule} from '@uiowa/digit-only';
import {ToastrModule} from 'ngx-toastr';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AngularSignaturePadModule} from '@almothafar/angular-signature-pad';
import {WebcamModule} from 'ngx-webcam';
import {ProductReceptionComponent} from './component/product-reception/product-reception.component';
import {SigningProcessContainerComponent} from './component/signing-process-container/signing-process-container.component';
import {DefaultSelectComponent} from './component/default-select/default-select.component';
import {ProductSpecificationComponent} from './component/product-specification/product-specification.component';
import {ErrorPageComponent} from './component/error-page/error-page.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {HeaderContainerComponent} from './container/header/header-container/header-container.component';
import {DialogComponent} from './component/dialog/dialog.component';
import {InstallPromptComponent} from './component/install-prompt/install-prompt.component';
import {ImagePreviewComponent} from './container/image-preview/image-preview.component';
import {Ng2ImgMaxModule} from 'ng2-img-max';
import {EnterClickDirective} from './_directive/enter-click.directive';
import {ClientEmailCellphoneComponent} from './component/client-email-cellphone/client-email-cellphone.component';
import { NgxMatSelectSearchModule, MatSelectSearchOptions, MAT_SELECTSEARCH_DEFAULT_OPTIONS } from 'ngx-mat-select-search';

@NgModule({
	imports: [
		CommonModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
		NgxMatSelectSearchModule,
		ReactiveFormsModule,
		DigitOnlyModule,
		BrowserAnimationsModule,
		ToastrModule.forRoot(),
		NgMultiSelectDropDownModule.forRoot(),
		FormsModule,
		MatSliderModule,
		MatSelectModule,
		MatTableModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatDialogModule,
		MatButtonModule,
		MatCheckboxModule,
		MatToolbarModule,
		QRCodeModule,
		AngularSignaturePadModule,
		MatStepperModule,
		MatTooltipModule,
		WebcamModule,
		QRCodeModule,
		NgbModule,
		MatMenuModule,
		MatGridListModule,
		MatNativeDateModule,
		MatSlideToggleModule,
		GoogleMapsModule,
		HttpClientJsonpModule,
		Ng2ImgMaxModule,
	],
	declarations: [
		AppComponent,
		LoginComponent,
		DatosPersonalesComponent,
		HeaderComponent,
		FotoDniComponent,
		ContainerComponent,
		ReferenciasComponent,
		MontoSolicitadoComponent,
		PlanDePagoComponent,
		LoaderComponent,
		CreditosComponent,
		AprobacionComponent,
		SimuladorComponent,
		HomeComponent,
		QrComponent,
		ExistingClientComponent,
		VendedoresComponent,
		NewClientComponent,
		ComercioSelectComponent,
		NoValidatedInputsDialogComponent,
		ClientDataComponent,
		SourcesHeaderComponent,
		HomeDataComponent,
		SignComponent,
		CeilPipe,
		LogPipe,
		MoneyPipe,
		NeutralSelfieComponent,
		SignatureComponent,
		ProductReceptionComponent,
		SigningProcessContainerComponent,
		DefaultSelectComponent,
		ProductSpecificationComponent,
		ErrorPageComponent,
		CreateOrEditFormComponent,
		HeaderContainerComponent,
		DialogComponent,
		InstallPromptComponent,
		ImagePreviewComponent,
		EnterClickDirective,
		ClientEmailCellphoneComponent,
	],
	providers: [
		AuthorizationService,
		SourcesService,
		CurrencyPipe,
		AuthenticationInterceptor,
		ErrorInterceptor,
		AuthGuardService,
		VendedoresService,
		TitleService,
		DialogService,
		AdminUserGuardService,
		StyleServiceService,
		ErrorPageService,
		{provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true},
		{
			provide: MAT_SELECTSEARCH_DEFAULT_OPTIONS,
			useValue: <MatSelectSearchOptions>{
				closeIcon: '',
				noEntriesFoundLabel: 'No se encontraron comercios',
				placeholderLabel: 'Buscar',
			},
		},
	],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
