import {Comercio} from './comercio.model';

export class Cliente {
	public dni: number;
	public nombre: string;
	public sexo: string;
	public fechaNacimiento: Date;
	public nuevo: boolean;
	public celular: number;
	public validatedCelular:boolean = false;
	public trabajoEnBlanco: boolean;
	public tarjeta: number;
	public email: string;
	public comercio: Comercio;
	public cantidadServicios: number;
	public telefonoFijo: number;
	public renovador: number;
	public validationId: string;
	public identityId: string;
	public tipo: string;
	public creditFlowId: number;
}
