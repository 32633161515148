<div>
	<div *ngIf="commerces; else loadingCommerce">
		<div *ngIf="originalCommerces.length === 0; else optionsCommerces">
			<div class="credilow-plan-cuota disabled">No se han encontrado comercios</div>
		</div>
		<ng-template #optionsCommerces>
			<mat-form-field class="commerceSelect">
				<mat-label>Comercios</mat-label>
				<mat-select
					(selectionChange)="onSelectionChanged($event)"
					[(value)]="selectedComercio"
					disabled="{{ disabled }}"
				>
					<mat-option>
						<ngx-mat-select-search  class="search-box" [formControl]="commerceFilterCtrl" >  
						</ngx-mat-select-search>
					</mat-option>
					
					<mat-option
						*ngFor="let commerce of commerces; index as i"
						[value]="commerce"
					>
						<small>{{ commerce.Codigo }} - {{ commerce.Nombre }}</small>
					</mat-option>
				</mat-select>
			</mat-form-field>
		</ng-template>
	</div>
	<ng-template #loadingCommerce>
		<small><mat-option class="loadingCommerces" disabled>Cargando...</mat-option></small>
	</ng-template>
</div>
