import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {UIService} from '../../_services/ui.service';
import {ClienteService} from '../../_services/cliente.service';
import {RestApiService} from '../../_services/rest-api.service';
import {Autorizacion} from '../../_models/autorizacion.model';
import {Cliente} from '../../_models/cliente.model';
import {PlanesService} from '../../_services/planes.service';
import {Simulacion} from '../../_models/simulacion.model';
import {AuthorizationService} from '../../_services/authorization.service';
import {OnboardingService} from '../../_services/onboarding-steps.service';
import {OnboardingStep} from '../../_enums/onboarding-step.enum';
import {finalize, take} from 'rxjs/operators';
import {HeaderStep} from '../../_enums/header-step.enum';
import {BehaviorSubject, Subscription} from 'rxjs';
import {TitleService} from '../../_services/title.service';

import {StyleServiceService} from '../../_services/style-service.service';
import {Step} from '../../_enums/step.enum';
import {LogType} from '../../_enums/log-type.enum';

@Component({
	selector: 'app-monto-solicitado',
	templateUrl: './monto-solicitado.component.html',
	styleUrls: ['./monto-solicitado.component.css'],
})
export class MontoSolicitadoComponent implements OnInit {
	public useCredilowStyle: boolean;
	@Output() public changeStepperState: EventEmitter<HeaderStep> = new EventEmitter<HeaderStep>();

	public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public autorizacion: Autorizacion = new Autorizacion();
	public cliente: Cliente = new Cliente();
	public amountForm: UntypedFormControl;

	private simulacion: Simulacion = new Simulacion();
	private clienteServiceSubscription: Subscription;

	constructor(
		public styleService: StyleServiceService,

		private readonly restApiService: RestApiService,
		private readonly autorizacionService: AuthorizationService,
		private readonly uIService: UIService,
		private readonly clienteService: ClienteService,
		private readonly planesService: PlanesService,
		private readonly onboardingService: OnboardingService,
		private titleService: TitleService
	) {
		this.amountForm = new UntypedFormControl(undefined, [Validators.required, Validators.min(0.01)]);
	}

	public ngOnInit(): void {
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		});
		this.titleService.updateTitle('Monto');
		this.autorizacionService.getAutorizacion().subscribe({
			next: (autorizacion: Autorizacion) => (this.autorizacion = autorizacion),
			error: (error: Error) => console.log(error),
		});
		this.clienteServiceSubscription = this.clienteService.getCliente().subscribe({
			next: (cliente: Cliente) => {
				this.cliente = cliente;
				this.restApiService.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.StartMontoSolicitado, logType:LogType.Flujo}).pipe(take(1)).subscribe();
			},
			error: (error: Error) => console.log(error),
		});
		if (this.simulacion.monto) {
			this.amountForm.setValue({requestedAmount: this.simulacion.monto});
		}
	}

	public continue(): void {
		if (this.amountForm.value.requestedAmount > this.autorizacion.montoMaximo) {
			this.uIService.errorMessage('Error', 'El monto solicitado no puede superar al monto máximo.');
			return;
		}
		this.loading.next(true);
		this.restApiService
			.solicitarMonto(this.amountForm.value, this.autorizacion.id)
			.pipe(
				finalize(() => {
					this.loading.next(false);
				})
			)
			.subscribe({
				next: (data: any) => {
					this.uIService.responseLog('solicitarMonto - OK', data);
					const montoAprobado = parseInt(data.monto, 10);
					const montoPedido = parseInt(this.amountForm.value, 10);
					if (montoAprobado > 0 && montoPedido <= montoAprobado) {
						this.planesService.setPlanes(data.planes);
						this.autorizacion.montoSolicitado = this.amountForm.value;
						this.autorizacion.tipoCredito = data.tipoCredito;
						this.autorizacionService.setAutorizacion(this.autorizacion);
						this.restApiService.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.MontoSolicitado, logType:LogType.Flujo}).subscribe();
						this.onboardingService.moveTo(OnboardingStep.PaymentPlan);
					} else {
						let mensaje = '';
						if (montoAprobado > 0) {
							mensaje = 'Monto solicitado no disponible. Por favor solicite un valor menor o igual a $' + montoAprobado;
						} else if(data.message){
							mensaje = data.message;
						} else {
							mensaje = 'El cliente no tiene más crédito disponible.';
						}
						if (data.authId) {
							if (data.dni) {
								mensaje = mensaje + ' (AA-' + data.authId + ' DNI: ' + data.dni + ')';
							} else {
								mensaje = mensaje + ' (AA-' + data.authId + ')';
							}
						}
						this.restApiService
							.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.MontoSolicitado, logType:LogType.Consola, error:mensaje})
							.subscribe();
						this.uIService.errorMessage('Error', mensaje);
					}
				},
				error: (error: Error) => {
					this.restApiService
						.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.MontoSolicitado, logType: LogType.Consola, error})
						.subscribe();
					this.uIService.responseLog('solicitarMonto - ERROR', error);
				},
			});
	}

	public cancel(): void {
		this.restApiService.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.Cancel, logType:LogType.Flujo}).subscribe();
		if (this.clienteServiceSubscription) {
			this.clienteServiceSubscription.unsubscribe();
		}
		this.onboardingService.restart();
		this.clienteService.restart();
	}

	public isInvalidForm(): boolean {
		return this.amountForm.touched && this.amountForm.invalid;
	}

	public ngOnDestroy(): void {
		// Unsubscribe from the clienteService subscription
		if (this.clienteServiceSubscription) {
		  this.clienteServiceSubscription.unsubscribe();
		}
	}
}
