import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class StyleServiceService {
	private useCredilowStyleSubject = new BehaviorSubject<boolean>(true);
	useCredilowStyle = this.useCredilowStyleSubject.asObservable();

	constructor() {}

	toggleStyle() {
		this.useCredilowStyleSubject.next(!this.useCredilowStyleSubject.value);
	}
}
