import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import {ComercioConVendedores} from '../_models/comercio-con-vendedores.model';
import {AuthenticationService} from './authentication.service';
import {RestApiService} from './rest-api.service';
import {UIService} from './ui.service';
import {VendedorLista} from '../_models/vendedor-lista.model';

@Injectable({
	providedIn: 'root',
})
export class VendedoresService {
	private commercesWithEmployees: ComercioConVendedores[];

	public commercesWithEmployeesChanged = new Subject<ComercioConVendedores[]>();
	public startedEditing = new Subject<number>();

	constructor(private restApiService: RestApiService, private uIService: UIService, private authenticationService: AuthenticationService) {
		this.getVendedores();
	}

	public getComerciosWithEmployees(): ComercioConVendedores[] {
		return this.commercesWithEmployees.slice();
	}

	public setCommercesWithEmployees(commercesWithEmployees: ComercioConVendedores[]) {
		this.commercesWithEmployees = commercesWithEmployees;
		this.commercesWithEmployeesChanged.next(this.commercesWithEmployees.slice());
	}

	public getVendedores(): Observable<ComercioConVendedores[]> {
		return this.restApiService.getVendedoresDeComercio().pipe(
			tap((data: ComercioConVendedores[]) => {
				this.setCommercesWithEmployees(data);
				this.uIService.responseLog('getVendedoresDeComercio - OK', data);
			}),
			catchError((error: HttpErrorResponse) => {
				this.uIService.responseLog('getComercios - ERROR', error);
				this.uIService.errorMessage('Error!', error.error.message);
				this.authenticationService.logout();
				return of([]);
			})
		);
	}

	public getVendedorByUserId(userId: number): VendedorLista | undefined {
		return this.commercesWithEmployees
			.reduce((acc, commerceWithEmployees) => {
				return acc.concat(commerceWithEmployees.vendedores);
			}, [] as VendedorLista[])
			.find((vendedorLista) => vendedorLista.userId === userId);
	}

	public editVendedor(vendedor: VendedorLista) {
		return this.restApiService.editVendedor(vendedor.userId, vendedor.vendedor, vendedor.cellphone, vendedor.email);
	}

	public createVendedor(username: string, name: string, email: string, cellphone: string) {
		return this.restApiService.createVendedor(username, name, email, cellphone);
	}
}
