import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Cliente} from '../_models/cliente.model';

@Injectable({
	providedIn: 'root',
})
export class ClienteService {
	private cliente: BehaviorSubject<Cliente> = new BehaviorSubject<Cliente>(new Cliente());

	getCliente(): Observable<Cliente> {
		return this.cliente.asObservable();
	}

	setCliente(cliente: Cliente): void {
		this.cliente.next(cliente);
	}

	restart(): void {
		this.cliente.next(new Cliente());
	}
}
