export enum InternalErrorCode {
	InvalidMAC = 'AA-0000-0010',
	InvalidValidationSelfie = 'IS-0000-0048',
	GenericError = 'IS-0000-0000',
	UserHasToUpdatePassword = 'AA-0000-0026',
	SameFrenteYDorso = 'IS-0001-0008',
	FrenteNotOk='IS-0001-0006',
	DorsoNotOk='IS-0000-0021',
	TwoFaces='IS-0001-0009',
	FallaOCR='IS-0000-0012',
	FallaSelfieRenaper='IS-0000-0013',
	DNIVencido='IS-0001-0010',
	SERVICE_DOWN='IS-0000-0002'
}
