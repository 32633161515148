<div class="d-flex">
	<div class="container d-flex flex-column h-100">
		<form #f="ngForm" (ngSubmit)="onSubmit(f)" class="h-100 position-relative d-flex flex-column">
			<div class="credilow-referencias">
				<h6>{{ editMode ? 'Editar Vendedor' : 'Crear Vendedor' }}</h6>
				<label for="username">Usuario</label>
				<input type="text" id="username" class="form-control" name="username" ngModel required [disabled]="editMode" />
				<label for="name">Nombre</label>
				<input
					type="text"
					id="name"
					class="form-control"
					name="name"
					ngModel
					required
					[ngClass]="{
						'is-invalid': isInvalidForm(editOrCreateUserForm.controls.name)
					}"
				/>
				<label for="cellphone">Teléfono</label>
				<input
					type="number"
					id="cellphone"
					class="form-control"
					name="cellphone"
					ngModel
					digitOnly
					[ngClass]="{
						'is-invalid': isInvalidForm(editOrCreateUserForm.controls.cellphone)
					}"
				/>
				<label for="email">Email</label>
				<input
					type="email"
					id="email"
					class="form-control"
					name="email"
					ngModel
					required
					[ngClass]="{
						'is-invalid': editOrCreateUserForm.controls.email.invalid && editOrCreateUserForm.controls.email.touched
					}"
				/>
				<div class="credilow-navigate space-between-buttons">
					<button
						class="credilow-next-button credilow-shadow"
						[ngClass]="{sanii: !useCredilowStyle}"
						type="submit"
						[disabled]="f.invalid || formIsNotTouchedOrUnchanged"
						id="createedit-btn"
					>
						{{ editMode ? 'Editar' : 'Crear' }}
					</button>
				</div>
			</div>
		</form>
		<button class="credilow-previous-button credilow-shadow" [ngClass]="{sanii: !useCredilowStyle}" (click)="goBack()">Volver</button>
	</div>
</div>
<app-loader *ngIf="loading | async"></app-loader>
