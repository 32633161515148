import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
	selector: 'app-image-preview',
	templateUrl: './image-preview.component.html',
	styleUrls: ['./image-preview.component.css'],
})
export class ImagePreviewComponent {
	@Input() imageSrc: string;
	@Output() retake = new EventEmitter<void>();
	@Output() accept = new EventEmitter<void>();
}
