import {HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators, UntypedFormControl} from '@angular/forms';
import {BehaviorSubject, finalize, isEmpty, Subscription, take} from 'rxjs';
import {OnboardingStep} from 'src/app/_enums/onboarding-step.enum';
import {ValidationResultMessage} from 'src/app/_enums/validation-result-message.enum';
import {ValidationResult} from 'src/app/_enums/validation-result.enum';
import {Autorizacion} from 'src/app/_models/autorizacion.model';
import {Cliente} from 'src/app/_models/cliente.model';
import {AuthorizationService} from 'src/app/_services/authorization.service';
import {ClienteService} from 'src/app/_services/cliente.service';
import {IdentityService} from 'src/app/_services/identity.service';
import {OnboardingService} from 'src/app/_services/onboarding-steps.service';
import {StyleServiceService} from 'src/app/_services/style-service.service';
import {UIService} from 'src/app/_services/ui.service';
import {LogType} from '../../_enums/log-type.enum';
import {Step} from '../../_enums/step.enum';
import {InputHide} from '../../_helpers/input-hide.support';
import {MonitoringLogData} from '../../_models/monitoringLogData.model';
import {Optional} from '../../_models/optional.model';
import {RestApiService} from '../../_services/rest-api.service';
import {TitleService} from '../../_services/title.service';
import {PhoneValidator} from '../../_validator/mobile-phone.validator';

@Component({
	selector: 'app-client-email-cellphone',
	templateUrl: './client-email-cellphone.component.html',
	styleUrls: ['./client-email-cellphone.component.css'],
})
export class ClientEmailCellphoneComponent {
	@Output() formChange = new EventEmitter<FormGroup>();
	@Output() mobilePhoneValidationChange = new EventEmitter<boolean>();
	@Output() emailValidationChange = new EventEmitter<boolean>();
	@Output() initialFormValidity: EventEmitter<boolean> = new EventEmitter<boolean>();

	nuevaSolicitud: FormGroup;
	public useCredilowStyle: boolean;

	public isInEmailValidationProcess: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public isEmailInputValidated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public emailReadOnly: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public canEditEmail: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private tupleEmailValidationId: number;

	public isInSmsValidationProcess: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public mobilePhoneReadOnly: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public isMobilePhoneInputValidated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public cliente: Cliente;
	private autorizacion: Autorizacion;
	private clienteServiceSubscription: Subscription;
	private tupleSmsValidationId: number;

	constructor(
		public styleService: StyleServiceService,
		private formBuilder: FormBuilder,
		private identityService: IdentityService,
		private readonly uIService: UIService,
		private readonly clienteService: ClienteService,
		private readonly onboardingService: OnboardingService,
		private readonly authorizationService: AuthorizationService,
		private titleService: TitleService,
		private restApiService: RestApiService
	) {}

	ngOnInit(): void {
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		});
		this.titleService.updateTitle('Fuentes y Datos Cliente');
		this.clienteServiceSubscription = this.clienteService
			.getCliente()
			.pipe(take(1))
			.subscribe({
				next: (cliente: Cliente) => {
					this.cliente = cliente;
					this.restApiService
						.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.StartClientEmailCellphone, logType: LogType.Flujo})
						.subscribe(() => {
							this.unsubscribe();
						});
				},
				error: (error: Error) => console.log(error),
			});
		this.authorizationService.getAutorizacion().subscribe({
			next: (autorizacion: Autorizacion) => (this.autorizacion = autorizacion),
			error: (error: Error) => console.log(error),
		});
		this.nuevaSolicitud = this.formBuilder.group({
			email: new UntypedFormControl(InputHide.fakeValue(this.cliente.email, true), [Validators.required, Validators.email]),
			emailCode: new UntypedFormControl(undefined),
			mobilePhone: [
				this.cliente.validatedCelular ? InputHide.fakeValue(this.cliente.celular, false) : this.cliente.celular,
				[Validators.required, PhoneValidator.mobile()],
			],
			smsCode: new UntypedFormControl(undefined),
		});
		if (!Optional.of(this.cliente.email).isPresent()) {
			this.nuevaSolicitud.controls.emailCode.setValidators(Validators.required);
		} else {
			this.nuevaSolicitud.controls.email.clearValidators();
		}
		if (!Optional.of(this.cliente.celular).isPresent()) {
			this.nuevaSolicitud.controls.smsCode.setValidators(Validators.required);
		} else {
			this.nuevaSolicitud.controls.mobilePhone.clearValidators();
		}
		this.nuevaSolicitud.controls.emailCode.updateValueAndValidity();
		this.nuevaSolicitud.controls.email.updateValueAndValidity();
		this.nuevaSolicitud.controls.smsCode.updateValueAndValidity();
		this.nuevaSolicitud.controls.mobilePhone.updateValueAndValidity();

		
		const mobilePhoneValidated = this.cliente.validatedCelular;
		this.isMobilePhoneInputValidated.next(mobilePhoneValidated);
		this.mobilePhoneValidationChange.emit(mobilePhoneValidated);
		this.mobilePhoneReadOnly.next(mobilePhoneValidated);
		
		const emailPreLoaded = Optional.of(this.cliente.email).isPresent();
		this.canEditEmail.next(emailPreLoaded);
		this.isEmailInputValidated.next(emailPreLoaded);
		this.emailValidationChange.emit(emailPreLoaded);
		this.emailReadOnly.next(emailPreLoaded);

		this.initialFormValidity.emit(!this.invalidForm());
		this.nuevaSolicitud.valueChanges.subscribe(() => {
			this.onFormChange();
		});
	}

	private unsubscribe(): void {
		if (this.clienteServiceSubscription && !this.clienteServiceSubscription.closed) {
			this.clienteServiceSubscription.unsubscribe();
		}
	}

	ngOnDestroy(): void {
		this.unsubscribe();
	}
	validateEmailCode(): void {
		if (this.nuevaSolicitud.controls.emailCode.invalid) return;
		this.loading.next(true);
		const code = this.nuevaSolicitud.controls.emailCode.value;
		this.identityService
			.validateEmailCode(code, this.tupleEmailValidationId)
			.pipe(finalize(() => this.loading.next(false)))
			.subscribe({
				next: (data: any) => {
					if (data.success) {
						this.restApiService
							.monitoringLog({
								creditFlowId: this.cliente.creditFlowId,
								step: Step.SendCODEEmailValidation,
								logType: LogType.Flujo,
							})
							.subscribe(() => {
								this.unsubscribe();
							});

						this.isInEmailValidationProcess.next(false);
						this.isEmailInputValidated.next(true);
						this.emailValidationChange.emit(true);
						this.uIService.responseLog('validarCodigoEmail - OK', data);
						this.uIService.successMessage('Importante!', ValidationResultMessage[data.validationResult]);
						this.cliente.email = this.nuevaSolicitud.controls.email.value;
						this.clienteService.setCliente(this.cliente);
						this.nuevaSolicitud.controls.email.setValue(InputHide.fakeValue(this.cliente.email, true));
						this.nuevaSolicitud.controls.email.clearValidators();
						this.nuevaSolicitud.controls.email.updateValueAndValidity();
						// ...
					} else {
						if (data.validationResult === ValidationResult.FAILED) {
							this.uIService.errorMessage('Error!', ValidationResultMessage.FAILED);
						}
						if (data.validationResult === ValidationResult.NOT_FOUND) {
							this.uIService.errorMessage('Error!', ValidationResultMessage.NOT_FOUND);
						}
						if (data.validationResult === ValidationResult.EXPIRATED_LINK) {
							this.uIService.errorMessage('Error!', ValidationResultMessage.EXPIRATED_LINK);
						}
						const logData: MonitoringLogData = {
							creditFlowId: this.cliente.creditFlowId,
							step: Step.SendCODEEmailValidation,
							logType: LogType.Flujo,
						};
						this.restApiService
							.monitoringLog({
								creditFlowId: this.cliente.creditFlowId,
								step: Step.SendCODEEmailValidation,
								logType: LogType.Consola,
								error: data.validationResult,
							})
							.subscribe();
						this.uIService.responseLog('validateEmailCode - ERROR', data.validationResult);
					}
				},
				error: (error: any) => {
					this.restApiService
						.monitoringLog({
							creditFlowId: this.cliente.creditFlowId,
							step: Step.SendCODEEmailValidation,
							logType: LogType.Consola,
							error: error.error,
						})
						.subscribe();
					this.uIService.responseLog('validateEmailCode - ERROR', error);
					this.uIService.errorMessage('Error!', error.error._message);
				},
			});
	}

	sendEmail(): void {
		if (this.nuevaSolicitud.controls.email.invalid) return;
		this.loading.next(true);
		this.restApiService
			.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.StartSendValidationEmail, logType: LogType.Flujo})
			.subscribe();
		this.identityService
			.validateEmail(this.nuevaSolicitud.controls.email.value, this.cliente.identityId)
			.pipe(finalize(() => this.loading.next(false)))
			.subscribe({
				next: (data: any) => {
					if (data.success) {
						this.tupleEmailValidationId = data.id;
						this.isInEmailValidationProcess.next(true);
						this.emailReadOnly.next(true);
						this.canEditEmail.next(true);
						this.restApiService
							.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.SendValidationEmail, logType: LogType.Flujo})
							.subscribe();

						this.uIService.successMessage('Importante!', 'Se ha enviado el código correctamente.');
					} else {
						this.restApiService
							.monitoringLog({
								creditFlowId: this.cliente.creditFlowId,
								step: Step.SendValidationEmail,
								logType: LogType.Consola,
								error: data,
							})
							.subscribe();
						this.uIService.responseLog('sendCode - ERROR', data.messages[0]);
						this.uIService.errorMessage('Error!', data.messages[0]);
					}
				},
				error: (error: HttpErrorResponse) => {
					this.restApiService
						.monitoringLog({
							creditFlowId: this.cliente.creditFlowId,
							step: Step.SendValidationEmail,
							logType: LogType.Consola,
							error: error,
						})
						.subscribe();
					this.uIService.responseLog('sendEmail - ERROR', error);
					this.uIService.errorMessage('Error!', error.error._message);
				},
			});
	}

	editEmailInput(): void {
		this.canEditEmail.next(false);
		this.isEmailInputValidated.next(false);
		this.emailValidationChange.emit(false);
		this.isInEmailValidationProcess.next(false);
		this.emailReadOnly.next(false);
		this.nuevaSolicitud.controls.email.reset();
		this.nuevaSolicitud.controls.email.setValidators(Validators.email);
		this.nuevaSolicitud.controls.emailCode.setValidators(Validators.required);
		this.nuevaSolicitud.controls.emailCode.reset();
		this.cliente.email = undefined;
		this.clienteService.setCliente(this.cliente);
	}

	public sendCode(): void {
		if (this.nuevaSolicitud.controls.mobilePhone.invalid) return;
		this.loading.next(true);
		this.restApiService
			.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.StartSendValidationSMS, logType: LogType.Flujo})
			.subscribe();
		this.identityService
			.validateMobilePhone(this.nuevaSolicitud.controls.mobilePhone.value, this.cliente.identityId)
			.pipe(finalize(() => this.loading.next(false)))
			.subscribe({
				next: (data: any) => {
					if (data.success) {
						this.isInSmsValidationProcess.next(true);
						this.mobilePhoneReadOnly.next(true);
						this.tupleSmsValidationId = data.id;
						this.restApiService
							.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.SendValidationSMS, logType: LogType.Flujo})
							.subscribe();
						this.uIService.successMessage('Importante!', 'Se ha enviado el código correctamente.');
					} else {
						this.restApiService
							.monitoringLog({
								creditFlowId: this.cliente.creditFlowId,
								step: Step.SendValidationSMS,
								logType: LogType.Consola,
								error: data,
							})
							.subscribe();
						this.uIService.responseLog('sendCode - ERROR', data.messages[0]);
						this.uIService.errorMessage('Error!', data.messages[0]);
					}
				},
				error: (error: HttpErrorResponse) => {
					this.restApiService
						.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.SendValidationSMS, logType: LogType.Consola, error})
						.subscribe();
					this.uIService.responseLog('sendCode - ERROR', error);
					this.uIService.errorMessage('Error!', error.error._message);
				},
			});
	}

	public validateSmsCode(): void {
		if (this.nuevaSolicitud.controls.smsCode.invalid) return;
		this.loading.next(true);
		const code = this.nuevaSolicitud.controls.smsCode.value;
		this.identityService
			.validateMobilePhoneCode(code, this.tupleSmsValidationId)
			.pipe(finalize(() => this.loading.next(false)))
			.subscribe({
				next: (data: any) => {
					if (data.success) {
						this.isInSmsValidationProcess.next(false);
						this.isMobilePhoneInputValidated.next(true);
						this.mobilePhoneValidationChange.emit(true);
						this.uIService.responseLog('validarCodigoCelular - OK', data);
						this.uIService.successMessage('Importante!', ValidationResultMessage[data.validationResult]);
						this.cliente.celular = this.nuevaSolicitud.controls.mobilePhone.value;
						this.cliente.validatedCelular = true;
						this.clienteService.setCliente(this.cliente);
						this.nuevaSolicitud.controls.mobilePhone.setValue(InputHide.fakeValue(this.cliente.celular, false));
						this.nuevaSolicitud.controls.mobilePhone.clearValidators();
						this.nuevaSolicitud.controls.mobilePhone.updateValueAndValidity();
						this.restApiService
							.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.SendCODESMSValidation, logType: LogType.Flujo})
							.subscribe();
					} else {
						if (data.validationResult === ValidationResult.FAILED) {
							this.uIService.errorMessage('Error!', ValidationResultMessage.FAILED);
						}
						if (data.validationResult === ValidationResult.NOT_FOUND) {
							this.uIService.errorMessage('Error!', ValidationResultMessage.NOT_FOUND);
						}
						if (data.validationResult === ValidationResult.EXPIRATED_LINK) {
							this.uIService.errorMessage('Error!', ValidationResultMessage.EXPIRATED_LINK);
						}
						this.uIService.responseLog('validateSmsCode - ERROR', data.validationResult);
						this.restApiService
							.monitoringLog({
								creditFlowId: this.cliente.creditFlowId,
								step: Step.SendCODESMSValidation,
								logType: LogType.Consola,
								error: data,
							})
							.subscribe();
					}
				},
				error: (error: HttpErrorResponse) => {
					this.restApiService
						.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.SendCODESMSValidation, logType: LogType.Consola, error})
						.subscribe();
					this.uIService.responseLog('validateSmsCode - ERROR', error);
					this.uIService.errorMessage('Error!', error.error._message);
				},
			});
	}

	isInValidInput(formControl: AbstractControl): boolean {
		return formControl.touched && formControl.invalid;
	}

	public invalidForm(): boolean {
		return (
			this.nuevaSolicitud.controls.email.invalid ||
			!this.nuevaSolicitud.controls.email.value ||
			this.nuevaSolicitud.controls.mobilePhone.invalid ||
			!this.nuevaSolicitud.controls.mobilePhone.value ||
			!this.isMobilePhoneInputValidated.value ||
			!this.isEmailInputValidated.value ||
			this.nuevaSolicitud.invalid
		);
	}

	onFormChange() {
		this.formChange.emit(this.nuevaSolicitud);
	}
}
