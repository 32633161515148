<form [formGroup]="homeDataFormGroup">
	<div class="mb-1 credilow-referencias">
		<div class="credilow-monto-solicitado">
			<h5>Datos personales</h5>
			<h6>Domicilio personal</h6>
		</div>
		<div class="mb-0 form-group">
			<input
				type="text"
				class="form-control"
				(keydown.enter)="$event.preventDefault()"
				placeholder="Ingresar Domicilio"
				autocorrect="off"
				autocapitalize="off"
				spellcheck="off"
				type="text"
				name="address-search"
				#search
			/>
		</div>
		<div class="mb-4">
			<google-map #myGoogleMap height="400px" width="320px" [center]="center" [options]="mapOptions" [zoom]="zoom">
				<map-marker #markerElem [options]="markerOptions" [position]="marker.position" (mapDragend)="markerDragEnd($event)"></map-marker>
			</google-map>
		</div>
		<div class="credilow-monto-solicitado credilow-referencias" *ngIf="addressComponents !== undefined">
			<h6>Dirección ingresada</h6>
			<input type="text" id="domicilio" name="domicilio" placeholder="Domicilio personal" formControlName="homeAddress" />
			<input type="text" id="entreCalles" name="entreCalles" placeholder="Entre Calles" formControlName="betweenStreets" />
			<input type="text" id="localidad" name="localidad" placeholder="Localidad" formControlName="locality" />
			<input type="text" id="codigoPostal" name="codigoPostal" placeholder="Código Postal" formControlName="postalAddress" digitOnly />
		</div>
		<div class="credilow-monto-solicitado">
			<h6>Información adicional sobre el domicilio</h6>
			<div class="form-group">
				<input
					placeholder="Piso, numero apartamento, caracteristicas ..."
					type="text"
					id="aditionalInfo"
					name="aditionalInfo"
					formControlName="additionalInfo"
				/>
			</div>
		</div>
	</div>

	<div class="credilow-navigate">
		<div class="credilow-50">
			<button class="credilow-previous-button credilow-shadow" [ngClass]="{sanii: !useCredilowStyle}" (click)="goBack()">Volver</button>
		</div>
		<div class="credilow-50">
			<button
				class="credilow-next-button credilow-shadow"
				[ngClass]="{sanii: !useCredilowStyle}"
				[disabled]="homeDataFormGroup.invalid"
				(click)="nextStep()"
				id="next-btn"
				appEnterClick
			>
				Continuar
			</button>
		</div>
	</div>
	<app-loader *ngIf="loading | async"></app-loader>
</form>
