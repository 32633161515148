<div class="fill position-relative text d-flex flex-column flex-grow-1" style="gap: 30px">
	<div class="border">
		<p>
			<strong>
				FUENTES VERIFICADAS:
				<span *ngIf="!autorizacion.reporteRecienteAprobado; else sourcesRecentlyChecked">
					{{ sources.checked === undefined ? 'Cargando...' : sources.checked + ' DE ' + sources.count }}
				</span>
				<ng-template #sourcesRecentlyChecked>
					<span>Reporte reciente aprobado</span>
				</ng-template>
			</strong>
		</p>
		<p>Nombre: {{ cliente.nombre }}</p>
		<p>DNI: {{ cliente.dni }}</p>
		<p>
			Monto Máximo:
			{{ autorizacion.montoMaximo === undefined ? 'Cargando...' : (autorizacion.montoMaximo | money) }}
		</p>
	</div>
	<app-client-email-cellphone
		class="border"
		(mobilePhoneValidationChange)="handleMobilePhoneValidationChange($event)"
		(initialFormValidity)="setInitialFormValidity($event)"
		(emailValidationChange)="handleEmailValidationChange($event)"
		(formChange)="handleFormChange($event)"
	></app-client-email-cellphone>
	<div class="credilow-footer credilow-navigate space-between-buttons">
		<div class="credilow-50">
			<button class="credilow-previous-button credilow-shadow" [ngClass]="{sanii: !useCredilowStyle}" (click)="cancel()">Cancelar</button>
		</div>
		<div *ngIf="autorizacion.montoMaximo === undefined; else dataLoaded" class="credilow-50">
			<button class="credilow-next-button credilow-shadow" [ngClass]="{sanii: !useCredilowStyle}">
				<mat-spinner class="spinner" diameter="25" color="warn"></mat-spinner>
			</button>
		</div>
		<ng-template #dataLoaded>
			<div class="credilow-50">
				<button
					class="credilow-next-button credilow-shadow"
					[ngClass]="{sanii: !useCredilowStyle}"
					id="next-btn-monto-max"
					(click)="nextStep()"
					[disabled]="!isFormValid"
					appEnterClick
				>
					Siguiente
				</button>
			</div>
		</ng-template>
	</div>
</div>
<app-loader *ngIf="loading | async"></app-loader>
