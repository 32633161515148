/**
 * @description
 *  Clase de soporte que facilita crear endpoints y normalizarlos.
 */

export class Endpoint {
	private constructor() {
		throw new Error('Nunca debe instanciar o heredar de esta clase (es de soporte).');
	}

	/**
	 * @description
	 *  Concatena una lista de segmentos agregando o eliminando símbolos '/'
	 *  según corresponda, así como también eliminando espacios innecesario en
	 *  los extremos.
	 *
	 * @param segments
	 *  Una lista de segmentos de un endpoint.
	 *
	 * @returns
	 *  Una URL conformada con la unión de todos los segmentos normalizados.
	 */
	public static join(segments: string[]): URL {
		return new URL(
			segments
				.map((segment) => segment.trim())
				.map((segment) => segment.replace(/(^\/+)|(\/+$)/, ''))
				.join('/')
		);
	}
}
