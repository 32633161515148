import {Plan} from './plan.model';
import {Comercio} from './comercio.model';
import {CreditType} from '../_enums/credit-type.enum';

export class Autorizacion {
	public id: number;
	public tipoCredito: CreditType;
	public reporteRecienteAprobado: boolean;
	public montoMaximo: number;
	public planSeleccionado: Plan;
	public comercio: Comercio;
	public montoSolicitado: number;
	public productoAdquirido: string;
	public acceptedUnderCommerceRiskDate: Date;
	public underCommerceRisk: boolean;
}
