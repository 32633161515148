<div class="fill position-relative d-flex flex-column">
	<div class="credilow-planes" #goToTop>
		<table class="table">
			<tbody>
				<tr>
					<td>N° Autorización:</td>
					<td>
						<strong>AA - {{ autorizacion.id }}</strong>
					</td>
				</tr>
				<tr>
					<td>Comercio</td>
					<td>
						<strong>{{ autorizacion.comercio.Nombre }}</strong>
					</td>
				</tr>
				<tr>
					<td>Monto Solicitado</td>
					<td>
						<strong>${{ autorizacion.montoSolicitado }}</strong>
					</td>
				</tr>
				<tr>
					<td>Cantidad de Cuotas</td>
					<td>
						<strong>{{ autorizacion.planSeleccionado.CantidadCuotas }}</strong>
					</td>
				</tr>
				<tr>
					<td>Monto Cuota</td>
					<td>
						<strong>${{ autorizacion.planSeleccionado.MontoCuota }}</strong>
					</td>
				</tr>
				<tr>
					<td>Primer Vencimiento</td>
					<td>
						<strong>{{ autorizacion.planSeleccionado.PrimerVencimiento }}</strong>
					</td>
				</tr>
				<tr>
					<td>Producto</td>
					<td>
						<strong>{{ autorizacion.productoAdquirido }}</strong>
					</td>
				</tr>
			</tbody>
		</table>
		<p>Comentario (opcional):</p>
		<textarea [(ngModel)]="comentario" rows="5" placeholder="Comentario..."></textarea>

		<h4>Adjuntar documentación</h4>
		<hr />
		<h6>
			Servicio
			<i
				class="fas fa-plus credilow-adjuntar"
				*ngIf="(documentacionServicios | async).length < 3"
				(click)="uploaderDocumentacionServicio.click()"
				id="add-servicio-btn"
			></i>
			<i class="fas fa-plus credilow-adjuntar-bloqueado" *ngIf="(documentacionServicios | async).length >= 3"></i>
		</h6>
		<input
			#uploaderDocumentacionServicio
			type="file"
			accept="image/*"
			capture="environment"
			hidden
			(change)="uploadFileDocumentacionServicios($event)"
			id="input-servicio"
		/>
		<div class="credilow-adjuntos">
			<div class="credilow-documento" *ngFor="let documento of documentacionServicios.value; index as i">
				Servicio {{ i + 1 }}
				<span class="credilow-borrar-adjunto" (click)="borrarDocumento(documentacionServicios, i)">
					<i class="fas fa-times"></i>
				</span>
			</div>
		</div>
		<hr />
		<h6>
			Adicional
			<i
				class="fas fa-plus credilow-adjuntar"
				*ngIf="(documentacionAdicional | async).length < 3"
				(click)="uploaderDocumentacionAdicional.click()"
			></i>
			<i class="fas fa-plus credilow-adjuntar-bloqueado" *ngIf="(documentacionAdicional | async).length >= 3"></i>
		</h6>
		<input
			#uploaderDocumentacionAdicional
			type="file"
			accept="image/*"
			capture="environment"
			hidden
			(change)="uploadFileDocumentacionAdicional($event)"
		/>
		<div class="credilow-adjuntos">
			<div class="credilow-documento" *ngFor="let documento of documentacionAdicional.value; index as i">
				Adicional {{ i + 1 }}
				<span class="credilow-borrar-adjunto" (click)="borrarDocumento(documentacionAdicional, i)">
					<i class="fas fa-times"></i>
				</span>
			</div>
		</div>
		<hr />
	</div>

	<div class="credilow-footer credilow-navigate">
		<div class="credilow-50">
			<button class="credilow-previous-button credilow-shadow" [ngClass]="{sanii: !useCredilowStyle}" (click)="cancel()">Cancelar</button>
		</div>
		<div class="credilow-50">
			<button
				class="credilow-next-button credilow-shadow"
				[ngClass]="{sanii: !useCredilowStyle}"
				id="btn-aprobacion-nxt"
				(click)="confirm()"
				appEnterClick
			>
				Confirmar
			</button>
		</div>
	</div>
</div>
<app-loader *ngIf="loading | async"></app-loader>
