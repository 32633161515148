import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {ContainerComponent} from './container/container.component';
import {SigningProcessContainerComponent} from './component/signing-process-container/signing-process-container.component';
import {AuthGuardService} from './_services/auth-guard.service';
import {VendedoresComponent} from './container/vendedores/vendedores.component';
import {CreateOrEditFormComponent} from './container/vendedores/create-or-edit-form/create-or-edit-form.component';
import {AdminUserGuardService} from './_services/admin-user-guard.service';
import {QrComponent} from './container/qr/qr.component';
import {CreditosComponent} from './container/creditos/creditos.component';
import {SimuladorComponent} from './container/simulador/simulador.component';
import {HomeComponent} from './container/home/home.component';
import {ErrorPageComponent} from './component/error-page/error-page.component';
import {InstallPromptComponent} from './component/install-prompt/install-prompt.component';
import {HomeDataComponent} from './component/home-data/home-data.component';
import { ClientEmailCellphoneComponent } from './component/client-email-cellphone/client-email-cellphone.component';

const routes: Routes = [
	{path: 'test', component: ClientEmailCellphoneComponent},
	{path: 'install', component: InstallPromptComponent},
	{path: 'signingProcess', component: SigningProcessContainerComponent},
	{path: 'login', component: LoginComponent},
	{path: 'test', component: SimuladorComponent},
	{path: '', redirectTo: '/home', pathMatch: 'full'},
	//TODO no me andan las child routes
	{
		path: 'adminVendedores',
		canActivate: [AuthGuardService, AdminUserGuardService],
		component: VendedoresComponent,
		// children: [
		// 	{path: 'create', component: CreateOrEditFormComponent},
		// 	{path: 'create/:userID', component: CreateOrEditFormComponent},
		// ],
	},
	{
		path: 'createCredit',
		canActivate: [AuthGuardService],
		component: ContainerComponent,
	},
	{
		path: 'adminVendedores/create',
		canActivate: [AuthGuardService, AdminUserGuardService],
		component: CreateOrEditFormComponent,
	},
	{
		path: 'creditsByVendedor',
		canActivate: [AuthGuardService],
		component: CreditosComponent,
	},
	{
		path: 'creditSimulator',
		canActivate: [AuthGuardService],
		component: SimuladorComponent,
	},
	{
		path: 'showQR',
		canActivate: [AuthGuardService],
		component: QrComponent,
	},
	{
		path: 'adminVendedores/create/:userID',
		canActivate: [AuthGuardService, AdminUserGuardService],
		component: CreateOrEditFormComponent,
	},
	{
		path: 'home',
		canActivate: [AuthGuardService],
		component: HomeComponent,
	},
	{
		path: 'error',
		// No tiene que tener Guard, ya que este error tambien se usa para los que no estan iniciados sesion en docusign
		// canActivate: [AuthGuardService],
		component: ErrorPageComponent,
	},

	{path: '**', canActivate: [AuthGuardService], component: ErrorPageComponent},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
