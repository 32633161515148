import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {SigningProcessStep} from '../_enums/signing-process-step.enum';

@Injectable({
	providedIn: 'root',
})
export class SigningProcessStepService {
	private signingProcessStep: BehaviorSubject<SigningProcessStep> =
		new BehaviorSubject<SigningProcessStep>(undefined);

	public get step(): Observable<SigningProcessStep> {
		return this.signingProcessStep.asObservable();
	}

	public moveTo(step: SigningProcessStep): void {
		this.signingProcessStep.next(step);
	}
}
