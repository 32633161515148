import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class TimerService {
	private refreshTokenTimeout;
	private readonly time: number = 15 * 60 * 1000; //15 minutos

	public startRefreshTokenTimer(timeout: number, method: () => void) {
		this.refreshTokenTimeout = setTimeout(() => method(), timeout - this.time);
	}

	public stopRefreshTokenTimer() {
		clearTimeout(this.refreshTokenTimeout);
	}
}
