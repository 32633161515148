import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {BehaviorSubject} from 'rxjs';
import {ClientStatus} from '../../_enums/cliente-status.enum';
import {InputHide} from '../../_helpers/input-hide.support';
import {Cliente} from '../../_models/cliente.model';
import {Comercio} from '../../_models/comercio.model';
import {Optional} from '../../_models/optional.model';
import {ClienteService} from '../../_services/cliente.service';
import {OnboardingService} from '../../_services/onboarding-steps.service';
import {PhoneValidator} from '../../_validator/mobile-phone.validator';

import {StyleServiceService} from 'src/app/_services/style-service.service';
import {RestApiService} from '../../_services/rest-api.service';
import {Step} from '../../_enums/step.enum';
import {LogType} from '../../_enums/log-type.enum';

@Component({
	selector: 'x-existing-client',
	templateUrl: './existing-client.component.html',
	styleUrls: ['./existing-client.component.css'],
})
export class ExistingClientComponent implements OnInit {
	public useCredilowStyle: boolean;
	public cliente: Cliente;
	public nuevaSolicitud: UntypedFormGroup;
	public clientStatus: ClientStatus = ClientStatus.Existent;

	public canEditMobilePhone: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public isMobilePhoneInputValidated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public mobilePhoneReadOnly: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	@Output() public filledExistentClientFields: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
	@Output() public filledNewClientFields: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
	@Output() public changeStep: EventEmitter<number> = new EventEmitter<number>();

	constructor(
		public styleService: StyleServiceService,

		private readonly clienteService: ClienteService,
		private readonly formBuilder: UntypedFormBuilder,
		private readonly onboardingService: OnboardingService,
		private restApiService: RestApiService
	) {}

	public ngOnInit(): void {
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		});
		this.clienteService.getCliente().subscribe({
			next: (cliente: Cliente) => {
				this.cliente = cliente;
				if (this.clientStatus !== ClientStatus.Existent) {
					this.initializeView();
				}
			},
			error: (error: Error) => console.log(error),
		});
		this.initializeView();
	}

	private initializeView() {
		this.nuevaSolicitud = this.formBuilder.group({
			mobilePhone: new UntypedFormControl(InputHide.fakeValue(this.cliente.celular, false), [Validators.required, PhoneValidator.mobile()]),
			commerce: new UntypedFormControl(undefined, Validators.required),
		});
		if (Optional.of(this.cliente.celular).isPresent()) {
			this.nuevaSolicitud.controls.mobilePhone.clearValidators();
		}
		const mobilePhoneValidated = this.cliente.validatedCelular;
		this.canEditMobilePhone.next(Optional.of(this.cliente.celular).isPresent());
		this.isMobilePhoneInputValidated.next(mobilePhoneValidated);
		this.mobilePhoneReadOnly.next(mobilePhoneValidated);
	}

	public cancel(): void {
		this.restApiService.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.Cancel, logType: LogType.Flujo}).subscribe();
		this.onboardingService.restart();
		this.clienteService.restart();
	}

	public nextStep(): void {
		if (this.nuevaSolicitud.valid) {
			this.filledExistentClientFields.emit(this.nuevaSolicitud);
		}
	}

	public isNewClient(): boolean {
		return this.clientStatus === ClientStatus.New;
	}

	public isExistentClient(): boolean {
		return this.clientStatus === ClientStatus.Existent;
	}

	public switchClientStatus(): void {
		if (this.isNewClient()) {
			this.clientStatus = ClientStatus.Existent;
		} else {
			this.clientStatus = ClientStatus.New;
		}
		this.nuevaSolicitud.controls.commerce.reset();
	}

	public sendNewFormData(formData: UntypedFormGroup): void {
		this.filledNewClientFields.emit(formData);
	}

	public selectComercio(commerce: Comercio): void {
		this.nuevaSolicitud.controls.commerce.setValue(commerce);
	}

	public editMobilePhoneInput(): void {
		this.canEditMobilePhone.next(false);
		this.isMobilePhoneInputValidated.next(false);
		this.mobilePhoneReadOnly.next(false);
		this.nuevaSolicitud.controls.mobilePhone.reset();
		this.nuevaSolicitud.controls.mobilePhone.setValidators([Validators.required, PhoneValidator.mobile()]);
		this.nuevaSolicitud.controls.mobilePhone.updateValueAndValidity();
		this.cliente.celular = undefined;
		this.cliente.validatedCelular = false;
		this.clienteService.setCliente(this.cliente);
	}

	public isInValidInput(formControl: AbstractControl): boolean {
		return formControl.touched && formControl.invalid;
	}

	public invalidForm(): boolean {
		return this.nuevaSolicitud.invalid;
	}

	public warningDialogOrNextStep(): void {
		this.cliente.nuevo = this.isNewClient();
		this.nextStep();
	}
}
