<div class="container d-flex flex-column h-100">
	<div class="mb-3">
		<div>
			<button
				id="create-vendedor"
				class="credilow-previous-button credilow-shadow"
				[ngClass]="{sanii: !useCredilowStyle}"
				(click)="loadCreateOrEdit()"
			>
				Crear Usuario
			</button>
		</div>
	</div>
	<div *ngIf="commercesWithEmployees; else loadingCommerce">
		<div *ngIf="commercesWithEmployees.length === 0; else optionsCommerces">
			<div class="credilow-plan-cuota disabled">No se han encontrado vendedores</div>
		</div>
		<ng-template #optionsCommerces>
			<mat-form-field class="commerceSelect">
				<mat-label>Comercios</mat-label>
				<mat-select (selectionChange)="onSelectionChanged($event)" [(value)]="firstCommerce" disabled="{{ disabled }}">
					<mat-option *ngFor="let commerce of commercesWithEmployees; index as i" value="commerce{{ i }}" [value]="commerce">
						<small>{{ commerce.nombre }}</small>
					</mat-option>
				</mat-select>
			</mat-form-field>
		</ng-template>
	</div>
	<ng-template #loadingCommerce>
		<div class="credilow-plan-cuota disabled">Cargando...</div>
	</ng-template>
	<div class="text-center">
		<div *ngIf="commerceSelected" class="mt-3">
			<table mat-table [dataSource]="getVendedores()" class="mat-elevation-z8">
				<ng-container matColumnDef="nombre_vendedor">
					<th mat-header-cell *matHeaderCellDef>Nombre Vendedor</th>
					<td mat-cell *matCellDef="let vendedor" class="pr-2">
						{{ vendedor.vendedor }}
					</td>
				</ng-container>
				<ng-container matColumnDef="accion" class="mt-3">
					<th mat-header-cell *matHeaderCellDef>Acción</th>
					<td mat-cell *matCellDef="let vendedor">
						<button
							id="deshabilitar-btn"
							type="button"
							mat-raised-button
							class="option-btn m-1"
							[ngClass]="vendedor.isActive() ? 'disabled-btn' : 'enable-btn'"
							(click)="activarDesactivarVendedor(vendedor)"
						>
							{{ vendedor.isActive() ? 'Deshabilitar' : 'Habilitar' }}
						</button>
						<button
							id="edit-btn"
							type="button"
							mat-raised-button
							class="option-btn m-1 edit-btn"
							[ngClass]="'btn-outline-primary'"
							(click)="edit(vendedor)"
						>
							Editar
						</button>
					</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
			</table>
		</div>
	</div>
	<div class="credilow-footer credilow-navigate mt-auto">
		<button class="credilow-previous-button credilow-shadow" [ngClass]="{sanii: !useCredilowStyle}" (click)="goBack()">Volver</button>
	</div>
</div>
<app-loader *ngIf="loading | async"></app-loader>
