import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {Permission} from '../_enums/permission.enum';
import {AuthenticationService} from '../_services/authentication.service';

@Injectable()
export class AuthGuardService {
	constructor(private authenticationService: AuthenticationService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
		const validToken = this.authenticationService.tokenNotExpired();
		const hasPermission = this.authenticationService.hasPermission(Permission.FullAccess);
		if(validToken && hasPermission){
			return true;
		} else {
			this.authenticationService.logout();
			return false;
		}
	}

	// Add the title property to ActivatedRouteSnapshot
	// canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
	// 	childRoute.title = 'Your Page Title';
	// 	return this.canActivate(childRoute, state);
	//   }
}
