import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Routes} from 'src/app/_enums/routes.enum';
import {TitleService} from '../../_services/title.service';

import {StyleServiceService} from 'src/app/_services/style-service.service';
import {ErrorPageService} from 'src/app/_services/error-page.service';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'app-error-page',
	templateUrl: './error-page.component.html',
	styleUrls: ['./error-page.component.css'],
})
export class ErrorPageComponent implements OnInit {
	public useCredilowStyle: boolean;
	@Input() errorMessage: string;
	@Input() errorCode: string;
	@Input() errorTitle: string;
	private redirectTo: Routes;
	public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		public styleService: StyleServiceService,
		private router: Router,
		private titleService: TitleService,
		private errorPageService: ErrorPageService
	) {}

	public ngOnInit(): void {
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		});
		this.titleService.updateTitle('Error');
		this.errorTitle = this.errorPageService.errorTitle;
		this.errorMessage = this.errorPageService.errorMessage;
		this.errorCode = this.errorPageService.errorCode;
		this.redirectTo = this.errorPageService.redirectTo;
	}

	public goBack(): void {
		if (this.redirectTo == Routes.Credilow) {
			this.loading.next(true);
			window.location.href = Routes.Credilow;
		} else {
			this.router.navigate([this.redirectTo ?? Routes.Login]).then(() => {
				this.resetErrorPage();
			});
		}
	}

	private resetErrorPage() {
		this.errorTitle = null;
		this.errorCode = null;
		this.errorMessage = null;
		this.redirectTo = null;
	}
}
