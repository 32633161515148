import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Endpoint} from '../_helpers/endpoint.support';
import {EnvironmentService} from './enviroment.service';

@Injectable({
	providedIn: 'root',
})
export class IdentityService {
	private headers: HttpHeaders = new HttpHeaders({
		Accept: 'application/json',
	});

	constructor(private environmentService: EnvironmentService, private httpClient: HttpClient) {}

	public uploadDocument(front: File, back: File): Observable<any> {
		const data = new FormData();
		data.append('frente', front);
		data.append('dorso', back);
		const headers = this.headers;
		const url = Endpoint.join([this.environmentService.identityServiceURI(), 'validation-id']);
		return this.httpClient.post<any>(url.href, data, {headers});
	}

	public validateMobilePhone(mobilePhoneNumber: string, identityId: string): Observable<any> {
		const headers = this.headers;
		const data = {mobilePhone: mobilePhoneNumber};
		const url = Endpoint.join([this.environmentService.identityServiceURI(), `/sms/${identityId}/CODE`]);
		return this.httpClient.post<any>(url.href, data, {headers});
	}

	public validateMobilePhoneCode(code: number, tupleValidationId: number): Observable<any> {
		const headers = this.headers;
		const url = Endpoint.join([this.environmentService.identityServiceURI(), `/sms/validation?token=${code}&id=${tupleValidationId}`]);
		return this.httpClient.get<any>(url.href, {headers});
	}

	public validateEmail(email: number, identityId: string): Observable<any> {
		const headers = this.headers;
		const data = {email: email};
		const url = Endpoint.join([this.environmentService.identityServiceURI(), `/email/${identityId}/CODE`]);
		return this.httpClient.post<any>(url.href, data, {headers});
	}

	public validateEmailCode(code: number, tupleValidationId: number): Observable<any> {
		const headers = this.headers;
		const url = Endpoint.join([this.environmentService.identityServiceURI(), `/email/validation?token=${code}&id=${tupleValidationId}`]);
		return this.httpClient.get<any>(url.href, {headers});
	}

	public getNeutralSelfie(identityId: string): Observable<any> {
		const headers = this.headers;
		const url = Endpoint.join([this.environmentService.identityServiceURI(), `/identity/${identityId}/image`]);
		return this.httpClient.get<any>(url.href, {headers});
	}
}
