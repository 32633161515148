import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

import {StyleServiceService} from '../../_services/style-service.service';

@Component({
	selector: 'x-no-validated-inputs-dialog',
	templateUrl: './no-validated-inputs-dialog.component.html',
	styleUrls: ['./no-validated-inputs-dialog.component.css'],
})
export class NoValidatedInputsDialogComponent {
	public useCredilowStyle: boolean;
	constructor(public styleService: StyleServiceService, public dialogRef: MatDialogRef<NoValidatedInputsDialogComponent>) {}

	onCloseClick(): void {
		this.dialogRef.close(false);
	}

	onContinueClick(): void {
		this.dialogRef.close(true);
	}
}
