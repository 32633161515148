import {VendedorLista} from './vendedor-lista.model';

/**
 * @description
 *	Clase para almacenar a los comercios y sus vendedores.
 */

export class ComercioConVendedores {
	public id: number;
	public nombre: string;
	public vendedores: VendedorLista[];

	public static from(comercioConVendedores: ComercioConVendedores) {
		const comercio: ComercioConVendedores = new ComercioConVendedores();
		comercio.id = comercioConVendedores.id;
		comercio.nombre = comercioConVendedores.nombre;
		comercio.vendedores = comercioConVendedores.vendedores.map((vendedores: VendedorLista) =>
			VendedorLista.from(vendedores)
		);
		return comercio;
	}
}
