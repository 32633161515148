<form [formGroup]="referenceForm" novalidate class="h-100 position-relative d-flex flex-column">
	<div class="credilow-referencias" #goToTop>
		<h6>Referencia 1</h6>
		<input
			type="text"
			id="name-reference-1"
			name="name-reference-1"
			placeholder="Nombre y Apellido Referencia"
			formControlName="nameReference1"
			[ngClass]="{
				'is-invalid': isInvalidForm(referenceForm.controls.nameReference1)
			}"
		/>
		<input
			type="text"
			id="relation-reference-1"
			name="relation-reference-1"
			placeholder="Relación"
			formControlName="relationReference1"
			[ngClass]="{
				'is-invalid': isInvalidForm(referenceForm.controls.relationReference1)
			}"
		/>
		<input
			type="text"
			id="telephone-reference-1"
			name="telephone-reference-1"
			placeholder="Teléfono de Referencia"
			formControlName="telephoneReference1"
			[ngClass]="{
				'is-invalid': isInvalidForm(referenceForm.controls.telephoneReference1)
			}"
			digitOnly
			autocomplete="off"
			inputmode="numeric"
		/>
		<div class="credilow-yes-no-title">Tipo de Línea</div>
		<div class="credilow-yes-no">
			<div
				class="credilow-yes-no-option credilow-yes-no-option-left"
				[ngClass]="{'credilow-yes-no-option-active': phoneType1 == 'Celular'}"
				(click)="changePhoneType(1, true)"
			>
				Celular
			</div>
			<div
				class="credilow-yes-no-option credilow-yes-no-option-right"
				[ngClass]="{'credilow-yes-no-option-active': phoneType1 == 'Fijo'}"
				(click)="changePhoneType(1, false)"
			>
				Fijo
			</div>
		</div>
		<div *ngIf="!cliente.renovador">
			<hr />
			<h6>Referencia 2</h6>
			<input
				type="text"
				id="name-reference-2"
				name="name-reference-2"
				placeholder="Nombre y Apellido Referencia"
				formControlName="nameReference2"
				[ngClass]="{
					'is-invalid': isInvalidForm(referenceForm.controls.nameReference2)
				}"
			/>
			<input
				type="text"
				id="relation-reference-2"
				name="relation-reference-2"
				placeholder="Relación"
				formControlName="relationReference2"
				[ngClass]="{
					'is-invalid': isInvalidForm(referenceForm.controls.relationReference2)
				}"
			/>
			<input
				type="text"
				id="telephone-reference-2"
				name="telephone-reference-2"
				placeholder="Teléfono de Referencia"
				formControlName="telephoneReference2"
				[ngClass]="{
					'is-invalid': isInvalidForm(referenceForm.controls.telephoneReference2)
				}"
				digitOnly
				autocomplete="off"
				inputmode="numeric"
			/>
			<div class="credilow-yes-no-title">Tipo de Línea</div>
			<div class="credilow-yes-no">
				<div
					class="credilow-yes-no-option credilow-yes-no-option-left"
					[ngClass]="{
						'credilow-yes-no-option-active': phoneType2 == 'Celular'
					}"
					(click)="changePhoneType(2, true)"
				>
					Celular
				</div>
				<div
					class="credilow-yes-no-option credilow-yes-no-option-right"
					[ngClass]="{'credilow-yes-no-option-active': phoneType2 == 'Fijo'}"
					(click)="changePhoneType(2, false)"
				>
					Fijo
				</div>
			</div>
		</div>
		<div *ngIf="isClientDNI()">
			<div *ngIf="!cliente.renovador">
				<hr />
				<h6>Referencia 3</h6>
				<input
					type="text"
					id="name-reference-3"
					name="name-reference-3"
					placeholder="Nombre y Apellido Referencia"
					formControlName="nameReference3"
					[ngClass]="{
						'is-invalid': isInvalidForm(referenceForm.controls.nameReference3)
					}"
				/>
				<input
					type="text"
					id="relation-reference-3"
					name="relation-reference-3"
					placeholder="Relación"
					formControlName="relationReference3"
					[ngClass]="{
						'is-invalid': isInvalidForm(referenceForm.controls.relationReference3)
					}"
				/>
				<input
					type="text"
					id="telephone-reference-3"
					name="telephone-reference-3"
					placeholder="Teléfono de Referencia"
					formControlName="telephoneReference3"
					[ngClass]="{
						'is-invalid': isInvalidForm(referenceForm.controls.telephoneReference3)
					}"
					digitOnly
					autocomplete="off"
					inputmode="numeric"
				/>
				<div class="credilow-yes-no-title">Tipo de Línea</div>
				<div class="credilow-yes-no">
					<div
						class="credilow-yes-no-option credilow-yes-no-option-left"
						[ngClass]="{
							'credilow-yes-no-option-active': phoneType3 == 'Celular'
						}"
						(click)="changePhoneType(3, true)"
					>
						Celular
					</div>
					<div
						class="credilow-yes-no-option credilow-yes-no-option-right"
						[ngClass]="{
							'credilow-yes-no-option-active': phoneType3 == 'Fijo'
						}"
						(click)="changePhoneType(3, false)"
					>
						Fijo
					</div>
				</div>
			</div>
		</div>
		<div class="credilow-referencias" *ngIf="showService1()">
			<hr />
			<p>
				Último servicio a nombre (luz, gas, teléfono de línea, agua, cable o resumen de tarjeta de crédito) que coincida la dirección con la
				del DNI, o 2 a nombre de un familiar y que coincidan las direcciones con la del DNI. En caso de presentar 2 recibos a su nombre, puede
				ampliar su límite de crédito
			</p>
			<h6>Servicio 1</h6>
			<div class="credilow-yes-no-title">Es titular del servicio</div>
			<div class="credilow-yes-no">
				<div
					class="credilow-yes-no-option credilow-yes-no-option-left"
					[ngClass]="{'credilow-yes-no-option-active': esTitular1}"
					(click)="esTitular1 = true"
				>
					Si
				</div>
				<div
					class="credilow-yes-no-option credilow-yes-no-option-right"
					[ngClass]="{'credilow-yes-no-option-active': !esTitular1}"
					(click)="esTitular1 = false"
				>
					No
				</div>
			</div>
			<div class="selectdiv">
				<select id="tipo-servicio-1" name="tipo-servicio-1" formControlName="tipoServicio1">
					<option>LUZ</option>
					<option>LUZ PREPAGA</option>
					<option>GAS</option>
					<option>AGUA</option>
					<option>CABLE</option>
					<option>TELÉFONO (FIJO)</option>
					<option>TARJETA DE CRÉDITO - VISA</option>
					<option>TARJETA DE CRÉDITO - MASTER</option>
					<option>TARJETA DE CRÉDITO - OTRA</option>
					<option>OTRO</option>
				</select>
			</div>
			<input
				type="text"
				id="proveedor-1"
				name="proveedor-1"
				placeholder="Proveedor del Servicio"
				formControlName="proveedorServicio1"
				[ngClass]="{
					'is-invalid': isInvalidForm(referenceForm.controls.proveedorServicio1)
				}"
			/>
			<input
				type="text"
				id="titular-1"
				name="titular-1"
				placeholder="Titular del Servicio"
				formControlName="titular1"
				[ngClass]="{
					'is-invalid': isInvalidForm(referenceForm.controls.titular1)
				}"
				[hidden]="esTitular1"
			/>
			<input
				type="text"
				id="relacion-1"
				name="relacion-1"
				placeholder="Relación"
				formControlName="relacion1"
				[ngClass]="{
					'is-invalid': isInvalidForm(referenceForm.controls.relacion1)
				}"
				[hidden]="esTitular1"
			/>
			<hr />
		</div>
		<div *ngIf="showService2()">
			<h6>Servicio 2</h6>
			<div class="credilow-yes-no-title">Es titular del servicio</div>
			<div class="credilow-yes-no">
				<div
					class="credilow-yes-no-option credilow-yes-no-option-left"
					[ngClass]="{'credilow-yes-no-option-active': esTitular2}"
					(click)="esTitular2 = true"
				>
					Si
				</div>
				<div
					class="credilow-yes-no-option credilow-yes-no-option-right"
					[ngClass]="{'credilow-yes-no-option-active': !esTitular2}"
					(click)="esTitular2 = false"
				>
					No
				</div>
			</div>
			<div class="selectdiv">
				<select id="tipo-servicio-2" name="tipo-servicio-2" formControlName="tipoServicio2">
					<option value="" disabled selected hidden>Tipo de Servicio</option>
					<option>LUZ</option>
					<option>LUZ PREPAGA</option>
					<option>GAS</option>
					<option>AGUA</option>
					<option>CABLE</option>
					<option>TELÉFONO (FIJO)</option>
					<option>TARJETA DE CRÉDITO - VISA</option>
					<option>TARJETA DE CRÉDITO - MASTER</option>
					<option>TARJETA DE CRÉDITO - OTRA</option>
					<option>OTRO</option>
				</select>
			</div>
			<input
				type="text"
				id="otro-servicio"
				name="otro-servicio"
				placeholder="Especifique Servicio"
				formControlName="otroServicio"
				*ngIf="referenceForm.controls.relacion2.value === 'OTRO'"
			/>
			<input
				type="text"
				id="proveedor-2"
				name="proveedor-2"
				placeholder="Proveedor del Servicio"
				formControlName="proveedorServicio2"
				[ngClass]="{
					'is-invalid': isInvalidForm(referenceForm.controls.proveedorServicio2)
				}"
			/>
			<input
				type="text"
				id="titular-2"
				name="titular-2"
				placeholder="Titular del Servicio"
				formControlName="titular2"
				[ngClass]="{
					'is-invalid': isInvalidForm(referenceForm.controls.titular2)
				}"
				*ngIf="!esTitular2"
			/>
			<input
				type="text"
				id="relacion-2"
				name="relacion-2"
				placeholder="Relación"
				formControlName="relacion2"
				[ngClass]="{
					'is-invalid': isInvalidForm(referenceForm.controls.relacion2)
				}"
				*ngIf="!esTitular2"
			/>
		</div>
	</div>
	<div class="credilow-navigate space-between-buttons">
		<div class="credilow-50">
			<button class="credilow-previous-button credilow-shadow" [ngClass]="{sanii: !useCredilowStyle}" (click)="cancel()">Cancel</button>
		</div>
		<div class="credilow-50">
			<button
				class="credilow-next-button credilow-shadow"
				[ngClass]="{sanii: !useCredilowStyle}"
				(click)="continue()"
				[disabled]="referenceForm.invalid || !verficarServicios()"
				id="next-ref-btn"
				appEnterClick
			>
				Continuar
			</button>
		</div>
	</div>
</form>
<app-loader *ngIf="loading | async"></app-loader>
