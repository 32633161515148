import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AuthorizationService} from '../../_services/authorization.service';
import {Autorizacion} from '../../_models/autorizacion.model';
import {Sources} from '../../_models/sources.model';
import {SourcesService} from '../../_services/sources.service';
import {Cliente} from '../../_models/cliente.model';
import {ClienteService} from '../../_services/cliente.service';
import {OnboardingService} from '../../_services/onboarding-steps.service';
import {OnboardingStep} from '../../_enums/onboarding-step.enum';
import {HeaderStep} from '../../_enums/header-step.enum';
import {BehaviorSubject} from 'rxjs';
import {TitleService} from '../../_services/title.service';

import {StyleServiceService} from '../../_services/style-service.service';
import {RestApiService} from '../../_services/rest-api.service';
import {Step} from '../../_enums/step.enum';
import {LogType} from '../../_enums/log-type.enum';
import {FormGroup} from '@angular/forms';

@Component({
	selector: 'app-client-data',
	templateUrl: './client-data.component.html',
	styleUrls: ['./client-data.component.css'],
})
export class ClientDataComponent implements OnInit {
	public useCredilowStyle: boolean;
	public isFormValid: boolean = false;
	isMobilePhoneInputValidated: boolean = false;
	isEmailInputValidated: boolean = false;

	@Output() changeStepperState: EventEmitter<HeaderStep> = new EventEmitter<HeaderStep>();

	public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public autorizacion: Autorizacion = new Autorizacion();
	public sources: Sources = new Sources();
	public cliente: Cliente = new Cliente();

	constructor(
		public styleService: StyleServiceService,

		private readonly autorizacionService: AuthorizationService,
		private readonly sourcesService: SourcesService,
		private readonly onboardingService: OnboardingService,
		private readonly clienteService: ClienteService,
		private titleService: TitleService,
		private restApiService: RestApiService
	) {}

	public ngOnInit(): void {
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		});
		this.titleService.updateTitle('Fuentes Verificadas');
		this.autorizacionService.getAutorizacion().subscribe({
			next: (autorizacion: Autorizacion) => (this.autorizacion = autorizacion),
			error: (error: Error) => console.log(error),
		});
		this.clienteService.getCliente().subscribe({
			next: (cliente: Cliente) => {
				this.cliente = cliente;
				// this.restApiService.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.BeforeFuentesVerificadas, logType:LogType.Flujo}).subscribe();
			},
			error: (error: Error) => console.log(error),
		});

		this.sourcesService.getFuentes().subscribe({
			next: (sources: Sources) => (this.sources = sources),
			error: (error: Error) => console.log(error),
		});

	}

	public nextStep(): void {
		// this.restApiService.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.FuentesVerificadas, logType:LogType.Flujo}).subscribe();
		if (this.isEmailInputValidated && this.isMobilePhoneInputValidated) {
			this.onboardingService.moveTo(OnboardingStep.AmountRequested);
		}
	}

	public cancel(): void {
		this.restApiService.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.Cancel, logType: LogType.Flujo}).subscribe();
		this.onboardingService.restart();
		this.clienteService.restart();
	}

	handleFormChange(form: FormGroup) {
		this.isFormValid = !this.invalidForm(form);
	}
	public invalidForm(form): boolean {
		return (
			form.controls.email.invalid ||
			!form.controls.email.value ||
			form.controls.mobilePhone.invalid ||
			!form.controls.mobilePhone.value ||
			!this.isMobilePhoneInputValidated ||
			!this.isEmailInputValidated ||
			form.invalid
		);
	}

	handleMobilePhoneValidationChange(isValidated: boolean) {
		this.isMobilePhoneInputValidated = isValidated;
	}

	handleEmailValidationChange(isValidated: boolean) {
		this.isEmailInputValidated = isValidated;
	}

	setInitialFormValidity(isValid: boolean) {
		this.isFormValid = isValid;
	}
}
