import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Endpoint} from '../_helpers/endpoint.support';
import {SigningProcess} from '../_models/signing-process.model';
import {EnvironmentService} from './enviroment.service';

@Injectable({
	providedIn: 'root',
})
export class SigningProcessService {
	private headers: HttpHeaders = new HttpHeaders({
		Accept: 'application/json',
	});

	private signingProcess: BehaviorSubject<SigningProcess> = new BehaviorSubject<SigningProcess>(new SigningProcess());

	public getSigningProcess(): Observable<SigningProcess> {
		return this.signingProcess.asObservable();
	}

	public setSigningProcess(signingProcess: SigningProcess): void {
		this.signingProcess.next(signingProcess);
	}

	constructor(private readonly httpClient: HttpClient, private readonly environmentService: EnvironmentService) {}

	public postSignMethod(authId: number, validationId: string): Observable<any> {
		const headers = this.headers;
		const url = Endpoint.join([
			this.environmentService.automaticAuthorizerURI(),
			`ac/start-signing-process-authorization?authId=${authId}&validationId=${validationId}`,
		]);
		return this.httpClient.post<any>(url.href, {headers});
	}

	public signCallCenter(authId: number): Observable<any> {
		const headers = this.headers;
		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `ac/signCallCenter/${authId}`]);
		return this.httpClient.post<any>(url.href, {headers});
	}

	public startSigningProcess(url: string): Observable<any> {
		const headers = this.headers;
		return this.httpClient.get<any>(url, {headers});
	}

	public postNeutralSelfie(validationId: string, selfieImage: File, authId: number): Observable<any> {
		const headers = this.headers;
		const data = new FormData();
		data.append('selfie', selfieImage);
		data.append('authId', authId.toString());
		const url = Endpoint.join([this.environmentService.identityServiceURI(), `validation-id/${validationId}/validateDocusignSelfie`]);
		return this.httpClient.post<any>(url.href, data, {headers});
	}

	public postSignatureAndTyc(signatureFile: string, authId: number, validationId: string, termsAndConditions: boolean): Observable<any> {
		const headers = this.headers;
		const data = {
			signatureFile: signatureFile,
			authId: authId,
			validationId: validationId,
			termsAndConditions: termsAndConditions,
		};
		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `ac/accept-tyc`]);
		return this.httpClient.post<any>(url.href, data, {headers});
	}

	public postAcceptProductsReceipt(authId: number): Observable<any> {
		const headers = this.headers;
		const data = {authId: authId};
		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `ac/aceptarReciboDeMercaderia`]);
		return this.httpClient.post<any>(url.href, data, {headers});
	}

	public postRecordReceiptProductUrl(authId: number): Observable<any> {
		const headers = this.headers;
		const data = {authId: authId};
		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `ac/constanciaReciboProductoUrl`]);
		return this.httpClient.post<any>(url.href, data, {headers});
	}

	public sendMutuoToVendedor(authId: number): Observable<any> {
		const headers = this.headers;
		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `ac/sendMutuoToVendedor/${authId}`]);
		return this.httpClient.post<any>(url.href, {headers});
	}
}
