import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {RestApiService} from '../../_services/rest-api.service';
import {NuevaSolicitud} from '../../_models/nueva-solicitud.model';
import {Autorizacion} from '../../_models/autorizacion.model';
import {UIService} from '../../_services/ui.service';
import {Cliente} from '../../_models/cliente.model';
import {ClienteService} from '../../_services/cliente.service';
import {AuthResponse} from '../../_models/auth-response.model';
import {Sources} from '../../_models/sources.model';
import {SourcesService} from '../../_services/sources.service';
import {HttpErrorResponse} from '@angular/common/http';
import {UntypedFormGroup} from '@angular/forms';
import {AuthorizationService} from '../../_services/authorization.service';
import {ResponseStatus} from '../../_enums/response-status.enum';
import {CreditTypeForDNIPlan} from '../../_enums/credit-type-plan-DNI';
import {OnboardingStep} from '../../_enums/onboarding-step.enum';
import {finalize, take} from 'rxjs/operators';
import {OnboardingService} from '../../_services/onboarding-steps.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {TitleService} from '../../_services/title.service';
import {StyleServiceService} from '../../_services/style-service.service';
import {ErrorPageService} from 'src/app/_services/error-page.service';
import {Router} from '@angular/router';
import {Routes} from 'src/app/_enums/routes.enum';
import {ErrorMessage} from 'src/app/_enums/error-message.enum';
import {Step} from '../../_enums/step.enum';
import {Gender} from '../../_enums/gender.enum';
import {LogType} from '../../_enums/log-type.enum';
import {InternalErrorCode} from '../../_enums/internal-error-code.enum';

@Component({
	selector: 'app-datos-personales',
	templateUrl: './datos-personales.component.html',
	styleUrls: ['./datos-personales.component.css'],
})
export class DatosPersonalesComponent implements OnInit {
	public useCredilowStyle: boolean;
	private autorizacion: Autorizacion = new Autorizacion();
	private nuevaSolicitud: NuevaSolicitud = new NuevaSolicitud();
	private sources: Sources = new Sources();

	public cliente: Cliente = new Cliente();
	public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private clienteServiceSubscription: Subscription;
	private monitoringLogSubscription: Subscription;

	constructor(
		public styleService: StyleServiceService,

		private readonly restApiService: RestApiService,
		private readonly autorizacionService: AuthorizationService,
		private readonly clienteService: ClienteService,
		private readonly uIService: UIService,
		private readonly onboardingService: OnboardingService,
		private readonly fuentesService: SourcesService,
		private titleService: TitleService,
		private errorPageService: ErrorPageService
	) {}

	ngOnInit(): void {
		this.titleService.updateTitle('Datos personales');
		this.autorizacionService.getAutorizacion().subscribe({
			next: (autorizacion: Autorizacion) => (this.autorizacion = autorizacion),
			error: (error: Error) => console.log(error),
		});
		this.clienteServiceSubscription = this.clienteService
			.getCliente()
			.pipe(take(1))
			.subscribe({
				next: (cliente: Cliente) => {
					this.cliente = cliente;
					this.restApiService
						.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.StartDatosPersonales, logType: LogType.Flujo})
						.subscribe(() => {
							this.unsubscribe();
						});
				},
				error: (error: Error) => console.log(error),
			});
		this.fuentesService.getFuentes().subscribe({
			next: (sources: Sources) => (this.sources = sources),
			error: (error: Error) => console.log(error),
		});
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		});
	}

	private unsubscribe(): void {
		if (this.clienteServiceSubscription && !this.clienteServiceSubscription.closed) {
			this.clienteServiceSubscription.unsubscribe();
		}
	}

	public fillClient(formGroupData: UntypedFormGroup, newClient: boolean) {
		const mobilePhoneInput = formGroupData.controls.mobilePhone.value;
		this.cliente.celular = mobilePhoneInput.charAt(0) === '*' ? this.cliente.celular : mobilePhoneInput;
		this.cliente.comercio = formGroupData.controls.commerce.value;
		if (newClient) {
			this.cliente.trabajoEnBlanco = formGroupData.controls.blankJob.value;
			this.cliente.telefonoFijo = formGroupData.controls.homePhone.value;
			if (formGroupData.controls.commerce.value.ofrecePlanDNINuevos)
				this.cliente.cantidadServicios = formGroupData.controls.amountOfServices.value;
		}
		this.nextStep();
	}

	private removeLeadingZeros(telefonoFijo: number): number {
		if (telefonoFijo === null || telefonoFijo === undefined) {
			return null;
		}
		const sanitizedValue = telefonoFijo.toString().replace(/^0+/, ''); // Remove leading zeros
		return parseInt(sanitizedValue, 10); // Convert back to number
	}

	private nextStep(): void {
		this.loading.next(true);
		this.nuevaSolicitud.nuevo = this.cliente.nuevo;
		this.nuevaSolicitud.comercio = this.cliente.comercio.Id;
		this.nuevaSolicitud.sexo = this.cliente.sexo;
		this.nuevaSolicitud.dni = this.cliente.dni;
		this.nuevaSolicitud.celular = this.cliente.celular;
		this.nuevaSolicitud.fijo = this.removeLeadingZeros(this.cliente.telefonoFijo);
		this.nuevaSolicitud.empleo = this.cliente.trabajoEnBlanco;
		this.nuevaSolicitud.fechaNacimiento = this.cliente.fechaNacimiento;
		this.nuevaSolicitud.tarjeta = this.cliente.tarjeta;
		this.nuevaSolicitud.validationId = this.cliente.validationId;
		this.nuevaSolicitud.cantServicios = this.cliente.cantidadServicios;
		this.nuevaSolicitud.acceptedUnderCommerceRiskDate = this.autorizacionService.getAcceptedUnderCommerceRisk();
		this.restApiService
			.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.NuevaSolicitudInicio, logType: LogType.Flujo})
			.subscribe();
		this.restApiService
			.nuevaSolicitud(this.nuevaSolicitud)
			.pipe(
				finalize(() => {
					this.loading.next(false);
				})
			)
			.subscribe({
				next: (data: AuthResponse) => {
					if (data.hasOwnProperty('status') && data.status !== 'error') {
						//@TURBIO VIENE DEL BACK ASI
						this.uIService.responseLog('nuevaSolicitud - OK', data);
						this.restApiService
							.monitoringLog({
								creditFlowId: this.cliente.creditFlowId,
								step: Step.NuevaSolicitud,
								logType: LogType.Flujo,
								authId: data.authId,
							})
							.subscribe();

						this.autorizacion.id = data.authId;
						this.autorizacion.reporteRecienteAprobado = data.reporteRecienteAprobado;
						this.autorizacion.comercio = this.cliente.comercio;
						this.cliente.renovador = data.renovador;
						this.cliente.tipo = data.tipo;
						this.clienteService.setCliente(this.cliente);
						this.autorizacionService.setAutorizacion(this.autorizacion);
						this.onboardingService.moveTo(OnboardingStep.ClientData);
						this.validarFuentes();
					} else {
						this.uIService.errorMessage('Error!', data.message);
						this.uIService.responseLog('nuevaSolicitud - ERROR', data);
						this.restApiService
							.monitoringLog({
								creditFlowId: this.cliente.creditFlowId,
								step: Step.NuevaSolicitud,
								logType: LogType.Consola,
								error: data.message,
								authId: data.authId,
							})
							.subscribe();
					}
				},
				error: (error: HttpErrorResponse) => {
					this.restApiService
						.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.NuevaSolicitud, logType: LogType.Consola, error})
						.subscribe();
					if (error.error.code === ErrorMessage.AASException) {
						this.uIService.errorMessage('Error!', error.error.payload.scalar);
						this.uIService.responseLog('nuevaSolicitud - ERROR', error.error);
					} else {
						this.uIService.errorMessage('Error!', error.error);
						this.uIService.responseLog('nuevaSolicitud - ERROR', error.error.message);
					}
				},
			});
	}

	private validarFuentes(): void {
		if (this.autorizacion.reporteRecienteAprobado) {
			this.correrReporteCrediticio();
			return;
		}
		this.restApiService
			.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.StartValidarFuentes, logType: LogType.Flujo})
			.subscribe();
		setTimeout(() => {
			this.restApiService.estadoFuentes(this.autorizacion.id).subscribe({
				next: (data: any) => {
					if (data.status == 'error') {
						this.uIService.responseLog('estadoFuentes - ERROR', data);
						this.restApiService
							.monitoringLog({
								creditFlowId: this.cliente.creditFlowId,
								step: Step.ValidarFuentes,
								logType: LogType.Consola,
								error: data,
							})
							.subscribe();
						this.errorPageService.setError(data.message, null, null, Routes.CreateCredit);
						return;
					} else {
						this.uIService.responseLog('estadoFuentes - OK', data);
						if (!data.resultado) {
							this.restApiService
								.monitoringLog({
									creditFlowId: this.cliente.creditFlowId,
									step: Step.ValidarFuentes,
									logType: LogType.Consola,
									error: data,
								})
								.subscribe();
							this.uIService.errorMessage('Error', data.novedad);
							this.errorPageService.setError(data.message, null, null, Routes.CreateCredit);

							return;
						}
						this.fuentesService.setFuentes(data);
						if (this.sources.checked === this.sources.count) {
							this.restApiService
								.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.ValidarFuentes, logType: LogType.Flujo})
								.subscribe();
							this.correrReporteCrediticio();
						} else {
							this.validarFuentes();
							return;
						}
					}
				},
				error: (error: HttpErrorResponse) => {
					this.restApiService
						.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.ValidarFuentes, logType: LogType.Consola, error})
						.subscribe();
					this.errorPageService.setError(
						'Error de Nosis',
						'Error al consultar el reporte crediticio. Por favor, reintenta dentro de unos minutos',
						null,
						Routes.CreateCredit
					);
					this.uIService.responseLog('estadoFuentes - ERROR', error);
				},
			});
		}, 5000);
	}

	private correrReporteCrediticio(): void {
		this.restApiService
			.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.StartReporteCrediticio, logType: LogType.Flujo})
			.subscribe();
		this.restApiService.correrReporteCrediticio(this.autorizacion.id).subscribe({
			next: (data: AuthResponse) => {
				if (data.status === ResponseStatus.Wait) {
					this.uIService.responseLog('correrReporteCrediticio - OK', data);
					this.correrReporteCrediticio();
					return;
				} else if (data.status === ResponseStatus.Success) {
					if (data.empleo && data.tipoCredito in CreditTypeForDNIPlan) {
						this.restApiService
							.monitoringLog({
								creditFlowId: this.cliente.creditFlowId,
								step: Step.ReporteCrediticio,
								logType: LogType.Consola,
								error: data.message,
							})
							.subscribe();
						this.uIService.responseLog('correrReporteCrediticio - ERROR', data.message);
						this.uIService.errorMessage('Error', data.message);
						this.onboardingService.restart();
						this.clienteService.restart();
						this.errorPageService.setError(data.message, null, null, Routes.Home);
						return;
					}
					this.uIService.responseLog('correrReporteCrediticio - OK', data);
					this.restApiService
						.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.ReporteCrediticio, logType: LogType.Flujo})
						.subscribe();
					this.getMontoMaximo();
					return;
				} else {
					this.uIService.responseLog('Error', data.message);
					this.uIService.responseLog('correrReporteCrediticio - ERROR', data);
					this.restApiService
						.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.ReporteCrediticio, logType: LogType.Consola, error: data})
						.subscribe();
					this.loading.next(false);
					this.onboardingService.restart();
					this.clienteService.restart();
					this.errorPageService.setError(data.message, null, null, Routes.Home);
					return;
				}
			},
			error: (error: HttpErrorResponse) => {
				this.restApiService
					.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.ReporteCrediticio, logType: LogType.Consola, error})
					.subscribe();
				this.uIService.responseLog('correrReporteCrediticio - ERROR', error);
				this.errorPageService.setError(error.message, null, null, Routes.Home);
			},
		});
	}

	private getMontoMaximo(): void {
		// this.restApiService.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.BeforeMontoMaximo, logType:LogType.Flujo}).subscribe();
		this.restApiService.getMontoMaximo(this.autorizacion.id).subscribe({
			next: (data: AuthResponse) => {
				if (data.status === ResponseStatus.Wait) {
					this.getMontoMaximo();
					return;
				} else if (data.status === ResponseStatus.Success) {
					// this.restApiService.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.MontoMaximo, logType:LogType.Flujo}).subscribe();
					this.uIService.responseLog('getMontoMaximo - OK', data);
					this.autorizacion.montoMaximo = data.monto;
					this.autorizacionService.setAutorizacion(this.autorizacion);
					return;
				} else if (data.status === ResponseStatus.Error) {
					this.restApiService
						.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.MontoMaximo, logType: LogType.Consola, error: data})
						.subscribe();
					this.loading.next(false);
					this.uIService.responseLog('getMontoMaximo - ERROR', data);
					this.uIService.errorMessage('Error', data.message);
					return;
				}
			},
			error: (error: HttpErrorResponse) => {
				this.loading.next(false);
				this.restApiService
					.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.MontoMaximo, logType: LogType.Consola, error})
					.subscribe();
				this.uIService.responseLog('getMontoMaximo - ERROR', error);
				this.errorPageService.setError(error.error.message, error.error.payload.scalar, null, Routes.CreateCredit);
			},
		});
	}

	ngOnDestroy(): void {
		this.unsubscribe();
	}
}
