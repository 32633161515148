<div class="fill position-relative credilow-sign credilow-monto-solicitado d-flex flex-column">
	<h6>Verificar cliente por medio de:</h6>
	<div
		class="credilow-plan-cuota text-left"
		(click)="changeSignType(SignType.Web)"
		[ngClass]="{
			'credilow-plan-cuota-active': selectedSignType === SignType.Web
		}"
	>
		<span
			[ngClass]="{
				'credilow-plan-cuota-dot-active': selectedSignType === SignType.Web,
				'credilow-plan-cuota-dot': selectedSignType !== SignType.Web
			}"
		></span>
		<b>Web</b>
		<span class="ml-1 credilow-plan-cuota-amount float-none">(Envío de e-mail al cliente)</span>
	</div>
	<div
		class="credilow-plan-cuota text-left"
		(click)="changeSignType(SignType.CallCenter)"
		[ngClass]="{
			'credilow-plan-cuota-active': selectedSignType === SignType.CallCenter
		}"
		id="btn-paper-sign"
	>
		<span
			[ngClass]="{
				'credilow-plan-cuota-dot-active': selectedSignType === SignType.CallCenter,
				'credilow-plan-cuota-dot': selectedSignType !== SignType.CallCenter
			}"
		></span>
		<b>Firma en papel</b>
	</div>
	<div class="credilow-footer credilow-navigate space-between-buttons">
		<div class="credilow-50">
			<button class="credilow-previous-button credilow-shadow" [ngClass]="{sanii: !useCredilowStyle}" (click)="cancel()">Cancelar</button>
		</div>
		<div class="credilow-50">
			<button
				class="credilow-next-button credilow-shadow"
				[ngClass]="{sanii: !useCredilowStyle}"
				id="btn-veriy-nxt"
				(click)="sign()"
				appEnterClick
			>
				Confirmar
			</button>
		</div>
	</div>
</div>
<app-loader *ngIf="loading | async"></app-loader>
