import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit, ViewChild, AfterViewInit, Input} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {ComercioConVendedores} from 'src/app/_models/comercio-con-vendedores.model';
import {VendedorLista} from 'src/app/_models/vendedor-lista.model';
import {TitleService} from '../../../_services/title.service';
import {VendedoresService} from 'src/app/_services/vendedores.service';
import {PhoneValidator} from 'src/app/_validator/mobile-phone.validator';
import {UIService} from 'src/app/_services/ui.service';
import {finalize} from 'rxjs/operators';
import {DialogService} from 'src/app/_services/dialog.service';
import {DialogComponent} from 'src/app/component/dialog/dialog.component';
import {ErrorMessage} from 'src/app/_enums/error-message.enum';
import {Routes} from 'src/app/_enums/routes.enum';

import {StyleServiceService} from '../../../_services/style-service.service';

@Component({
	selector: 'app-create-or-edit-form',
	templateUrl: './create-or-edit-form.component.html',
	styleUrls: ['./create-or-edit-form.component.css'],
})
export class CreateOrEditFormComponent implements OnInit {
	public useCredilowStyle: boolean;
	@ViewChild('f') editOrCreateUserForm: UntypedFormGroup;
	@Input() title: BehaviorSubject<string> = new BehaviorSubject<string>('Crear vendedor');

	public subscription: Subscription;
	public commercesWithEmployees: ComercioConVendedores[];
	public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	public editMode: boolean = false;
	public editedUserId: number;
	public editedUser: VendedorLista;
	public formIsTouched: boolean = false;
	public formUnchanged: boolean = true;

	public formValueChanges = new Subject<void>();

	constructor(
		public styleService: StyleServiceService,

		private vendedoresService: VendedoresService,
		private route: ActivatedRoute,
		private titleService: TitleService,
		private router: Router,
		private readonly uIService: UIService,
		private dialogService: DialogService
	) {}

	ngOnInit(): void {
		this.titleService.updateTitle('Crear o Editar');
		this.route.params.subscribe((params) => {
			if (params['userID']) {
				this.editMode = true;
				this.editedUserId = +params['userID'];
				this.editedUser = this.vendedoresService.getVendedorByUserId(this.editedUserId);
			}
		});
		this.editOrCreateUserForm = new UntypedFormGroup({
			user: new UntypedFormControl(null),
			name: new UntypedFormControl(null),
			cellphone: new UntypedFormControl(null),
			email: new UntypedFormControl(null),
		});
	}

	ngAfterViewInit() {
		setTimeout(() => {
			if (this.editMode) {
				this.editOrCreateUserForm.setValue({
					username: this.editedUser.username,
					name: this.editedUser.vendedor,
					cellphone: this.editedUser.cellphone,
					email: this.editedUser.email,
				});
			}
			this.editOrCreateUserForm.controls.cellphone.setValidators([PhoneValidator.mobile()]);
			this.editOrCreateUserForm.controls.email.setValidators([Validators.required, Validators.email]);
			this.editOrCreateUserForm.controls.name.setValidators([Validators.required, Validators.minLength(3)]);
			// this.editOrCreateUserForm.controls.user.setValidators([Validators.required, Validators.minLength(3)]);
			this.editOrCreateUserForm.valueChanges.subscribe(() => {
				this.formIsTouched = true;
				this.formValueChanges.next();
				this.checkFormUnchanged();
			});
		}, 0);
	}

	onSubmit(form: UntypedFormGroup) {
		const value = form.value;
		if (this.editMode) {
			this.editedUser.username = value.username;
			this.editedUser.vendedor = value.name;
			this.editedUser.cellphone = value.cellphone;
			this.editedUser.email = value.email;
			this.editVendedor(this.editedUser);
		} else {
			this.createNewUser(form);
		}
	}

	public editVendedor(editedUser: VendedorLista) {
		this.loading.next(true);
		const rta = this.vendedoresService
			.editVendedor(editedUser)
			.pipe(finalize(() => this.loading.next(false)))
			.subscribe({
				next: (data: any) => {
					this.uIService.responseLog('editVendedor - OK', data);
					this.uIService.successMessage('Éxito!', 'Usuario editado');
				},
				error: (error: HttpErrorResponse) => {
					this.uIService.responseLog('editVendedor - ERROR', error);
					if (error.error.code == ErrorMessage.ParameterValuesError) {
						const errorPayload = error.error.payload;
						for (const key in errorPayload) {
							if (errorPayload[key]) {
								this.uIService.errorMessage('Error!', errorPayload[key]);
							}
						}
					} else {
						this.uIService.errorMessage('Error!', error.error.message);
					}
				},
			});
	}

	public createNewUser(form: UntypedFormGroup) {
		const value = form.value;
		this.loading.next(true);
		const rta = this.vendedoresService
			.createVendedor(value.username, value.name, value.email, value.cellphone)
			.pipe(finalize(() => this.loading.next(false)))
			.subscribe({
				next: (data: any) => {
					this.uIService.responseLog('createVendedor - OK', data);
					const dialogRef = this.dialogService.openDialog(DialogComponent, {
						title: 'Usuario creado con éxito',
						data: 'Se envió un e-mail a la casilla del vendedor con la contraseña. ',
						buttonLabel: 'Cerrar',
					});
					dialogRef.afterClosed().subscribe((result) => {
						this.router.navigate([Routes.AdminVendedores]);
					});
				},
				error: (error: HttpErrorResponse) => {
					this.uIService.responseLog('createVendedor - ERROR', error);
					if (error.error.code == ErrorMessage.ParameterValuesError) {
						const errorPayload = error.error.payload;
						for (const key in errorPayload) {
							if (errorPayload[key]) {
								this.uIService.errorMessage('Error!', errorPayload[key]);
							}
						}
					} else {
						this.uIService.errorMessage('Error!', error.error.message);
					}
				},
			});
	}

	public goBack(): void {
		this.router.navigate([Routes.AdminVendedores]);
	}

	public isInvalidForm(formControl: AbstractControl): boolean {
		return formControl.touched && formControl.invalid;
	}

	get formIsNotTouchedOrUnchanged() {
		return !this.formIsTouched || this.editOrCreateUserForm.value === this.editedUser;
	}

	public checkFormUnchanged() {
		this.formUnchanged =
			this.editOrCreateUserForm.value.email === this.editedUser.email || this.editOrCreateUserForm.value.name === this.editedUser.vendedor;
	}
}
