<div class="container h-100 d-flex flex-column">
	<form [formGroup]="simulatorForm" class="d-flex flex-grow-1">
		<div *ngIf="planes.length <= 0" class="d-flex flex-grow-1 flex-column">
			<div class="mb-3" class="d-flex flex-column">
				<h6>Comercio</h6>
				<x-comercio-select (selectedChanges)="selectComercio($event)"></x-comercio-select>
				<h6>Documento</h6>
				<input type="text" placeholder="Documento" formControlName="dni" digitOnly />
				<h6 *ngIf="hideAmmountAvailable | async">Saldo disponible</h6>
				<input *ngIf="hideAmmountAvailable| async" type="text" placeholder="Saldo Disponible" [value]="ammountAvailable" digitOnly readonly style="color:rgba(0,0,0,.38)"/>
				<h6 *ngIf="hideClientType | async">Persona</h6>
				<x-default-select
					*ngIf="hideClientType | async"
					(selectedData)="selectedClientType($event)"
					[inputSelectedData]="clientTypeIndex"
					[data]="tiposCliente"
					selectTitle="Persona"
					selectOptionKey="Descripcion"
				></x-default-select>
				<h6 *ngIf="hideAmountRequest | async">Capital Pedido</h6>
				<input type="text" placeholder="Capital Pedido" formControlName="amountRequest" *ngIf="hideAmountRequest | async" digitOnly />
				<h6 *ngIf="hideEarnings | async">Ingreso</h6>
				<input type="text" placeholder="Ingreso" formControlName="earnings" *ngIf="hideEarnings | async" digitOnly />
				<h6 *ngIf="hideScore | async">Nivel Score</h6>
				<x-default-select
					*ngIf="hideScore | async"
					(selectedData)="selectedScore($event)"
					[data]="scoreLevel"
					selectTitle="Score"
					selectOptionKey="level"
				></x-default-select>
			</div>
			<div class="credilow-footer credilow-navigate space-between-buttons">
				<div class="credilow-50">
					<button class="credilow-previous-button credilow-shadow" [ngClass]="{sanii: !useCredilowStyle}" (click)="goBack()">
						Cancelar
					</button>
				</div>
				<div class="credilow-50">
					<button
						class="credilow-next-button credilow-shadow"
						[ngClass]="{sanii: !useCredilowStyle}"
						[disabled]="simulatorForm.invalid"
						(click)="buscarPlanes()"
						style="font-size: 14px"
					>
						Buscar Planes
					</button>
				</div>
			</div>
		</div>
	</form>

	<div *ngIf="planes.length > 0" class="d-flex flex-column h-100">
		<div class="credilow-planes text-center">
			<div class="credilow-plan-cuota d-flex flex-column" *ngFor="let plan of planes">
				<span>
					<b>{{ plan.CantidadCuotas }} Cuotas</b>
				</span>
				<span>
					<b>Plan:</b>
					({{ plan.Descripcion }})
				</span>
				<span>
					<b>Primer vencimiento:</b>
					{{ plan.PrimerVencimiento }}
				</span>
				<span>
					<b>Monto Cuota:</b>
					{{ plan.MontoCuota | money }}
				</span>
			</div>
		</div>

		<div class="credilow-footer credilow-navigate space-between-buttons">
			<button class="credilow-previous-button credilow-shadow" [ngClass]="{sanii: !useCredilowStyle}" (click)="goBack()">Volver</button>
		</div>
	</div>
</div>
<app-loader *ngIf="loading | async"></app-loader>
