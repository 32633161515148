import {HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {WebcamImage, WebcamInitError} from 'ngx-webcam';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {SigningProcessStep} from '../../_enums/signing-process-step.enum';
import {ImageConverter} from '../../_helpers/image-converter.support';
import {SigningProcess} from '../../_models/signing-process.model';
import {SigningProcessStepService} from '../../_services/signing-process-steps.service';
import {SigningProcessService} from '../../_services/signing-process.service';
import {UIService} from '../../_services/ui.service';

import {StyleServiceService} from 'src/app/_services/style-service.service';
import {EnvironmentService} from 'src/app/_services/enviroment.service';
import {DialogService} from 'src/app/_services/dialog.service';
import {DialogComponent} from '../dialog/dialog.component';
import {DomSanitizer} from '@angular/platform-browser';
import {InternalErrorCode} from 'src/app/_enums/internal-error-code.enum';
import {ErrorPageService} from 'src/app/_services/error-page.service';
import {Routes} from 'src/app/_enums/routes.enum';
import {RestApiService} from '../../_services/rest-api.service';
import {Step} from '../../_enums/step.enum';
import {LogType} from '../../_enums/log-type.enum';

@Component({
	selector: 'x-neutral-selfie',
	templateUrl: './neutral-selfie.component.html',
	styleUrls: ['./neutral-selfie.component.css'],
})
export class NeutralSelfieComponent implements OnInit {
	public useCredilowStyle: boolean;
	@Output() changeStepperState: EventEmitter<undefined> = new EventEmitter<undefined>();

	public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public showWebcam = true;
	public allowCameraSwitch = true;
	public multipleWebcamsAvailable = false;
	public deviceId: string;
	public facingMode: string = 'user';
	public webcamImage: WebcamImage = null;
	public productionEnv: boolean;

	public selfieUnsuccessfully: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	private trigger: Subject<void> = new Subject<void>();
	private signingProcess: SigningProcess;
	private signingProcessSubscription: Subscription;

	constructor(
		public styleService: StyleServiceService,
		private dialogService: DialogService,
		private sanitizer: DomSanitizer,

		private readonly signingProcessService: SigningProcessService,
		private readonly signingProcessStepService: SigningProcessStepService,
		private readonly uIService: UIService,
		private readonly environmentService: EnvironmentService,
		private errorPageService: ErrorPageService,
		private restApiService: RestApiService
	) {}

	public ngOnInit(): void {
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		});
		this.signingProcessSubscription = this.signingProcessService.getSigningProcess().subscribe({
			next: (signingProcess: SigningProcess) => {
				this.signingProcess = signingProcess;
				this.restApiService
					.monitoringLog({creditFlowId: this.signingProcess.creditFlowId, step: Step.StartNeutralSelfie, logType: LogType.Flujo})
					.subscribe(() => {
						this.unsubscribe();
					});
			},
			error: (error: Error) => console.log(error),
		});
		this.productionEnv = this.environmentService.environmentProduction();
	}

	private unsubscribe(): void {
		if (this.signingProcessSubscription && !this.signingProcessSubscription.closed) {
			this.signingProcessSubscription.unsubscribe();
		}
	}

	public triggerSnapshot(): void {
		this.trigger.next();
	}

	public handleInitError(error: WebcamInitError): void {
		if (error.mediaStreamError && error.mediaStreamError.name === 'NotAllowedError') {
			this.restApiService
				.monitoringLog({creditFlowId: this.signingProcess.creditFlowId, step: Step.NeutralSelfie, logType: LogType.Consola, error})
				.subscribe();
			console.log('User denied camera access');
			this.uIService.errorMessage('Error!', 'El usuario no permitio el uso de la camara');
		}
	}

	public handleImage(webcamImage: WebcamImage): void {
		this.restApiService
			.monitoringLog({creditFlowId: this.signingProcess.creditFlowId, step: Step.PostNeutralSelfie, logType: LogType.Flujo})
			.subscribe();
		this.webcamImage = webcamImage;
		const selfie = ImageConverter.dataURLtoFile(this.webcamImage.imageAsDataUrl, 'selfie.jpeg');
		this.selfieImage(selfie);
	}

	public get triggerObservable(): Observable<void> {
		return this.trigger.asObservable();
	}

	public get videoOptions(): MediaTrackConstraints {
		const result: MediaTrackConstraints = {};
		if (this.facingMode && this.facingMode !== '') {
			result.facingMode = {ideal: this.facingMode};
		}
		return result;
	}

	private selfieImage(selfie: File): void {
		this.loading.next(true);
		this.signingProcessService
			.postNeutralSelfie(this.signingProcess.validationId, selfie, this.signingProcess.authId)
			.pipe(finalize(() => this.loading.next(false)))
			.subscribe({
				next: (data: any) => {
					if (data.renaperStatus) {
						this.restApiService
							.monitoringLog({creditFlowId: this.signingProcess.creditFlowId, step: Step.NeutralSelfie, logType: LogType.Flujo})
							.subscribe();
						this.uIService.successMessage('Exitoso!', 'Validación con éxito');
					} else {
						this.showErrorSelfieUpload();
						this.restApiService
							.monitoringLog({
								creditFlowId: this.signingProcess.creditFlowId,
								step: Step.NeutralSelfie,
								logType: LogType.Consola,
								error: data,
							})
							.subscribe();
					}
					this.selfieUnsuccessfully.next(!data.renaperStatus);
				},
				error: (error: HttpErrorResponse) => {
					this.restApiService
						.monitoringLog({creditFlowId: this.signingProcess.creditFlowId, step: Step.NeutralSelfie, logType: LogType.Consola, error})
						.subscribe();
					if (error.error.errors[0].code == InternalErrorCode.InvalidValidationSelfie) {
						this.showInvalidSelfie(error.error.errors[0].message);
					} else {
						this.uIService.errorMessage('Error!', error.error.errors[0].message);
					}
				},
			});
	}

	public next(): void {
		if (!this.selfieUnsuccessfully.getValue()) {
			this.changeStepperState.emit();
			this.signingProcessStepService.moveTo(SigningProcessStep.SignaturePad);
		} else {
			this.restApiService
				.monitoringLog({
					creditFlowId: this.signingProcess.creditFlowId,
					step: Step.NeutralSelfie,
					logType: LogType.Consola,
					error: 'Los rostros de la persona no coinciden',
				})
				.subscribe();
			this.uIService.errorMessage('Error!', 'Los rostros de la persona no coinciden');
		}
	}

	onChange(event) {
		this.selfieImage(event.target.files[0]);
	}

	public showErrorSelfieUpload() {
		const dialogRef = this.dialogService.openDialog(DialogComponent, {
			title: 'Los rostros no coinciden',
			data: this.sanitizer.bypassSecurityTrustHtml(`No pudimos validar tu rostro, por favor intentá nuevamente.`),
			buttonLabel: 'Reintentar',
		});
	}

	public showInvalidSelfie(message: string) {
		this.errorPageService.setError(message, null, null, Routes.Credilow);
	}
}
