import {Component, OnInit} from '@angular/core';
import {Autorizacion} from '../../_models/autorizacion.model';
import {Cliente} from '../../_models/cliente.model';
import {Sources} from '../../_models/sources.model';
import {AuthorizationService} from '../../_services/authorization.service';
import {ClienteService} from '../../_services/cliente.service';
import {SourcesService} from '../../_services/sources.service';

import {StyleServiceService} from 'src/app/_services/style-service.service';

@Component({
	selector: 'x-sources-header',
	templateUrl: './sources-header.component.html',
	styleUrls: ['./sources-header.component.css'],
})
export class SourcesHeaderComponent implements OnInit {
	public useCredilowStyle: boolean;
	public autorizacion: Autorizacion = new Autorizacion();
	public cliente: Cliente = new Cliente();
	public fuentes: Sources = new Sources();

	constructor(
		public styleService: StyleServiceService,

		private readonly autorizacionService: AuthorizationService,
		private readonly clienteService: ClienteService,
		private readonly sourcesService: SourcesService
	) {}

	ngOnInit(): void {
		this.autorizacionService.getAutorizacion().subscribe({
			next: (autorizacion: Autorizacion) => (this.autorizacion = autorizacion),
			error: (error: Error) => console.log(error),
		});
		this.clienteService.getCliente().subscribe({
			next: (cliente: Cliente) => (this.cliente = cliente),
			error: (error: Error) => console.log(error),
		});
		this.sourcesService.getFuentes().subscribe({
			next: (fuentes: Sources) => (this.fuentes = fuentes),
			error: (error: Error) => console.log(error),
		});
	}
}
