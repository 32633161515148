import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Plan} from '../_models/plan.model';

@Injectable({
	providedIn: 'root',
})
export class PlanesService {
	private planes: BehaviorSubject<Plan[]> = new BehaviorSubject<Plan[]>([new Plan()]);

	getPlanes(): Observable<Plan[]> {
		return this.planes.asObservable();
	}

	setPlanes(planes: Plan[]): void {
		this.planes.next(planes);
	}
}
