import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Subscription, take} from 'rxjs';
import {TitleService} from '../../_services/title.service';
import {OnboardingStep} from '../../_enums/onboarding-step.enum';
import {Autorizacion} from '../../_models/autorizacion.model';
import {Cliente} from '../../_models/cliente.model';
import {Plan} from '../../_models/plan.model';
import {AuthorizationService} from '../../_services/authorization.service';
import {ClienteService} from '../../_services/cliente.service';
import {OnboardingService} from '../../_services/onboarding-steps.service';
import {PlanesService} from '../../_services/planes.service';

import {StyleServiceService} from '../../_services/style-service.service';
import {RestApiService} from '../../_services/rest-api.service';
import {Step} from '../../_enums/step.enum';
import {LogType} from '../../_enums/log-type.enum';

@Component({
	selector: 'app-plan-de-pago',
	templateUrl: './plan-de-pago.component.html',
	styleUrls: ['./plan-de-pago.component.css'],
})
export class PlanDePagoComponent implements OnInit {
	public useCredilowStyle: boolean;
	public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public planes: Plan[];
	public activePlan: number = undefined;

	private autorizacion: Autorizacion = new Autorizacion();
	private clienteServiceSubscription: Subscription;

	// TODO: Si no se usa, se debería borrar.
	private cliente: Cliente = new Cliente();

	constructor(
		public styleService: StyleServiceService,

		private readonly autorizacionService: AuthorizationService,
		private readonly planesService: PlanesService,
		private readonly onboardingService: OnboardingService,
		private readonly clienteService: ClienteService,
		private titleService: TitleService,
		private restApiService: RestApiService
	) {}

	public ngOnInit(): void {
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		});
		this.titleService.updateTitle('Plan de pago');
		this.autorizacionService.getAutorizacion().subscribe({
			next: (autorizacion: Autorizacion) => (this.autorizacion = autorizacion),
			error: (error: Error) => console.log(error),
		});
		this.clienteServiceSubscription = this.clienteService.getCliente().subscribe({
			next: (cliente: Cliente) => {
				this.cliente = cliente;
				this.restApiService.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.StartPlanDePago, logType:LogType.Flujo}).pipe(take(1)).subscribe();
			},
			error: (error: Error) => console.log(error),
		});
		this.planesService.getPlanes().subscribe({
			next: (planes: Plan[]) => (this.planes = planes),
			error: (error: Error) => console.log(error),
		});
	}

	public continue(): void {
		this.restApiService.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.PlanDePago, logType:LogType.Flujo}).subscribe();
		this.loading.next(true);
		this.autorizacion.planSeleccionado = this.planes[this.activePlan];
		this.autorizacionService.setAutorizacion(this.autorizacion);
		this.onboardingService.moveTo(OnboardingStep.ProductSpecification);
	}

	public cancelar(): void {
		if (this.clienteServiceSubscription) {
			this.clienteServiceSubscription.unsubscribe();
		}
		this.onboardingService.restart();
		this.clienteService.restart();
	}

	public changePlan(index: number): void {
		this.activePlan = index;
	}

	public goBack(): void {
		this.onboardingService.previousStep();
	}

	public ngOnDestroy(): void {
		// Unsubscribe from the clienteService subscription
		if (this.clienteServiceSubscription) {
		  this.clienteServiceSubscription.unsubscribe();
		}
	}
}
