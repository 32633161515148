import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Comercio} from '../_models/comercio.model';
import {NuevaSolicitud} from '../_models/nueva-solicitud.model';
import {Referencias} from '../_models/referencias.model';
import {Domicilio} from '../_models/domicilio.model';
import {Cliente} from '../_models/cliente.model';
import {EnvironmentService} from './enviroment.service';
import {map} from 'rxjs/operators';
import {ComercioConVendedores} from '../_models/comercio-con-vendedores.model';
import {Endpoint} from '../_helpers/endpoint.support';
import {DocumentAttached} from '../_models/document-attached.model';
import {VendedorLista} from '../_models/vendedor-lista.model';
import {Origin} from '../_enums/origin.enum';
import {Step} from '../_enums/step.enum';
import {Gender} from '../_enums/gender.enum';
import {LogType} from '../_enums/log-type.enum';
import {MonitoringLogData} from '../_models/monitoringLogData.model';

@Injectable({
	providedIn: 'root',
})
export class RestApiService {
	constructor(private httpClient: HttpClient, private environmentService: EnvironmentService) {}

	public findClient(identityId: string): Observable<Comercio[]> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		});

		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `ac/client/${identityId}`]);
		return this.httpClient.get<any>(url.href, {headers});
	}

	public getComercios(): Observable<Comercio[]> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		});

		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `ac/comercios`]);
		return this.httpClient.get<any>(url.href, {headers});
	}

	public nuevaSolicitud(data: NuevaSolicitud): Observable<any> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		});
		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `ac/nuevaSolicitudAC`]);
		return this.httpClient.post<any>(url.href, data, {headers});
	}

	public correrReporteCrediticio(authId: number): Observable<any> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		});

		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `runReporteCrediticio`]);
		return this.httpClient.post<any>(url.href, {authId: authId.toString()}, {headers});
	}

	public getMontoMaximo(authId: number): Observable<any> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		});

		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `getMontoMaximo`]);
		return this.httpClient.post<any>(url.href, {authId: authId}, {headers});
	}

	public estadoFuentes(authId: number): Observable<any> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		});

		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `getEstadoFuentes/${authId}`]);
		return this.httpClient.get<any>(url.href, {headers});
	}

	public actualizarReferencias(referencias: Referencias): Observable<any> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		});

		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `/ac/actualizarReferencias`]);
		return this.httpClient.post<any>(url.href, referencias, {headers});
	}

	public actualizarDomicilio(domicilio: Domicilio): Observable<any> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		});

		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `/ac/actualizarDomicilio`]);
		return this.httpClient.post<any>(url.href, domicilio, {headers});
	}

	public solicitarMonto(montoSolicitado: number, authId: number): Observable<any> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		});
		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `getPlanesParaMontoSolicitado/${montoSolicitado}/${authId}`]);
		return this.httpClient.get<any>(url.href, {headers});
	}

	public getCreditos() {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		});
		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `ac/creditos`]);
		return this.httpClient.get<any>(url.href, {headers});
	}

	public postActivarDesactivarVendedor(comercio: number, estadoUsuario: number, idUsuario: number): Observable<any> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		});
		const data = {idVendedor: idUsuario, estado: estadoUsuario};
		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `/ac/comercio/${comercio}/vendedor/${idUsuario}`]);
		return this.httpClient.post<any>(url.href, data, {headers});
	}

	public getVendedoresDeComercio(): Observable<any> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		});
		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `/ac/comercio/vendedores`]);
		return this.httpClient
			.get<any>(url.href, {headers})
			.pipe(map((comercios: ComercioConVendedores[]) => comercios.map((comercio) => ComercioConVendedores.from(comercio))));
	}

	public confirmarPlan(
		authId: number,
		productoAdquirido: string,
		metodoRetiro: number,
		planId: number,
		identityId: string,
		validationId: string,
		comentario: string
	): Observable<any> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		});
		const data = {
			authId: authId,
			productoAdquirido: productoAdquirido,
			metodoRetiro: metodoRetiro,
			planId: planId,
			identityId: identityId,
			validationId: validationId,
			comentario: comentario,
		};
		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `/ac/postSeleccionPlanAC`]);
		return this.httpClient.post<any>(url.href, data, {headers});
	}

	public ultimaValidacionReciente(dni: number): Observable<any> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		});
		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `ultimaValidacionReciente/${dni}`]);
		return this.httpClient.get<any>(url.href, {headers});
	}

	public adjuntarServicio(file: DocumentAttached, authId: number): Observable<any> {
		const formData: FormData = new FormData();
		formData.append(file.tipo, file.archivo);
		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `/ac/aprobacion/${authId}/documentacion/servicio`]);
		return this.httpClient.post<any>(url.href, formData);
	}

	public adjuntarAdicional(file: DocumentAttached, authId: number): Observable<any> {
		const formData: FormData = new FormData();
		formData.append(file.tipo, file.archivo);
		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `/ac/aprobacion/${authId}/documentacion/adicional`]);
		return this.httpClient.post<any>(url.href, formData);
	}

	public getTiposCliente(): Observable<any> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		});
		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `tiposCliente`]);
		return this.httpClient.get<any>(url.href, {headers});
	}

	public buscarPlanes(tipoCliente: number, ingreso: number, comercioId: number, monto: number, dni: string, score: string): Observable<any> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		});

		const data = {
			tipoCliente,
			ingreso,
			comercioId,
			monto,
			dni,
			score,
			origenCredito: Origin.AC,
		};

		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `buscarPlanes`]);
		return this.httpClient.post<any>(url.href, data, {headers});
	}

	public getDatosCliente(dni: string, comercioId: number = null): Observable<any> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		});
		var baseUrl = `ac/datosCliente/${dni}/comercio`;
		if (comercioId) {
			baseUrl += `/${comercioId}`;
		}
		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), baseUrl]);
		return this.httpClient.get<any>(url.href, {headers});
	}

	public commerceWithExceptionalLimit(commerceId: number): Observable<any> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		});
		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `comercio/${commerceId}/limiteExcepcional`]);
		return this.httpClient.get(url.href, {headers});
	}

	public commerceOfferPlanWithoutServices(commerce: Comercio): Observable<any> {
		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `comercio/${commerce}/ofrecePlanSS`]);
		return this.httpClient.post(url.href, {comercio: commerce});
	}

	public getMutuo(authId: number, planId: number, montoCuota: number, metodoRetiro: string): Observable<string> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		});
		const data = {
			authId: authId,
			plan_id: planId,
			montoCuota: montoCuota,
			metodoRetiro: metodoRetiro,
		};
		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), 'ac/mutualAgreementURL']);
		return this.httpClient.post(url.href, data, {headers, responseType: 'text'});
	}

	public editVendedor(userId: number, name: string, cellphone: string, email: string): Observable<any> {
		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `ac/editVendedor`]);
		return this.httpClient.post(url.href, {
			userId: userId,
			name: name,
			cellphone: cellphone,
			email: email,
		});
	}

	public createVendedor(username: string, name: string, email: string, cellphone: string): Observable<any> {
		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `ac/createVendedor`]);
		return this.httpClient.post(url.href, {
			username: username,
			name: name,
			cellphone: cellphone,
			email: email,
		});
	}

	public monitoringLog(data: MonitoringLogData): Observable<any> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		});

		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `ac/monitoringLog`]);
		const payload: any = {
			step: data.step,
			creditFlowId: data.creditFlowId,
			logType: data.logType,
		};

		if (data.gender !== null && data.gender !== undefined) {
			payload.gender = data.gender;
		}
		if (data.dni !== null && data.dni !== undefined) {
			payload.dni = data.dni;
		}
		if (data.authId !== null && data.authId !== undefined) {
			payload.authId = data.authId;
		}
		if (data.origin !== null && data.origin !== undefined) {
			payload.origin = data.origin;
		}
		if (data.error !== null && data.error !== undefined) {
			payload.error = data.error;
		}
		if (data.validationId !== null && data.validationId !== undefined) {
			payload.validationId = data.validationId;
		}
		return this.httpClient.post(url.href, payload, {headers: headers});
	}

	public getCreditFlowFromAuthId(authId: number): Observable<any> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		});
		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `ac/credit-flow/${authId}`]);
		return this.httpClient.get(url.href, {headers});
	}

	public fetchPresignedURLpdfFlyerAndParams() {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		});
		const url = Endpoint.join([this.environmentService.automaticAuthorizerURI(), `ac/pdf-flyer-params`]);
		return this.httpClient.get(url.href, {headers});
	}
}
