import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {HeaderStep} from '../_enums/header-step.enum';
import {OnboardingStep} from '../_enums/onboarding-step.enum';
import {Routes} from '../_enums/routes.enum';
import {AuthorizationService} from './authorization.service';

@Injectable({
	providedIn: 'root',
})
export class OnboardingService {
	constructor(private readonly autorizacion: AuthorizationService, private router: Router) {}

	private _step: BehaviorSubject<OnboardingStep> = new BehaviorSubject<OnboardingStep>(OnboardingStep.Home);

	public get step(): Observable<OnboardingStep> {
		return this._step.asObservable();
	}

	private _previousStep: Array<OnboardingStep> = [OnboardingStep.Home];

	public previousStep(): void {
		const prevStep = this._previousStep.pop();
		this._step.next(prevStep);
	}

	public moveTo(step: OnboardingStep): void {
		this._previousStep.push(this._step.value);
		this._step.next(step);
	}

	public restart(): void {
		this.autorizacion.resetAutorizacion();
		this._previousStep = [OnboardingStep.Home];
		this._step.next(OnboardingStep.Home);
		this.router.navigate([Routes.Home]);
	}
}
