/**
 * @description
 *  Clase de soporte que facilita crear endpoints y normalizarlos.
 */

export class ImageConverter {
	private constructor() {
		throw new Error('Nunca debe instanciar o heredar de esta clase (es de soporte).');
	}

	/**
	 * @description
	 *  Convierte una dataUrl en un File
	 *
	 * @param dataurl
	 *  Data url relacionada la foto a convertir
	 *
	 * @param filename
	 *  Nombre que le queremos dar al archivo
	 *
	 * @returns
	 *  Retorna un tipo de dato File
	 */
	public static dataURLtoFile(dataurl, filename) {
		var arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], filename, {type: mime});
	}
}
