import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationService} from '../../_services/authentication.service';
import {Optional} from 'src/app/_models/optional.model';
import {User} from 'src/app/_models/user.model';
import {UserType} from '../../_enums/user-type.enum';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import {Routes} from 'src/app/_enums/routes.enum';
import {StyleServiceService} from 'src/app/_services/style-service.service';
import { EnvironmentService } from 'src/app/_services/enviroment.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
	public useCredilowStyle: boolean;
	@Input() showRequest: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	@Input() showMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	@Input() home: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	@Input() title: BehaviorSubject<string> = new BehaviorSubject<string>('Credilow');

	public currentUser: Optional<User>;
	public showAdminSellers: boolean = false;
	public productionEnv: boolean;

	constructor(public styleService: StyleServiceService, private authenticationService: AuthenticationService, private router: Router,
				private readonly environmentService: EnvironmentService,
				) {
		this.authenticationService.currentUser.subscribe((user) => {
			this.currentUser = user;
		});
		this.productionEnv = this.environmentService.environmentProduction();
	}

	public ngOnInit(): void {
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		});
		this.currentUser.ifPresent((currentUser) => {
			if (currentUser.usuario_administrador === UserType.Administrator) {
				this.showAdminSellers = true;
			}
		});
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		});
	}

	public changePage(pageTitle: string, page: BehaviorSubject<boolean>): void {
		this.home.next(false);
		this.title.next(pageTitle);
		this.showMenu.next(false);
		page.next(true);
	}

	public changeMenu(): void {
		this.showMenu.next(!this.showMenu.value);
	}

	public createCredit(): void {
		this.router.navigate([Routes.CreateCredit]);
	}

	public loadAdminVendedores(): void {
		this.router.navigate([Routes.AdminVendedores]);
	}

	public showQR(): void {
		this.router.navigate([Routes.ShowQR]);
	}

	public creditsByVendedor(): void {
		this.router.navigate([Routes.CreditsByVendedor]);
	}

	public creditSimulator(): void {
		this.router.navigate([Routes.CreditSimulator]);
	}

	toggleStyle() {
		this.styleService.toggleStyle();
	}
}
