<div class="fill position-relative d-flex flex-column">
	<div class="credilow-planes">
		<div
			class="credilow-plan-cuota"
			*ngFor="let plan of planes; index as i"
			[ngClass]="{'credilow-plan-cuota-active': i === activePlan}"
			(click)="changePlan(i)"
		>
			<span
				[ngClass]="{
					'credilow-plan-cuota-dot-active': i === activePlan,
					'credilow-plan-cuota-dot': i !== activePlan
				}"
			></span>
			<b>{{ plan.CantidadCuotas }} cuotas</b>
			<span class="credilow-plan-cuota-amount" [ngClass]="{'credilow-plan-cuota-amount-active': i === activePlan}">
				{{ plan.MontoCuota | money }}
			</span>
		</div>
	</div>
	<div class="credilow-footer credilow-navigate space-between-buttons">
		<div class="credilow-50">
			<button class="credilow-previous-button credilow-shadow" [ngClass]="{sanii: !useCredilowStyle}" (click)="goBack()">Volver</button>
		</div>
		<div class="credilow-50">
			<button
				class="credilow-next-button credilow-shadow"
				[ngClass]="{sanii: !useCredilowStyle}"
				[disabled]="activePlan === undefined"
				(click)="continue()"
				id="btn-plan-pago-nxt"
				appEnterClick
			>
				Continuar
			</button>
		</div>
	</div>
</div>
<app-loader *ngIf="loading | async"></app-loader>
