export class Referencias {
	authId: string;
	telReferencia: string;
	nombreReferencia: string;
	celular: boolean;
	relacionReferencia: string;
	telReferencia2: string;
	nombreReferencia2: string;
	celular2: boolean;
	relacionReferencia2: string;
	telReferencia3: string;
	nombreReferencia3: string;
	celular3: boolean;
	relacionReferencia3: string;
	servicio: string;
	proveedor: string;
	titular: string;
	relacionServicio: string;
	servicio2: string;
	otroServicio: string;
	proveedor2: string;
	titular2: string;
	relacionServicio2: string;
	propio: boolean;
	propio2: boolean;
}
