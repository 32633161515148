/**
 * @description
 *	Clase para almacenar los datos de los usuarios.
 */

import {UserType} from '../_enums/user-type.enum';

export class User {
	public commerce_user: string;
	public commerce_id: number;
	public imprime_mutuos_comercio: boolean;
	public name: string;
	public token: string;
	public usuario_administrador: UserType;

	/**
	 * @description
	 *	Retorna si el usuario es administrador o no
	 */

	public isAdmin(): boolean {
		return this.usuario_administrador == UserType.Administrator;
	}

	public static from(anemicUser: User): User {
		const user: User = new User();
		user.commerce_user = anemicUser.commerce_user;
		user.commerce_id = anemicUser.commerce_id;
		user.imprime_mutuos_comercio = anemicUser.imprime_mutuos_comercio;
		user.name = anemicUser.name;
		user.token = anemicUser.token;
		user.usuario_administrador = anemicUser.usuario_administrador;
		return user;
	}
}
