<div class="fill d-flex flex-column w-100">
	<div *ngIf="(signingProcessStep | async) === SigningProcessStep.SigningProcessRedirect; else signingProcess" class="credilow-container"></div>
	<ng-template #signingProcess>
		<div class="credilow-header credilow-shadow" [ngClass]="{sanii: !useCredilowStyle}">
			<div class="row justify-content-center">
				<div>
					<span *ngIf="useCredilowStyle">Credilow</span>
					<span *ngIf="!useCredilowStyle">Sanii</span>
				</div>
			</div>
			<div class="row pl-2 pr-2">
				<mat-horizontal-stepper linear class="mat-horizontal-stepper" [ngClass]="{sanii: !useCredilowStyle}" labelPosition="bottom" #stepper>
					<ng-template matStepperIcon="edit">
						<mat-icon>check</mat-icon>
					</ng-template>
					<mat-step>
						<ng-template matStepLabel>Selfie</ng-template>
					</mat-step>
					<mat-step>
						<ng-template matStepLabel>Firma</ng-template>
					</mat-step>
					<mat-step>
						<ng-template matStepLabel>Confirmación</ng-template>
					</mat-step>
				</mat-horizontal-stepper>
			</div>
		</div>
		<div class="container h-100 d-flex flex-column flex-grow-1">
			<x-neutral-selfie
				class="flex-grow-1"
				*ngIf="(signingProcessStep | async) === SigningProcessStep.NeutralSelfie"
				(changeStepperState)="changeStepperState($event)"
			></x-neutral-selfie>
			<x-signature
				class="flex-grow-1"
				*ngIf="(signingProcessStep | async) === SigningProcessStep.SignaturePad"
				(changeStepperState)="changeStepperState($event)"
			></x-signature>
			<x-product-reception class="flex-grow-1" *ngIf="(signingProcessStep | async) === SigningProcessStep.ProductRecived"></x-product-reception>
		</div>
	</ng-template>
</div>
<app-loader *ngIf="redirect | async"></app-loader>
