import {CurrencyPipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';

/**
 * @description
 *	Transforma un número en su representación monetaria. En particular,
 *	muestra su valor como pesos argentinos, usando 2 decimales. Este pipe
 *	debería usarse en favor del pipe 'currency', ya que evita especificar
 *	los parámetros cada vez, y además permite mantener una representación
 *	consistente a lo largo de toda la aplicación.
 */

@Pipe({name: 'money', pure: true})
export class MoneyPipe implements PipeTransform {
	public constructor(private currencyPipe: CurrencyPipe) {}

	/**
	 * @description
	 *	Transforma un número en su representación monetaria.
	 *
	 * @param value
	 *	El número, posiblemente sin especificar.
	 * @param orElse
	 *	Resultado a devolver si el número no fue especificado.
	 *
	 * @returns
	 *	La representación monetaria del número, o la cadena indicada en el
	 *	parámetro 'orElse', si el número es 'null', 'undefined' o alguna
	 *	variante de NaN (Not a Number).
	 */
	public transform(value: number, orElse: string = '[no disponible]'): string {
		if (value === null || value === undefined || Number.isNaN(value)) {
			return orElse;
		} else {
			return this.currencyPipe.transform(value, '', '$', '1.2-2').replace(/\./g, '_').replace(/,/g, '.').replace(/_/g, ',');
		}
	}
}
