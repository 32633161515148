<div mat-dialog-content style="text-align: center">
	<p>El e-mail no fue verificado.</p>
	<p>
		Si continúa sin validar el e-mail, Credilow no se hará responsable por el crédito otorgado
		en caso de que no pueda comunicarse con el cliente vía dicho medio.
	</p>
	<p>¿Seguro que desea continuar?</p>
</div>
<div mat-dialog-actions class="buttonSeparation">
	<button mat-raised-button color="warn" (click)="onCloseClick()">No</button>
	<button mat-raised-button class="continue" (click)="onContinueClick()">Sí</button>
</div>
