import {AbstractControl, ValidatorFn} from '@angular/forms';

export class PhoneValidator {
	public static mobile(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: boolean} => {
			if (!control.value) {
				return null;
			}
			const regex = new RegExp(/(^(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$)/);
			const valid = regex.test(control.value);
			return valid ? null : {mobilePhoneInvalid: true};
		};
	}
	public static home(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: boolean} => {
			if (!control.value) {
				return null;
			}
			const regex = new RegExp(/^(?:0{0,5}[1-9]\d{9}|0{0,5}[1-9]\d{10})$/);
			const valid = regex.test(control.value);
			return valid ? null : {mobilePhoneInvalid: true};
		};
	}
}
