import * as jose from 'jose';

/**
 * @description
 *	Clase para almacenar el token.
 */

export class AuthenticationToken {
	public decodedToken: jose.JWTPayload;
	private timestampInMilliseconds: number;

	public constructor(token: string) {
		this.decodedToken = jose.decodeJwt(token);
		this.timestampInMilliseconds = new Date().getTime();
	}

	/**
	 * @description
	 *	Devuelve la fecha de cuando va a expirar el token en milisegundos
	 */

	private expirationTime(): number {
		return this.decodedToken.exp * 1000;
	}

	/**
	 * @description
	 *	Devuelve los milisegundos que faltan para que se expire el token
	 */

	public timeToExpire(): number {
		return this.expirationTime() - this.timestampInMilliseconds;
	}
}
