export class SigningProcess {
	public authId: number;
	public cantidad_cuotas: number;
	public capital: number;
	public planId: number;
	public metodo_retiro: string;
	public monto_cuota: number;
	public validationId: string;
	public comercioDescripcion: string;
	public comercioNombre: string;
	public autorizada: boolean;
	public creditFlowId: number;
}
