/**
 * @description
 *  Clase de soporte para inputs.
 */

export class InputHide {
	private constructor() {
		throw new Error('Nunca debe instanciar o heredar de esta clase (es de soporte).');
	}

	/**
	 * @description
	 *  Reformatea un string haciendo que solo sean visibles los ultimos cuatro digitos, los anteriores seran reemplazados por asteriscos.
	 *
	 * @param inputValue
	 *  El string o number a ocultar.
	 *
	 * @returns
	 *  Un string con asteriscos donde la cantidad de asteriscos es el largo del string menos cuatro y
	 *  los ultimos o los primeros(Caso de ser email) cuatros digitos son igual al del string original.
	 */
	public static fakeValue(inputValue: string | number, email: boolean): string {
		if (typeof inputValue != 'string') {
			inputValue = inputValue ? inputValue.toString() : '';
		}
		const stringRemains = 6;
		let fakeInput: string = '';
		for (let count = 1; count < inputValue.length - stringRemains; count++) fakeInput += '*';
		return email ? inputValue.slice(0, 3).concat(fakeInput) : fakeInput.concat(inputValue.slice(-4));
	}
}
