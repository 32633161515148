<div class="h-100 d-flex flex-column">
	<div style="text-align: center">
		<h5>Reconocimiento Facial</h5>
		<h6>Tomate una foto para confirmar tu identidad</h6>
		<div>
			<div class="webcam">
				<webcam
					[trigger]="triggerObservable"
					(imageCapture)="handleImage($event)"
					*ngIf="showWebcam"
					[allowCameraSwitch]="allowCameraSwitch"
					[videoOptions]="videoOptions"
					[imageQuality]="1"
					(initError)="handleInitError($event)"
				></webcam>
			</div>
			<div *ngIf="!productionEnv">
				<input class="form-control" type="file" (change)="onChange($event)" id="input-selfie" />
			</div>
			<br />
			<button mat-raised-button color="primary" class="actionBtn" [disabled]="!(selfieUnsuccessfully | async)" (click)="triggerSnapshot()">
				Tomar Foto
			</button>
		</div>
	</div>
	<div class="credilow-footer credilow-navigate mt-auto mb-3">
		<button
			class="credilow-next-button credilow-shadow"
			[ngClass]="{sanii: !useCredilowStyle}"
			[disabled]="selfieUnsuccessfully | async"
			(click)="next()"
			id="next-selfie-btn"
			appEnterClick
		>
			Siguiente
		</button>
	</div>
</div>
<app-loader *ngIf="loading | async"></app-loader>
