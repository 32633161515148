/**
 * @description
 *	Clase para almacenar a los vendedores.
 */

import {SellerState} from '../_enums/seller-state.enum';

export class VendedorLista {
	private _sellerState: number;
	private active: string;
	public comercioId: number;
	public username: string;
	public nombreComercio: string;
	public vendedor: string;
	public userId: number;
	public cellphone: string;
	public email: string;

	public constructor(
		sellerState: SellerState,
		active: string,
		comercioId: number,
		username: string,
		nombreComercio: string,
		vendedor: string,
		userId: number,
		cellphone: string,
		email: string
	) {
		this._sellerState = sellerState;
		this.active = active;
		this.comercioId = comercioId;
		this.username = username;
		this.nombreComercio = nombreComercio;
		this.vendedor = vendedor;
		this.userId = userId;
		this.cellphone = cellphone;
		this.email = email;
	}

	public set sellerState(value: SellerState) {
		this._sellerState = value;
	}

	/**
	 * @description
	 *	Devuelve si el vendedor esta activo
	 */

	public isActive(): boolean {
		return this._sellerState === SellerState.Active;
	}

	public static from(vendedorLista: VendedorLista): VendedorLista {
		const sellerState = parseInt(vendedorLista.active);
		const vendedor = new VendedorLista(
			sellerState,
			vendedorLista.active,
			vendedorLista.comercioId,
			vendedorLista.username,
			vendedorLista.nombreComercio,
			vendedorLista.vendedor,
			vendedorLista.userId,
			vendedorLista.cellphone,
			vendedorLista.email
		);
		return vendedor;
	}
}
