import {Injectable} from '@angular/core';
import {Optional} from '../_models/optional.model';

@Injectable({providedIn: 'root'})
export class LocalStorageService {
	public setToken(token: string): void {
		localStorage.setItem('token', token);
	}

	public getToken(): Optional<string> {
		return Optional.of(localStorage.getItem('token'));
	}

	public setCurrentUser(data: string): void {
		localStorage.setItem('currentUser', data);
	}

	public getCurrentUser(): Optional<string> {
		return Optional.of(localStorage.getItem('currentUser'));
	}

	public setCommerces(data: string): void {
		localStorage.setItem('comercios', data);
	}

	public getCommerces(): string {
		return localStorage.getItem('comercios');
	}

	public clearLocalStorage(): void {
		localStorage.clear();
	}
}
