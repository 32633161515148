import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../environments/environment';
import {HttpStatusCode} from '../_enums/http-status-codes.enum';

@Injectable({
	providedIn: 'root',
})
export class UIService {
	constructor(private toastr: ToastrService) {}

	successMessage(title: string, message: string): void {
		this.toastr.success(message, title, {
			positionClass: 'toast-top-full-width',
			closeButton: true,
			progressBar: true,
			timeOut: environment.toastrTimeOut,
		});
	}

	errorMessage(title: string, error: string): void {
		this.toastr.error(error, title, {
			positionClass: 'toast-top-full-width',
			closeButton: true,
			progressBar: true,
			timeOut: environment.toastrTimeOut,
		});
	}

	clearNotifications(): void {
		this.toastr.clear();
	}

	responseLog(title: string, data?: any): void {
		console.log(title);
		if (environment.production) {
			return;
		}
		console.log(data);
	}

	errorInterceptor(error: any): boolean {
		if (error.status_code !== HttpStatusCode.Ok) {
			return true;
		}
		this.errorMessage('Error', error.message);
		return false;
	}
}
