<div *ngIf="!stepCheckout" class="fill position-relative">
	<!-- <div class="position-relative"> -->
	<app-image-preview
		*ngIf="webcamImage && showPreview"
		[imageSrc]="webcamImage.imageAsDataUrl"
		(retake)="webcamImage = null; showPreview = false"
		(accept)="handleImage(webcamImage)"
		style="position: absolute; top: -30px; left: -15px; right: -15px; bottom: 0; z-index: 1; background-color: rgba(0, 0, 0, 0.78)"
	></app-image-preview>
	<div class="fill-10 credilow-text">
		Tomá una foto del
		<b>{{ dniForm.controls.frente.valid ? 'dorso' : 'frente' }}</b>
		del DNI del cliente.
	</div>

	<form [formGroup]="dniForm" class="fill-90 d-flex flex-column">
		<div class="credilow-dni-tabs flex-grow-1">
			<div class="credilow-dni-tabs-header mb-1">
				<div class="credilow-dni-tab">{{ document.isSideFront() ? 'Frente' : 'Dorso' }}</div>
			</div>
			<div class="credilow-dni-tabs-body h-100">
				<div class="h-100 d-flex flex-column">
					<div *ngIf="dniForm.invalid" class="d-flex h-100 flex-column">
						<div class="d-flex flex-grow-1 flex-column">
							<div class="webcam-wrapper">
								<webcam
									[trigger]="triggerObservable"
									(imageCapture)="handlePreviewImage($event)"
									*ngIf="showWebcam"
									[videoOptions]="videoOptions"
									[imageQuality]="1"
									(initError)="handleInitError($event)"
									[switchCamera]="nextWebcamObservable"
								></webcam>
								<div class="webcam-overlay"></div>
							</div>
							<div *ngIf="!productionEnv">
								<input class="form-control" type="file" (change)="onChange($event)" id="input-frente" />
								<input class="form-control" type="file" (change)="onChangeD($event)" id="input-dorso" />
							</div>
						</div>
						<div class="mb-5 mt-2">
							<button mat-fab class="round-action-btn" (click)="triggerSnapshot()">
								<span
									class="material-icons"
									
								>
									circle
								</span>
							</button>
						</div>
					</div>
					<div *ngIf="dniForm.valid">
						<i class="fas fa-check-circle credilow-success"></i>
						<button class="credilow-dni-foto-subida">Documento subido correctamente!</button>
					</div>
				</div>
			</div>
		</div>
		<div [style.visibility]="dniForm.controls.frente.valid ? 'visible' : 'hidden'" class="mb-3" style="text-align: center">
			<button class="credilow-dni-eliminar" [ngClass]="{sanii: !useCredilowStyle}" (click)="clearDocument()">Eliminar Documento</button>
		</div>
		<div class="credilow-footer credilow-navigate space-between-buttons">
			<div class="credilow-50">
				<button class="credilow-previous-button credilow-shadow" [ngClass]="{sanii: !useCredilowStyle}" (click)="cancel()">cancelar</button>
			</div>
			<div class="credilow-50">
				<button
					id="next-foto-btn"
					class="credilow-next-button credilow-shadow"
					[ngClass]="{sanii: !useCredilowStyle}"
					[disabled]="dniForm.invalid"
					(click)="documentVerification()"
					appEnterClick
				>
					Siguiente
				</button>
			</div>
		</div>
	</form>
	<!-- </div> -->
</div>
<div *ngIf="!document.isRecognizedDocument()">
	<div class="container credilow-text" style="overflow-wrap: break-word">
		{{ this.errorMessage ? this.errorMessage : 'No se pudieron procesar las imágenes del DNI debido a una falla del proveedor del servicio.' }}
	</div>
	<div *ngIf="!productionEnv">
		<b>Http error response:</b>
		<br />
		Error Message: {{ this.errorMessage }}
		<br />
		Error Error: {{ this.errorError }}
		<br />
		Error Status Code: {{ this.errorStatusCode }}
		<br />
		Http Error Message : {{ this.httpErrorMessage }}
		<br />
		Http Error Status : {{ this.httpErrorStatus }}
		<br />
		Http Error Url : {{ this.httpErrorUrl }}
		<br />
	</div>
	<div class="credilow-footer credilow-navigate">
		<button class="credilow-previous-button credilow-shadow" [ngClass]="{sanii: !useCredilowStyle}" id="go-back-btn" (click)="goBack()">Volver</button>
	</div>
</div>
<app-loader *ngIf="loading | async"></app-loader>
