import {AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {RestApiService} from '../../_services/rest-api.service';
import {Cliente} from '../../_models/cliente.model';
import {Autorizacion} from '../../_models/autorizacion.model';
import {AuthorizationService} from '../../_services/authorization.service';
import {UIService} from '../../_services/ui.service';
import {ClienteService} from '../../_services/cliente.service';
import {Referencias} from '../../_models/referencias.model';
import {OnboardingStep} from '../../_enums/onboarding-step.enum';
import {OnboardingService} from '../../_services/onboarding-steps.service';
import {PhoneType} from '../../_enums/phone-type.enum';
import {CreditType} from '../../_enums/credit-type.enum';
import {ClienteType} from '../../_enums/cliente-type.enum';
import {HeaderStep} from '../../_enums/header-step.enum';
import {BehaviorSubject, Subscription} from 'rxjs';
import {finalize, take} from 'rxjs/operators';
import {PhoneValidator} from '../../_validator/mobile-phone.validator';
import {ReferencePhonesValidator} from '../../_validator/reference-phones.validator';
import {TitleService} from '../../_services/title.service';

import {StyleServiceService} from '../../_services/style-service.service';
import {textChangeRangeIsUnchanged} from 'typescript';
import {Step} from '../../_enums/step.enum';
import {LogType} from '../../_enums/log-type.enum';

@Component({
	selector: 'app-referencias',
	templateUrl: './referencias.component.html',
	styleUrls: ['./referencias.component.css'],
})
export class ReferenciasComponent implements OnInit {
	public useCredilowStyle: boolean;
	@Output() public changeStepperState: EventEmitter<HeaderStep> = new EventEmitter<HeaderStep>();

	@ViewChild('goToTop') goToTop: ElementRef;

	public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public phoneType1 = PhoneType.MobilePhone;
	public phoneType2 = PhoneType.MobilePhone;
	public phoneType3 = PhoneType.MobilePhone;
	public referenceForm: UntypedFormGroup;
	public referencias: Referencias = new Referencias();
	public cliente: Cliente = new Cliente();
	public esTitular1 = false;
	public esTitular2 = false;

	private autorizacion: Autorizacion = new Autorizacion();
	private clienteServiceSubscription: Subscription;

	constructor(
		public styleService: StyleServiceService,
		private readonly formBuilder: UntypedFormBuilder,
		private readonly restApiService: RestApiService,
		private readonly autorizacionService: AuthorizationService,
		private readonly uIService: UIService,
		private readonly onboardingService: OnboardingService,
		private readonly clienteService: ClienteService,
		private titleService: TitleService
	) {}

	public ngOnInit(): void {
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		});
		this.titleService.updateTitle('Referencias');
		this.autorizacionService.getAutorizacion().subscribe({
			next: (autorizacion: Autorizacion) => (this.autorizacion = autorizacion),
			error: (error: Error) => console.log(error),
		});
		this.clienteServiceSubscription = this.clienteService.getCliente().subscribe({
			next: (cliente: Cliente) => {
				this.cliente = cliente;
				this.restApiService.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.StartReferencias, logType:LogType.Flujo}).pipe(take(1)).subscribe();
			},
			error: (error: Error) => console.log(error),
		});
		const namePatternValidator = Validators.pattern(/^[a-zA-ZÀ-ÿ]{2,}(\s[a-zA-ZÀ-ÿ]{2,})+\s*$/);
		const patternValidator = Validators.pattern(/^[a-zA-ZÀ-ÿ]{2,}(\s[a-zA-ZÀ-Ý]{2,})*\s*$/);

		this.referenceForm = this.formBuilder.group(
			{
				nameReference1: [undefined, [Validators.required, namePatternValidator]],
				relationReference1: [undefined, [Validators.required, patternValidator]],
				telephoneReference1: [undefined, [Validators.required, PhoneValidator.mobile()]],
				nameReference2: [undefined, [Validators.required, namePatternValidator]],
				relationReference2: [undefined, [Validators.required, patternValidator]],
				telephoneReference2: [undefined, [Validators.required, PhoneValidator.mobile()]],
				nameReference3: [undefined, [Validators.required, namePatternValidator]],
				relationReference3: [undefined, [Validators.required, patternValidator]],
				telephoneReference3: [undefined, [Validators.required, PhoneValidator.mobile()]],
				tipoServicio1: [undefined],
				proveedorServicio1: [undefined, patternValidator],
				titular1: [undefined, namePatternValidator],
				relacion1: [undefined, patternValidator],
				tipoServicio2: [undefined],
				proveedorServicio2: [undefined, patternValidator],
				titular2: [undefined, namePatternValidator],
				relacion2: [undefined, patternValidator],
				otroServicio: [undefined, patternValidator],
			},
			{
				validators: [ReferencePhonesValidator.referencePhonesValidator()],
			}
		);
		if (this.cliente.renovador) {
			this.referenceForm.controls.nameReference2.clearValidators();
			this.referenceForm.controls.relationReference2.clearValidators();
			this.referenceForm.controls.telephoneReference2.clearValidators();
		}
		if (this.cliente.renovador || this.isClientWithIngresos()) {
			this.referenceForm.controls.nameReference3.clearValidators();
			this.referenceForm.controls.relationReference3.clearValidators();
			this.referenceForm.controls.telephoneReference3.clearValidators();
		}
	}

	public ngAfterViewInit() {
		this.goToTop.nativeElement.scrollIntoView();
	}

	public continue(): void {
		this.loading.next(true);
		this.generarReferencias();
		this.restApiService
			.actualizarReferencias(this.referencias)
			.pipe(finalize(() => this.loading.next(false)))
			.subscribe({
				next: (data: any) => {
					this.restApiService.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.Referencias, logType:LogType.Flujo}).subscribe();
					this.uIService.responseLog('actualizarReferencias - OK', data);
					this.changeStepperState.emit(HeaderStep.Authorization);
					this.onboardingService.moveTo(OnboardingStep.Authorization);
				},
				error: (error: any) => {
					this.restApiService
						.monitoringLog({creditFlowId:this.cliente.creditFlowId, step: Step.Referencias, logType: LogType.Consola, error})
						.subscribe();
					this.uIService.responseLog('actualizarReferencias - ERROR', error);
					this.uIService.errorMessage('Error', error.statusText);
				},
			});
	}

	public cancel(): void {
		this.restApiService.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.Cancel, logType:LogType.Flujo}).subscribe();
		if (this.clienteServiceSubscription) {
			this.clienteServiceSubscription.unsubscribe();
		}
		this.onboardingService.restart();
		this.clienteService.restart();
	}

	public changePhoneType(reference: number, mobilePhone: boolean): void {
		const phoneType = mobilePhone ? PhoneType.MobilePhone : PhoneType.HomePhone;
		const validator = phoneType === PhoneType.MobilePhone ? PhoneValidator.mobile() : PhoneValidator.home();

		switch (reference) {
			case 1:
				this.phoneType1 = phoneType;
				this.referenceForm.controls.telephoneReference1.clearValidators();
				this.referenceForm.controls.telephoneReference1.setValidators([Validators.required, validator]);
				this.referenceForm.controls.telephoneReference1.updateValueAndValidity();
				break;
			case 2:
				this.phoneType2 = phoneType;
				this.referenceForm.controls.telephoneReference2.clearValidators();
				this.referenceForm.controls.telephoneReference2.setValidators([Validators.required, validator]);
				this.referenceForm.controls.telephoneReference2.updateValueAndValidity();
				break;
			case 3:
				this.phoneType3 = phoneType;
				this.referenceForm.controls.telephoneReference3.clearValidators();
				this.referenceForm.controls.telephoneReference3.setValidators([Validators.required, validator]);
				this.referenceForm.controls.telephoneReference3.updateValueAndValidity();
				break;
			default:
				break;
		}
	}

	private removeLeadingZeros(telefonoFijo: number): number {
		if (telefonoFijo === null || telefonoFijo === undefined) {
			return null;
		}
		const sanitizedValue = telefonoFijo.toString().replace(/^0+/, ''); // Remove leading zeros
		return parseInt(sanitizedValue, 10); // Convert back to number
	}

	private generarReferencias(): void {
		this.referencias.authId = this.autorizacion.id.toString();
		this.referencias.celular = this.phoneType1 === PhoneType.MobilePhone;
		this.referencias.telReferencia =
			this.phoneType1 === PhoneType.MobilePhone
				? this.referenceForm.value.telephoneReference1
				: this.removeLeadingZeros(this.referenceForm.value.telephoneReference1);
		this.referencias.nombreReferencia = this.referenceForm.value.nameReference1;
		this.referencias.relacionReferencia = this.referenceForm.value.relationReference1;
		this.referencias.celular2 = this.phoneType2 === PhoneType.MobilePhone;
		this.referencias.telReferencia2 =
			this.phoneType2 === PhoneType.MobilePhone
				? this.referenceForm.value.telephoneReference2
				: this.removeLeadingZeros(this.referenceForm.value.telephoneReference2);
		this.referencias.nombreReferencia2 = this.referenceForm.value.nameReference2;
		this.referencias.relacionReferencia2 = this.referenceForm.value.relationReference2;

		if (!this.cliente.trabajoEnBlanco) {
			this.referencias.celular3 = this.phoneType3 === PhoneType.MobilePhone;
			this.referencias.telReferencia3 =
				this.phoneType3 === PhoneType.MobilePhone
					? this.referenceForm.value.telephoneReference3
					: this.removeLeadingZeros(this.referenceForm.value.telephoneReference3);
			this.referencias.nombreReferencia3 = this.referenceForm.value.nameReference3;
			this.referencias.relacionReferencia3 = this.referenceForm.value.relationReference3;

			this.referencias.propio = this.esTitular1;
			this.referencias.servicio = this.referenceForm.value.tipoServicio1;
			this.referencias.proveedor = this.referenceForm.value.proveedorServicio1;
			if (!this.esTitular1) {
				this.referencias.titular = this.referenceForm.value.titular1;
				this.referencias.relacionServicio = this.referenceForm.value.relacion1;
			}

			this.referencias.propio2 = this.esTitular2;
			this.referencias.servicio2 = this.referenceForm.value.tipoServicio2;
			this.referencias.proveedor2 = this.referenceForm.value.proveedorServicio2;
			if (this.referenceForm.value.tipoServicio2 === 'OTRO') {
				this.referencias.otroServicio = this.referenceForm.value.otroServicio;
			}
			if (!this.esTitular2) {
				this.referencias.titular2 = this.referenceForm.value.titular2;
				this.referencias.relacionServicio2 = this.referenceForm.value.relacion2;
			}
		}
	}

	public isInvalidForm(formControl: AbstractControl): boolean {
		return formControl.touched && formControl.invalid;
	}

	public isClientDNI(): boolean {
		return this.autorizacionService.isAuthorizationTypeDNI(this.autorizacion);
	}

	public isClientWithIngresos(): boolean {
		return this.autorizacionService.isAuthorizationTypeWithIngresos(this.autorizacion);
	}

	public verficarServicios(): boolean {
		if (this.isClientDNI() && this.cliente.tipo === ClienteType.New) {
			return this.serviciosValidos();
		} else {
			return true;
		}
	}

	private servicioValido(): boolean {
		if (this.esTitular1) {
			if (!this.referenceForm.controls.tipoServicio1.value || !this.referenceForm.controls.proveedorServicio1.value) {
				return false;
			}
		} else if (
			this.referenceForm.controls.tipoServicio1.value ||
			this.referenceForm.controls.proveedorServicio1.value ||
			this.referenceForm.controls.titular1.value ||
			this.referenceForm.controls.relacion1.value
		) {
			if (
				!this.referenceForm.controls.tipoServicio1.value ||
				!this.referenceForm.controls.proveedorServicio1.value ||
				!this.referenceForm.controls.titular1.value ||
				!this.referenceForm.controls.relacion1.value
			) {
				return false;
			}
		} else if (
			!this.referenceForm.controls.tipoServicio1.value &&
			!this.referenceForm.controls.proveedorServicio1.value &&
			!this.referenceForm.controls.titular1.value &&
			!this.referenceForm.controls.relacion1.value
		) {
			return false;
		}
		return true;
	}

	private servicio2Valido(): boolean {
		if (this.esTitular2) {
			if (!this.referenceForm.controls.tipoServicio2.value || !this.referenceForm.controls.proveedorServicio2.value) {
				return false;
			}
		} else if (
			this.referenceForm.controls.tipoServicio2.value ||
			this.referenceForm.controls.proveedorServicio2.value ||
			this.referenceForm.controls.titular2.value ||
			this.referenceForm.controls.relacion2.value
		) {
			if (
				!this.referenceForm.controls.tipoServicio2.value ||
				!this.referenceForm.controls.proveedorServicio2.value ||
				!this.referenceForm.controls.titular2.value ||
				!this.referenceForm.controls.relacion2.value
			) {
				return false;
			}
		} else if (
			!this.referenceForm.controls.tipoServicio2.value &&
			!this.referenceForm.controls.proveedorServicio2.value &&
			!this.referenceForm.controls.titular2.value &&
			!this.referenceForm.controls.relacion2.value
		) {
			return false;
		}
		return true;
	}

	public serviciosValidos(): boolean {
		if (this.creditType() === CreditType.PlanDNI) {
			return this.servicioValido();
		} else if (this.creditType() === CreditType.DNI2Servicios) {
			return this.servicioValido() && this.servicio2Valido();
		} else {
			return false;
		}
	}

	public showService1(): boolean {
		return this.cliente.tipo === ClienteType.New && (this.creditType() === CreditType.PlanDNI || this.creditType() === CreditType.DNI2Servicios);
	}

	public showService2(): boolean {
		return this.cliente.tipo == ClienteType.New && this.creditType() === CreditType.DNI2Servicios;
	}

	/**
	 * @description
	 *	Devuelve el tipo de crédito.
	 */
	public creditType(): CreditType {
		return this.autorizacion.tipoCredito;
	}

	/**
	 * @description
	 *	Permite utilizar el enum en la vista HTML.
	 */
	public get CreditType(): typeof CreditType {
		return CreditType;
	}

	/**
	 * @description
	 *	Permite utilizar el enum en la vista HTML.
	 */
	public get ClienteType(): typeof ClienteType {
		return ClienteType;
	}
}
