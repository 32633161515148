<form novalidate [formGroup]="nuevaSolicitud" class="fill d-flex flex-column">
	<div class="credilow-existing-client-tabs">
		<div class="credilow-existing-client-tabs-header credilow-shadow">
			<div
				class="credilow-existing-client-tab"
				[ngClass]="{'credilow-existing-client-tab-active': isExistentClient()}"
				(click)="switchClientStatus()"
			>
				CLIENTE CREDILOW
			</div>
			<div
				class="credilow-existing-client-tab"
				[ngClass]="{'credilow-existing-client-tab-active': isNewClient()}"
				(click)="switchClientStatus()"
				id="new-client-btn"
			>
				NUEVO CLIENTE
			</div>
		</div>
	</div>
	<div *ngIf="clientStatus; else loadNewClientView" class="container d-flex flex-column flex-grow-1 credilow-existing-client-tabs-body">
		<div class="selectCommerces">
			<x-comercio-select class="text-center" (selectedChanges)="selectComercio($event)"></x-comercio-select>
		</div>
		<div>
			<div class="mb-2">
				<input
					class="formInput"
					type="text"
					[readOnly]="mobilePhoneReadOnly | async"
					[ngClass]="{'is-invalid': isInValidInput(nuevaSolicitud.controls.mobilePhone)}"
					placeholder="Teléfono Celular"
					formControlName="mobilePhone"
					digitOnly
					#tooltip="matTooltip"
					matTooltip="Ingrese Su Teléfono Celular (Ejemplo: 1194524123)"
					matTooltipHideDelay="1000"
					[ngStyle]="{width: isMobilePhoneInputValidated.value ? '80%' : '90%'}"
					inputmode="numeric"
					autocomplete="off"
				/>
				<span *ngIf="isMobilePhoneInputValidated | async" class="material-icons">check_circle</span>
				<button *ngIf="canEditMobilePhone | async" class="small-btn p-2 modify-btn credilow-shadow" (click)="editMobilePhoneInput()">
					Modificar
				</button>
			</div>
		</div>
		<div class="credilow-footer credilow-navigate space-between-buttons">
			<div class="credilow-50">
				<button class="credilow-previous-button credilow-shadow" [ngClass]="{sanii: !useCredilowStyle}" (click)="cancel()">Cancelar</button>
			</div>
			<div class="credilow-50">
				<button
					class="credilow-next-button credilow-shadow"
					[ngClass]="{sanii: !useCredilowStyle}"
					[disabled]="invalidForm()"
					(click)="warningDialogOrNextStep()"
					id="next-btn"
					appEnterClick
				>
					Continuar
				</button>
			</div>
		</div>
	</div>
	<ng-template #loadNewClientView>
		<x-new-client (filledNewClientFields)="sendNewFormData($event)"></x-new-client>
	</ng-template>
</form>
<app-loader *ngIf="loading | async"></app-loader>
