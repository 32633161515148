import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
	selector: '[appEnterClick]',
})
export class EnterClickDirective {
	constructor(private el: ElementRef) {}

	@HostListener('document:keydown.enter')
	onEnter() {
		this.el.nativeElement.click();
	}
}
