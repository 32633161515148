<div class="fill position-relative d-flex flex-column">
	<div class="credilow-monto-solicitado">
		<h6>¿Qué producto está comprando?</h6>
		<input
			name="product-esp"
			type="text"
			[ngClass]="{
				'is-invalid': productSpecificationControl.invalid && productSpecificationControl.touched
			}"
			placeholder="Ingresa la descripcion del producto"
			[formControl]="productSpecificationControl"
		/>
	</div>
	<div class="credilow-footer credilow-navigate space-between-buttons">
		<div class="credilow-50">
			<button class="credilow-previous-button credilow-shadow" [ngClass]="{sanii: !useCredilowStyle}" (click)="cancel()">Cancelar</button>
		</div>
		<div class="credilow-50">
			<button
				class="credilow-next-button credilow-shadow"
				[ngClass]="{sanii: !useCredilowStyle}"
				(click)="next()"
				[disabled]="productSpecificationControl.invalid"
				id="btn-product-nxt"
				appEnterClick
			>
				Continuar
			</button>
		</div>
	</div>
</div>
<app-loader *ngIf="loading | async"></app-loader>
