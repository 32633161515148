import {Component, OnInit} from '@angular/core';

import {StyleServiceService} from 'src/app/_services/style-service.service';

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.css'],
})
export class LoaderComponent implements OnInit {
	public useCredilowStyle: boolean;
	constructor(public styleService: StyleServiceService) {}

	ngOnInit(): void {}
}
