export const Gender = {
	Male: 'M',
	Female: 'F',
	Generic: 'X',
	
	from: function(value: string) {
	  switch (value) {
		case 'M':
		  return this.Male;
		case 'F':
		  return this.Female;
		case 'X':
		  return this.Generic;
		default:
		  throw new Error(`Cannot convert from unknown Gender: ${value}.`);
	  }
	}
  };


