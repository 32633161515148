<div style="flex-wrap: wrap" class="d-flex flex-column h-100 w-100">
	<x-sources-header *ngIf="(onboardingStep | async) > OnboardingStep.ClientData"></x-sources-header>
	<div class="container fill flex-grow-1 pt-0" *ngIf="(onboardingStep | async) === OnboardingStep.PersonalData">
		<app-datos-personales #datosPersonales *ngIf="(onboardingStep | async) === OnboardingStep.PersonalData"></app-datos-personales>
	</div>
	<div class="container flex-grow-1" *ngIf="(onboardingStep | async) !== OnboardingStep.PersonalData">
		<app-foto-dni #fotoDni *ngIf="(onboardingStep | async) === OnboardingStep.DocumentPhoto"></app-foto-dni>
		<app-referencias #referencias *ngIf="(onboardingStep | async) === OnboardingStep.References"></app-referencias>
		<app-home-data #homeData *ngIf="(onboardingStep | async) === OnboardingStep.HomeData"></app-home-data>
		<app-client-data #clientData *ngIf="(onboardingStep | async) === OnboardingStep.ClientData"></app-client-data>
		<app-monto-solicitado #montoSolicitado *ngIf="(onboardingStep | async) === OnboardingStep.AmountRequested"></app-monto-solicitado>
		<app-product-specification
			#productSpecification
			*ngIf="(onboardingStep | async) === OnboardingStep.ProductSpecification"
		></app-product-specification>
		<app-plan-de-pago #planDePago *ngIf="(onboardingStep | async) === OnboardingStep.PaymentPlan"></app-plan-de-pago>
		<app-aprobacion #aprobacion *ngIf="(onboardingStep | async) === OnboardingStep.Authorization"></app-aprobacion>
		<app-sign #sign *ngIf="(onboardingStep | async) === OnboardingStep.Sign"></app-sign>
	</div>
</div>
