// import {MapsAPILoader} from '@agm/core';
import {AfterViewInit, Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {finalize, catchError, map, take} from 'rxjs/operators';
import {TitleService} from '../../_services/title.service';
import {HeaderStep} from '../../_enums/header-step.enum';
import {OnboardingStep} from '../../_enums/onboarding-step.enum';
import {Autorizacion} from '../../_models/autorizacion.model';
import {Cliente} from '../../_models/cliente.model';
import {Domicilio} from '../../_models/domicilio.model';
import {AuthorizationService} from '../../_services/authorization.service';
import {ClienteService} from '../../_services/cliente.service';
import {OnboardingService} from '../../_services/onboarding-steps.service';
import {RestApiService} from '../../_services/rest-api.service';
import {UIService} from '../../_services/ui.service';
import {HttpClient} from '@angular/common/http';
import {GoogleMap} from '@angular/google-maps';
import {StyleServiceService} from '../../_services/style-service.service';
import {LogType} from '../../_enums/log-type.enum';
import {Step} from '../../_enums/step.enum';

@Component({
	selector: 'app-home-data',
	templateUrl: './home-data.component.html',
	styleUrls: ['./home-data.component.css'],
})
export class HomeDataComponent implements OnInit {
	public useCredilowStyle: boolean;
	@Output() changeStepperState: EventEmitter<HeaderStep> = new EventEmitter<HeaderStep>();

	public homeDataFormGroup: UntypedFormGroup;
	public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public datosPersonales: Domicilio = new Domicilio();
	public cliente: Cliente;
	public latitude: number;
	public longitude: number;
	public formattedAddress: string;
	public showMarker: boolean;
	public addressComponents: object;
	public aditionalInfo: string;

	private autorizacion: Autorizacion = new Autorizacion();
	private geoCoder: google.maps.Geocoder;
	@ViewChild('search', {static: true})
	public searchElementRef: ElementRef;

	@ViewChild('myGoogleMap')
	public map!: GoogleMap;
	private readonly bsAsLatitude: number = -34.6037;
	private readonly bsAsLongitude: number = -58.3816;
	public display;
	public zoom: number = 10;
	center: google.maps.LatLngLiteral = {lat: this.bsAsLatitude, lng: this.bsAsLongitude};
	mapOptions: google.maps.MapOptions = {
		mapTypeControl: false,
		streetViewControl: false,
		fullscreenControl: false,
	};
	markerOptions: google.maps.MarkerOptions = {draggable: true};
	marker = {
		position: {lat: this.bsAsLatitude, lng: this.bsAsLongitude},
	};
	private clienteServiceSubscription: Subscription;

	constructor(
		public styleService: StyleServiceService,

		private readonly onboardingService: OnboardingService,
		private readonly restApiService: RestApiService,
		private readonly clienteService: ClienteService,
		private readonly autorizacionService: AuthorizationService,
		private readonly uIService: UIService,
		private readonly ngZone: NgZone,
		private titleService: TitleService
	) {}

	public ngOnInit(): void {
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		});
		this.titleService.updateTitle('Dirección');
		this.autorizacionService.getAutorizacion().subscribe({
			next: (autorizacion: Autorizacion) => (this.autorizacion = autorizacion),
			error: (error: Error) => console.log(error),
		});
		this.clienteServiceSubscription = this.clienteService.getCliente().subscribe({
			next: (cliente: Cliente) => {
				this.cliente = cliente;
				this.restApiService.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.StartHomeData, logType:LogType.Flujo}).pipe(take(1)).subscribe();
			},
			error: (error: Error) => console.log(error),
		});
		this.homeDataFormGroup = new UntypedFormGroup({
			homeAddress: new UntypedFormControl(undefined, Validators.required),
			betweenStreets: new UntypedFormControl(undefined, Validators.required),
			postalAddress: new UntypedFormControl(undefined, Validators.required),
			locality: new UntypedFormControl(undefined, Validators.required),
			additionalInfo: new UntypedFormControl(undefined),
		});
	}

	public mapDragend(any) {
		// console.log('ola', any.position, this.marker.position);
	}

	public ngAfterViewInit(): void {
		this.geoCoder = new google.maps.Geocoder();
		let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
		autocomplete.setComponentRestrictions({
			//se limita la busqueda de direccion a solo Argentina
			country: ['ar'],
		});
		autocomplete.addListener('place_changed', () => {
			this.ngZone.run(() => {
				let place: google.maps.places.PlaceResult = autocomplete.getPlace();
				// console.log('cambio', place);
				this.showMarker = true;
				//verifica resultado para que no falle la asignacion
				if (
					place.geometry === undefined ||
					place.geometry === null ||
					place.formatted_address === null ||
					place.address_components === null
				) {
					return;
				}
				this.latitude = place.geometry.location?.lat();
				this.longitude = place.geometry.location?.lng();
				this.center = {
					lat: this.latitude,
					lng: this.longitude,
				};
				this.marker.position = {
					lat: this.latitude,
					lng: this.longitude,
				};
				this.zoom = 15;

				this.formattedAddress = place.formatted_address;
				this.addressComponents = this.getAddressObject(place.address_components);
				this.setPersonalData();
			});
		});
	}

	public nextStep(): void {
		this.loading.next(true);
		this.datosPersonales.authId = this.autorizacion.id;
		this.datosPersonales.domicilio = this.homeDataFormGroup.controls.homeAddress.value;
		this.datosPersonales.entreCalles = this.homeDataFormGroup.controls.betweenStreets.value;
		this.datosPersonales.codigoPostal = this.homeDataFormGroup.controls.postalAddress.value;
		this.datosPersonales.localidad = this.homeDataFormGroup.controls.locality.value;
		this.datosPersonales.informacionAdicional = this.homeDataFormGroup.controls.additionalInfo.value;
		this.restApiService
			.actualizarDomicilio(this.datosPersonales)
			.pipe(finalize(() => this.loading.next(false)))
			.subscribe({
				next: (data: any) => {
					this.restApiService.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.HomeData, logType:LogType.Flujo}).subscribe();

					this.uIService.responseLog('actualizarDomicilio - OK', data);
					this.onboardingService.moveTo(OnboardingStep.References);
				},
				error: (error: any) => {
					this.restApiService.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.HomeData, logType: LogType.Consola, error}).subscribe();
					this.uIService.responseLog('actualizarDomicilio - ERROR', error);
					this.uIService.errorMessage('Error', error.statusText);
				},
			});
	}
	ngOnDestroy(): void {
		if (this.clienteServiceSubscription) {
			this.clienteServiceSubscription.unsubscribe();
		}
	}
	public cancel(): void {
		this.restApiService.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.Cancel, logType:LogType.Flujo}).subscribe();
		if (this.clienteServiceSubscription) {
			this.clienteServiceSubscription.unsubscribe();
		}
		this.onboardingService.restart();
		this.clienteService.restart();
	}

	public markerDragEnd($event: google.maps.MapMouseEvent) {
		this.latitude = $event.latLng.toJSON().lat;
		this.longitude = $event.latLng.toJSON().lng;
		this.getAddress(this.latitude, this.longitude);
	}

	private getAddress(latitude, longitude) {
		this.geoCoder.geocode({location: {lat: latitude, lng: longitude}}, (results, status) => {
			if (status === 'OK') {
				if (results[0]) {
					this.zoom = 15;
					this.center = {
						lat: this.latitude,
						lng: this.longitude,
					};
					this.formattedAddress = results[0].formatted_address;
					this.addressComponents = this.getAddressObject(results[0].address_components);
					this.setPersonalData();
				} else {
					console.log('Error no se encontraron resulados');
				}
			} else {
				console.log('Geocoder failed due to: ' + status);
			}
		});
	}

	private getAddressObject(address_components) {
		var address = {
			street_number: '',
			postal_code: '',
			street: '',
			region: '',
			city: '',
			country: '',
		};
		address_components.forEach((component) => {
			var fieldName = this.getAddressFieldNameFromGeocoderType(component.types[0]);
			if (fieldName !== undefined) {
				address[fieldName] =
					(address[fieldName] ? address[fieldName] + ', ' : '') + (fieldName === 'country' ? component.short_name : component.long_name);
			}
		});
		return address;
	}

	private getAddressFieldNameFromGeocoderType(component_type) {
		var ComponentTypeToField = {
			street_number: 'street_number',
			postal_code: 'postal_code',
			street_address: 'street',
			route: 'street',
			administrative_area_level_1: 'region',
			administrative_area_level_2: 'region',
			administrative_area_level_3: 'region',
			administrative_area_level_4: 'region',
			administrative_area_level_5: 'region',
			locality: 'city',
			sublocality: 'city',
			sublocality_level_1: 'city',
			sublocality_level_2: 'city',
			sublocality_level_3: 'city',
			sublocality_level_4: 'city',
			postal_code_suffix: 'postal_code',
			country: 'country',
		};
		return ComponentTypeToField[component_type];
	}

	private setPersonalData() {
		this.homeDataFormGroup.controls.homeAddress.setValue(
			this.addressComponents['street'] ? this.addressComponents['street'] + ' ' + this.addressComponents['street_number'] : null
		);
		const postalCode = this.addressComponents['postal_code'].match(/\d+/);
		this.homeDataFormGroup.controls.postalAddress.setValue(postalCode?.[0] ? postalCode[0] : undefined);
		if (this.addressComponents['city'] === this.addressComponents['region']) {
			this.homeDataFormGroup.controls.locality.setValue(this.addressComponents['city']);
		} else {
			this.homeDataFormGroup.controls.locality.setValue(
				`${this.addressComponents['city'] ? this.addressComponents['city'] + ', ' : ''}${this.addressComponents['region']}`
			);
		}
	}

	public goBack(): void {
		this.onboardingService.previousStep();
	}
}
