<div class="credilow-fuentes-verificadas-banner credilow-shadow">
	<p class="credilow-mb-2">
		<strong>
			FUENTES VERIFICADAS:
			<span *ngIf="!autorizacion.reporteRecienteAprobado; else sourcesRecentlyChecked">
				{{ fuentes.checked === undefined ? 'Cargando...' : fuentes.checked + ' DE ' + fuentes.count }}
			</span>
			<ng-template #sourcesRecentlyChecked>
				<span *ngIf="autorizacion.reporteRecienteAprobado">Reporte reciente aprobado</span>
			</ng-template>
		</strong>
	</p>
	<p>Nombre: {{ cliente.nombre }} / DNI: {{ cliente.dni }}</p>
	<p>
		Monto Máximo:
		{{ autorizacion.montoMaximo === undefined ? 'Cargando...' : (autorizacion.montoMaximo | money) }}
	</p>
</div>
