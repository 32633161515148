<div class="h-100 d-flex flex-column justify-content-center w-100">
	<mat-card class="mb-5 d-flex flex-column align-items-center" style="margin-block-start: auto">
		<mat-card-title><i class="material-icons error-page-icon">search_off</i></mat-card-title>
		<mat-card-title><h5>Disculpanos</h5></mat-card-title>
		<mat-card-subtitle class="mb-3 mt-1">
			<h6 class="text-center ml-2 mr-2">{{ errorTitle ? errorTitle : 'Ha ocurrido un error' }}</h6>
		</mat-card-subtitle>
		<mat-card-subtitle class="mb-3">
			<p class="text-center" style="font-size: 12px">
				{{ errorMessage }}
				<br />
				<ng-container *ngIf="errorCode">({{ errorCode }})</ng-container>
			</p>
		</mat-card-subtitle>
		<mat-card-actions class="w-50 mt-4"></mat-card-actions>
	</mat-card>
	<div class="credilow-navigate space-between-buttons mb-3">
		<div class="credilow-50">
			<button class="credilow-next-button credilow-shadow" [ngClass]="{sanii: !useCredilowStyle}" id="go-back-btn" (click)="goBack()">Volver</button>
		</div>
	</div>
</div>
<app-loader *ngIf="loading | async"></app-loader>
