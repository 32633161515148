import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * @description
 *	Mónada opcional, para evitar el uso de 'null' y 'undefined'.
 */

export class Optional<T> {
	private _value: T | null | undefined;

	private constructor(value: T | null | undefined) {
		this._value = value;
	}

	public get value(): T | null | undefined {
		return this._value;
	}

	public static empty<T>(): Optional<T> {
		return new Optional<T>(undefined);
	}

	public static of<T>(value: T | null | undefined): Optional<T> {
		return new Optional<T>(value);
	}

	/**
	 * @description
	 *	Aplica una operación de mapeo sobre el valor opcional si este existe,
	 *	pero de forma asincrónica, es decir, retornando un observable de la
	 *	operación cuyo valor final es un opcional del resultado.
	 *
	 * @param mapper
	 *	La función de transformación asincrónica.
	 *
	 * @returns
	 *	Un observable del opcional del resultado de la transformación, o un
	 *	observable del opcional vacío si no existía un valor que transformar.
	 */
	public asyncMap<U>(mapper: (value: T | null | undefined) => Observable<U>): Observable<Optional<U>> {
		if (this.isPresent()) {
			return mapper(this.value).pipe(map((value) => Optional.of(value)));
		} else {
			return of(Optional.empty<U>()); // Provide explicit typing information here
		}
	}

	public filter(predicate: (value: T | null | undefined) => boolean): Optional<T> {
		if (this.isPresent() && predicate(this.value)) {
			return this;
		} else {
			return Optional.empty();
		}
	}

	public flatMap<U>(mapper: (value: T | null | undefined) => Optional<U>): Optional<U> {
		if (this.isPresent()) {
			return mapper(this.value);
		} else {
			return Optional.empty();
		}
	}

	public getOrElse(value: T): T | null | undefined {
		if (this.isPresent()) {
			return this.value;
		} else {
			return value;
		}
	}

	public ifPresent(consumer: (value: T | null | undefined) => void): Optional<T> {
		if (this.isPresent()) {
			consumer(this.value);
		}
		return this;
	}

	public isPresent(): boolean {
		return this.value !== null && this.value !== undefined;
	}

	public map<U>(mapper: (value: T | null | undefined) => U): Optional<U> {
		if (this.isPresent()) {
			return Optional.of(mapper(this.value));
		} else {
			return Optional.empty();
		}
	}

	public orElse(action: () => void): Optional<T> {
		if (!this.isPresent()) {
			action();
		}
		return this;
	}
}
