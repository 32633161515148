import {HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {BehaviorSubject, Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {Routes} from 'src/app/_enums/routes.enum';
import {TitleService} from '../../_services/title.service';
import {UIService} from 'src/app/_services/ui.service';
import {OnboardingStep} from '../../_enums/onboarding-step.enum';
import {SignType} from '../../_enums/sign-type.enum';
import {Autorizacion} from '../../_models/autorizacion.model';
import {Cliente} from '../../_models/cliente.model';
import {AuthorizationService} from '../../_services/authorization.service';
import {ClienteService} from '../../_services/cliente.service';
import {OnboardingService} from '../../_services/onboarding-steps.service';
import {SigningProcessService} from '../../_services/signing-process.service';

import {StyleServiceService} from 'src/app/_services/style-service.service';
import {DialogService} from 'src/app/_services/dialog.service';
import {DialogComponent} from '../dialog/dialog.component';
import {RestApiService} from '../../_services/rest-api.service';
import {Step} from '../../_enums/step.enum';
import {LogType} from '../../_enums/log-type.enum';

@Component({
	selector: 'app-sign',
	templateUrl: './sign.component.html',
	styleUrls: ['./sign.component.css'],
})
export class SignComponent implements OnInit {
	public useCredilowStyle: boolean;
	public selectedSignType: SignType = SignType.Web;
	public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private authorization: Autorizacion;
	private cliente: Cliente;
	private clienteServiceSubscription: Subscription;

	constructor(
		public styleService: StyleServiceService,

		private readonly onboardingService: OnboardingService,
		private readonly signingProcess: SigningProcessService,
		private readonly authorizationService: AuthorizationService,
		private readonly clienteService: ClienteService,
		private readonly dialog: MatDialog,
		private readonly uIService: UIService,
		private titleService: TitleService,
		private router: Router,
		private dialogService: DialogService,
		private restApiService: RestApiService
	) {}

	public ngOnInit(): void {
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		});
		this.titleService.updateTitle('Verificar Cliente');
		this.authorizationService.getAutorizacion().subscribe({
			next: (autorizacion: Autorizacion) => (this.authorization = autorizacion),
			error: (error: Error) => console.log(error),
		});
		this.clienteServiceSubscription = this.clienteService.getCliente().subscribe({
			next: (cliente: Cliente) => {
				this.cliente = cliente;
				this.restApiService
					.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.StartVerifyClient, logType: LogType.Flujo})
					.subscribe(() => {
						this.unsubscribe();
					});
			},
			error: (error: Error) => console.log(error),
		});
	}

	private unsubscribe(): void {
		if (this.clienteServiceSubscription && !this.clienteServiceSubscription.closed) {
			this.clienteServiceSubscription.unsubscribe();
		}
	}

	/**
	 * @description
	 *	Permite utilizar el enum en la vista HTML.
	 */
	public get SignType(): typeof SignType {
		return SignType;
	}

	public sign(): void {
		this.loading.next(true);
		if (this.selectedSignType === SignType.Web) {
			this.signingProcess
				.postSignMethod(this.authorization.id, this.cliente.validationId)
				.pipe(finalize(() => this.loading.next(false)))
				.subscribe({
					next: (data: any) => {
						this.restApiService
							.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.VerifyClient, logType: LogType.Flujo})
							.subscribe();
						this.uIService.responseLog('postSignMethod - OK', data);
						this.informativeDialog();
					},
					error: (error: HttpErrorResponse) => {
						this.restApiService
							.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.VerifyClient, logType: LogType.Consola, error})
							.subscribe();
						this.uIService.responseLog('ERROR', error);
						this.uIService.errorMessage('Error!', error.error.message);
					},
				});
		} else {
			this.signingProcess
				.signCallCenter(this.authorization.id)
				.pipe(finalize(() => this.loading.next(false)))
				.subscribe({
					next: (data: any) => {
						this.restApiService
							.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.VerifyClient, logType: LogType.Flujo})
							.subscribe();
						this.uIService.responseLog('signCallCenter - OK', data);
						this.informativeDialog(data.presignedURL);
					},
					error: (error: HttpErrorResponse) => {
						this.restApiService
							.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.VerifyClient, logType: LogType.Consola, error})
							.subscribe();
						this.uIService.responseLog('signCallCenter - ERROR', error);
						this.uIService.errorMessage('Error! Por favor reintentar', error.error.message);
					},
				});
		}
	}

	public cancel(): void {
		this.restApiService.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.Cancel, logType: LogType.Flujo}).subscribe();
		if (this.clienteServiceSubscription) {
			this.clienteServiceSubscription.unsubscribe();
		}
		this.onboardingService.restart();
		this.clienteService.restart();
	}

	public changeSignType(signType: SignType): void {
		this.selectedSignType = signType;
	}

	public informativeDialog(presignedURL?:string) {
		const dialogRef = this.dialogService.openDialog(DialogComponent, {
			data:
				this.selectedSignType === SignType.CallCenter
					? 'Por favor, imprimí el mutuo que se envió por e-mail. Llamá al call-center al 0810-222-5566, o por WhatsApp al 11-5125-3347 para más información.'
					: 'Se envió un e-mail; seguí el proceso con el dispositivo del cliente.',
			buttonLabel: 'Cerrar',
			secondButtonLabel: this.selectedSignType === SignType.CallCenter ? 'Imprimir Mutuo' : undefined,
			secondButtonAction:
				this.selectedSignType === SignType.CallCenter
					? () => {
							window.open(presignedURL, '_blank');
					  }
					: undefined,
		});
		dialogRef.afterClosed().subscribe((result) => {
			this.authorizationService.resetAutorizacion();
			this.router.navigate([Routes.Home]);
		});
	}
}
