import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {CreditType} from '../_enums/credit-type.enum';
import {Autorizacion} from '../_models/autorizacion.model';

@Injectable()
export class AuthorizationService {
	private acceptedUnderCommerceRiskDate: Date = undefined;
	private underCommerceRisk: boolean = false;


	private autorizacion: BehaviorSubject<Autorizacion> = new BehaviorSubject<Autorizacion>(
		new Autorizacion()
	);

	public getAutorizacion(): Observable<Autorizacion> {
		return this.autorizacion.asObservable();
	}

	public setAutorizacion(autorizacion: Autorizacion): void {
		autorizacion.underCommerceRisk = this.underCommerceRisk;
		autorizacion.acceptedUnderCommerceRiskDate = this.acceptedUnderCommerceRiskDate;
		this.autorizacion.next(autorizacion);
	}

	public resetAutorizacion(): void {
		this.acceptedUnderCommerceRiskDate = undefined;
		this.underCommerceRisk = false;
		this.autorizacion.next(new Autorizacion());
	}

	public isAuthorizationTypeDNI(autorizacion: Autorizacion): boolean {
		return (
			autorizacion.tipoCredito === CreditType.PlanDNI ||
			autorizacion.tipoCredito === CreditType.DNI2Servicios ||
			autorizacion.tipoCredito === CreditType.DNISS
		);
	}

	public isAuthorizationTypeWithIngresos(autorizacion: Autorizacion): boolean {
		return (
			autorizacion.tipoCredito === CreditType.ReciboDeSueldo ||
			autorizacion.tipoCredito === CreditType.Jubilados ||
			autorizacion.tipoCredito === CreditType.Domesticas
		);
	}

	public setAcceptedUnderCommerceRisk(): void {
		this.acceptedUnderCommerceRiskDate = new Date();
	}

	public getAcceptedUnderCommerceRisk(): Date {
		return this.acceptedUnderCommerceRiskDate;
	}

	public setUnderCommerceRisk(value: boolean): void {
		this.underCommerceRisk = value;
	}

	public getUnderCommerceRisk(): boolean {
		return this.underCommerceRisk;
	}
}
