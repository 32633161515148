import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class TitleService {
	private titleSubject = new BehaviorSubject<string>('Credilow');
	title = this.titleSubject.asObservable();

	constructor() {}

	updateTitle(title: string) {
		this.titleSubject.next(title);
	}
}
