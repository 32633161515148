<div
	class="credilow-header credilow-shadow d-flex"
	style="padding-bottom: 15px; position: inherit"
	[ngClass]="{'credilow-header-solicitud': (showRequest | async), 'black-header': showBlackLogo, sanii: !useCredilowStyle}"
>
	<div class="col-8 offset-2 credilow-titulo" *ngIf="!showBlackLogo">
		<span>{{ title }}</span>
	</div>
	<div class="col-10 align-self-center" *ngIf="showBlackLogo">
		<img src="./assets/img/Logo-CL@2x.png" class="credilow-logo" *ngIf="useCredilowStyle" />
		<img src="./assets/img/sanii/logo.png" class="sanii-logo" *ngIf="!useCredilowStyle" />
	</div>
	<div class="col-2 align-self-center">
		<div class="credilow-menu-bars">
			<div class="credilow-menu-bars-group" [ngClass]="{change: (showMenu | async)}" (click)="changeMenu()">
				<div class="bar1" [ngClass]="{sanii: !useCredilowStyle}"></div>
				<div class="bar2" [ngClass]="{sanii: !useCredilowStyle}"></div>
				<div class="bar3" [ngClass]="{sanii: !useCredilowStyle}"></div>
			</div>
		</div>
	</div>
	<div
		class="credilow-menu"
		*ngIf="showMenu | async"
		[ngClass]="{show: showMenu.value, sanii: !useCredilowStyle}"
		[ngStyle]="showBlackLogo && {'margin-top': '60px'}"
	>
		<span *ngIf="currentUser.isPresent()" (click)="backToHome()">
			{{ currentUser.value.name }}
		</span>
		<hr />
		<p (click)="changePage(Routes.CreateCredit)">Crear Crédito</p>
		<p (click)="changePage(Routes.CreditSimulator)">Simular Crédito</p>
		<p (click)="changePage(Routes.CreditsByVendedor)">Listado de Créditos</p>
		<p (click)="changePage(Routes.ShowQR)">Mostrar QR</p>
		<div>
			<p *ngIf="currentUser.value.isAdmin()" (click)="changePage(Routes.AdminVendedores)">Administrar Vendedores</p>
		</div>
		<hr />
		<p (click)="logOut()">Cerrar Sesión</p>
	</div>
</div>
