import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {BehaviorSubject, Subscription, take} from 'rxjs';
import {TitleService} from '../../_services/title.service';
import {OnboardingStep} from '../../_enums/onboarding-step.enum';
import {Autorizacion} from '../../_models/autorizacion.model';
import {Cliente} from '../../_models/cliente.model';
import {AuthorizationService} from '../../_services/authorization.service';
import {ClienteService} from '../../_services/cliente.service';
import {OnboardingService} from '../../_services/onboarding-steps.service';

import {StyleServiceService} from '../../_services/style-service.service';
import {RestApiService} from '../../_services/rest-api.service';
import {Step} from '../../_enums/step.enum';
import {LogType} from '../../_enums/log-type.enum';

@Component({
	selector: 'app-product-specification',
	templateUrl: './product-specification.component.html',
	styleUrls: ['./product-specification.component.css'],
})
export class ProductSpecificationComponent implements OnInit {
	public useCredilowStyle: boolean;
	private cliente: Cliente;
	private autorizacion: Autorizacion;

	public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public productSpecificationControl: UntypedFormControl;
	private clienteServiceSubscription: Subscription;

	constructor(
		public styleService: StyleServiceService,

		private readonly onboardingService: OnboardingService,
		private readonly authorizationService: AuthorizationService,
		private readonly clienteService: ClienteService,
		private titleService: TitleService,
		private restApiService: RestApiService
	) {}

	public ngOnInit(): void {
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		});
		this.titleService.updateTitle('Producto');
		this.clienteServiceSubscription = this.clienteService.getCliente().subscribe({
			next: (cliente: Cliente) => {
				this.cliente = cliente;
				this.restApiService
					.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.StartEspecificacionProducto, logType:LogType.Flujo})
					.pipe(take(1))
					.subscribe();
			},
			error: (error: Error) => console.log(error),
		});
		this.authorizationService.getAutorizacion().subscribe({
			next: (autorizacion: Autorizacion) => (this.autorizacion = autorizacion),
			error: (error: Error) => console.log(error),
		});
		this.productSpecificationControl = new UntypedFormControl(undefined, Validators.required);
	}

	public cancel(): void {
		this.restApiService.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.Cancel, logType:LogType.Flujo}).subscribe();
		if (this.clienteServiceSubscription) {
			this.clienteServiceSubscription.unsubscribe();
		}
		this.onboardingService.restart();
		this.clienteService.restart();
	}

	public next(): void {
		this.restApiService.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.EspecificacionProducto, logType:LogType.Flujo}).subscribe();
		this.autorizacion.productoAdquirido = this.productSpecificationControl.value;
		this.authorizationService.setAutorizacion(this.autorizacion);
		if (!this.cliente.nuevo) {
			this.onboardingService.moveTo(OnboardingStep.Authorization);
		} else if (this.authorizationService.isAuthorizationTypeDNI(this.autorizacion)) {
			this.onboardingService.moveTo(OnboardingStep.HomeData);
		} else {
			this.onboardingService.moveTo(OnboardingStep.References);
		}
	}

	public ngOnDestroy(): void {
		// Unsubscribe from the clienteService subscription
		if (this.clienteServiceSubscription) {
			this.clienteServiceSubscription.unsubscribe();
		}
	}
}
