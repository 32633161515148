<body [ngClass]="{credilow: useCredilowStyle, sanii: !useCredilowStyle}">
	<form novalidate [formGroup]="user" (submit)="login()" style="height: 90%">
		<div class="credilow-login-background" [ngClass]="{sanii: !useCredilowStyle}">
			<div class="credilow-header" [ngClass]="{sanii: !useCredilowStyle}">
				<img src="./assets/img/Logo-CL@2x.png" class="credilow-logo" *ngIf="useCredilowStyle" />
				<img src="./assets/img/sanii/logo.png" class="sanii-logo" *ngIf="!useCredilowStyle" />
				<div class="credilow-titulo">Autorizador Automático</div>
			</div>
			<div class="credilow-login col-lg-6 mx-auto" [ngClass]="{sanii: !useCredilowStyle}">
				<div class="credilow-iniciar-sesion" [ngClass]="{sanii: !useCredilowStyle}">Iniciar Sesión</div>
				<input type="text" id="username" name="username" placeholder="Usuario" formControlName="username" />
				<input type="password" id="password" name="password" placeholder="Contraseña" formControlName="password" />
				<div class="credilow-login-error">
					&nbsp;
					<span *ngIf="loginError && !accountBlocked">Usuario o contraseña incorrectos</span>
				</div>
				<div class="credilow-olvide-contrasenia">
					<a *ngIf="loginErrorMessage">{{ loginErrorMessage }}</a>
				</div>
				<div class="credilow-olvide-contrasenia">
					<a (click)="resetPassword()">Olvidé mi contraseña</a>
				</div>
			</div>
			<div class="credilow-navigate mr-3 ml-3 col-lg-6 mx-auto">
				<input type="submit" value="Ingresar" [disabled]="!user.valid" />
			</div>
		</div>
	</form>
	<div *ngIf="!productionEnv">
		<mat-slide-toggle class="container" [checked]="useCredilowStyle" (change)="toggleStyle()">Cambiar estilo</mat-slide-toggle>
	</div>

	<app-loader [hidden]="!loading"></app-loader>
</body>
