<div class="container h-100 d-flex flex-column">
	<div class="text-center flex-grow-1">
		<x-comercio-select (selectedChanges)="selectComercio($event)"></x-comercio-select>
		<h6 class="mt-2">Presente el siguiente código al cliente:</h6>
		<div class="credilow-planes" style="margin: 10px 0px 0px 0px">
			<div class="credilow-plan-cuota p-0" style="text-align: center" *ngIf="qrCode | async">
				<qrcode #qrcodeElement [qrdata]="qrCode.value" [width]="256" [errorCorrectionLevel]="'M'" [elementType]="'canvas'"></qrcode>

			</div>
		</div>
	</div>
	<div class="" style="display: flex;	flex-direction: row;flex-wrap: nowrap">
		<button class="credilow-next-button credilow-shadow" [ngClass]="{sanii: !useCredilowStyle}" (click)="saveAsImage()">Descargar QR</button>
		<button class="credilow-next-button credilow-shadow" [ngClass]="{sanii: !useCredilowStyle}" (click)="saveFlyer()">Descargar Flyer</button>
	</div>
	<div class="credilow-footer credilow-navigate">
		<button class="credilow-previous-button credilow-shadow" [ngClass]="{sanii: !useCredilowStyle}" (click)="goBack()">Volver</button>
	</div>
</div>
<app-loader *ngIf="loading | async"></app-loader>
