export class Plan {
	// @Turbio viene del back asi
	public CantidadCuotas: number;
	public Codigo: string;
	public Descripcion: string;
	public Id: number;
	public IdPlan: number;
	public Interes: string;
	public MontoCuota: number;
	public PrimerVencimiento: string;
}
