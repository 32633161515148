import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthenticationService} from '../../_services/authentication.service';
import {RestApiService} from '../../_services/rest-api.service';
import {UIService} from '../../_services/ui.service';
import {Optional} from 'src/app/_models/optional.model';
import {User} from 'src/app/_models/user.model';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {OpcionDropDown} from '../../_interfaces/opcion_drop_down';
import {UntypedFormControl} from '@angular/forms';
import {ComercioConVendedores} from '../../_models/comercio-con-vendedores.model';
import {VendedorLista} from '../../_models/vendedor-lista.model';
import {HttpErrorResponse} from '@angular/common/http';
import {SellerState} from '../../_enums/seller-state.enum';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {VendedoresService} from 'src/app/_services/vendedores.service';
import {Location} from '@angular/common';
import {TitleService} from '../../_services/title.service';
import {Routes} from 'src/app/_enums/routes.enum';

import {StyleServiceService} from '../../_services/style-service.service';

@Component({
	selector: 'app-vendedores',
	templateUrl: './vendedores.component.html',
	styleUrls: ['./vendedores.component.css'],
})
export class VendedoresComponent implements OnInit {
	public useCredilowStyle: boolean;
	@Output() public selectedChanges: EventEmitter<ComercioConVendedores> = new EventEmitter<ComercioConVendedores>();

	public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private currentUser: Optional<User>;
	public comercioCtrl: UntypedFormControl = new UntypedFormControl();
	public firstCommerce: ComercioConVendedores;
	public commercesWithEmployees: ComercioConVendedores[];

	public commerceSelected: ComercioConVendedores;
	public disabled: boolean = false;

	dropDownSettings: IDropdownSettings = {};
	dropDownListCommerce: OpcionDropDown[] = [];
	displayedColumns: string[] = ['nombre_vendedor', 'accion'];

	private subscription: Subscription;

	constructor(
		public styleService: StyleServiceService,
		private restApiService: RestApiService,
		private uIService: UIService,
		private authenticationService: AuthenticationService,
		private router: Router,
		private route: ActivatedRoute,
		private vendedoresService: VendedoresService,
		private location: Location,
		private titleService: TitleService
	) {
		this.authenticationService.currentUser.subscribe((x) => (this.currentUser = x));
		this.dropDownSettings = {
			singleSelection: true,
			idField: 'id',
			textField: 'descripcion',
			itemsShowLimit: 15,
			allowSearchFilter: true,
		};
	}

	ngOnInit(): void {
		this.getVendedoresDeComercio();
		this.titleService.updateTitle('Vendedores');
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		});
	}

	public activarDesactivarVendedor(vendedor: VendedorLista): void {
		this.currentUser.ifPresent((currentUser) => {
			this.loading.next(true);
			const sellerState = vendedor.isActive() ? SellerState.Inactive : SellerState.Active;
			this.restApiService
				.postActivarDesactivarVendedor(currentUser.commerce_id, sellerState, vendedor.userId)
				.pipe(finalize(() => this.loading.next(false)))
				.subscribe({
					next: () => {
						vendedor.sellerState = sellerState;
					},
					error: (response: HttpErrorResponse) => {
						this.uIService.errorMessage('ERROR', response.error.message);
					},
				});
		});
	}

	private getVendedoresDeComercio(): void {
		// this.loading.next(true);
		// this.vendedoresService.getVendedores()
		// .subscribe({
		//   next: (data: ComercioConVendedores[]) => {
		//     this.comerciosWithEmployees = this.vendedoresService.getComerciosWithEmployees();
		//   },
		//   error: (error: HttpErrorResponse) => {
		//     // handle error
		//   },
		// });
		this.vendedoresService.getVendedores().subscribe((data: ComercioConVendedores[]) => {
			this.setCommercesWithEmployees(data);
		});
		// this.setCommercesWithEmployees(data.);
		// this.restApiService.getVendedoresDeComercio()
		// .pipe(finalize(() => this.loading.next(false)))
		//   .subscribe({
		//     next: (data: ComercioConVendedores[]) =>{
		//       this.setCommercesWithEmployees(data);
		//       this.uIService.responseLog('getVendedoresDeComercio - OK', data);
		//     },
		//     error: (error: HttpErrorResponse) => {
		//       this.uIService.responseLog('getComercios - ERROR', error);
		//       this.uIService.errorMessage('Error!', error.error.message);
		//       this.authenticationService.logout();
		//     },
		//   })
	}

	private setCommercesWithEmployees(commercesWithEmployees: ComercioConVendedores[]): void {
		if (commercesWithEmployees.length >= 1) {
			this.firstCommerce = commercesWithEmployees[0];
			this.commerceSelected = commercesWithEmployees[0];
			this.selectedChanges.emit(commercesWithEmployees[0]);
		}
		this.disabled = commercesWithEmployees.length === 1;
		this.commercesWithEmployees = commercesWithEmployees;
	}

	public getVendedores(): VendedorLista[] {
		return this.commercesWithEmployees.find((comercio: ComercioConVendedores) => comercio.id == this.commerceSelected.id).vendedores;
	}

	public onSelectionChanged($event) {
		this.selectedChanges.emit($event.value);
		this.commerceSelected = $event.value;
	}

	public goBack(): void {
		this.router.navigate([Routes.Home]);
	}

	public loadCreateOrEdit(): void {
		this.router.navigate(['adminVendedores', 'create']);
	}

	public edit(vendedor: VendedorLista) {
		this.vendedoresService.startedEditing.next(vendedor.userId);
		this.router.navigate([Routes.AdminVendedoresCreate, vendedor.userId]);
	}

	public getcommercesWithEmployees() {
		return this.commercesWithEmployees.slice();
	}
}
