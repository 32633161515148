import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AuthenticationService} from '../../_services/authentication.service';
import {NavigationEnd, Router} from '@angular/router';
import {Optional} from 'src/app/_models/optional.model';
import {User} from 'src/app/_models/user.model';
import {MatStepper} from '@angular/material/stepper';
import {BehaviorSubject} from 'rxjs';
import {HeaderStep} from '../../_enums/header-step.enum';
import {OnboardingService} from '../../_services/onboarding-steps.service';
import {OnboardingStep} from '../../_enums/onboarding-step.enum';
import {TitleService} from '../../_services/title.service';
import {Routes} from '../../_enums/routes.enum';
import {StyleServiceService} from '../../_services/style-service.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
	public useCredilowStyle: boolean;
	@Output() changeMenuState: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Input() showRequest: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	@Input() showMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	@ViewChild('stepper') stepper: MatStepper;

	public currentUser: Optional<User>;
	public completed: boolean;
	public selected: boolean = true;
	public state: string;
	public verMenu = false;
	public title: string;
	public showBlackLogo: boolean = true;
	private readonly Routes = Routes;

	constructor(
		private readonly router: Router,
		private readonly authenticationService: AuthenticationService,
		private titleService: TitleService,
		public styleService: StyleServiceService
	) {
		this.authenticationService.currentUser.subscribe({
			next: (currentUser: Optional<User>) => {
				this.currentUser = currentUser;
			},
			error: (error: Error) => {
				console.log(error);
			},
		});
	}

	public ngOnInit(): void {
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		}); // if (!this.currentUser.isPresent()) {
		// 	this.router.navigate(['./login']);
		// }
		this.titleService.title.subscribe((title) => (this.title = title));
		//todo user showblacklogo que viene del header container
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.showBlackLogo = event.url === Routes.Home || event.url === Routes.EmptyRoute;
			}
		});
	}

	public changeMenu(): void {
		this.showMenu.next(!this.showMenu.value);
	}

	public changePage(route: string) {
		this.router.navigate([route]);
		this.changeMenu();
	}

	public logOut() {
		this.authenticationService.logout();
	}

	toggleStyle() {
		this.styleService.toggleStyle();
	}
}
