import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Routes} from 'src/app/_enums/routes.enum';
import {DialogService} from 'src/app/_services/dialog.service';
import {DialogComponent} from '../dialog/dialog.component';

@Component({
	selector: 'app-install-prompt',
	template: '',
})
export class InstallPromptComponent implements OnInit {
	deferredPrompt: any;
	beforeInstallPromptTriggered = false;

	constructor(private dialogService: DialogService, private router: Router) {}

	ngOnInit(): void {
		window.addEventListener('beforeinstallprompt', (event: any) => {
			// Prevent Chrome 76 and later from showing the mini-infobar
			event.preventDefault();

			// Stash the event so it can be triggered later.
			this.deferredPrompt = event;

			const dialogRef = this.dialogService.openDialog(DialogComponent, {
				title: '¿Desea instalar la aplicación?',
				buttonLabel: 'Cancelar',
				secondButtonLabel: 'Instalar',
				secondButtonAction: () => {
					this.installPWA();
				},
			});
			dialogRef.afterClosed().subscribe((result) => {
				// After the dialog is closed, navigate to login only if beforeInstallPromptTriggered is still false
					this.router.navigate([Routes.Login]);
			});
			this.beforeInstallPromptTriggered = true;
		});

		// If beforeInstallPromptTriggered is still false after 5 seconds, navigate to login
		setTimeout(() => {
			if (!this.beforeInstallPromptTriggered) {
				this.router.navigate([Routes.Login]);
			}
		}, 5000);
	}

	installPWA(): void {
		// Show the install prompt
		if (this.deferredPrompt) {
			this.deferredPrompt.prompt();

			// Wait for the user to respond to the prompt
			this.deferredPrompt.userChoice.then((choiceResult: any) => {
				if (choiceResult.outcome === 'accepted') {
					console.log('User accepted the install prompt');
				} else {
					console.log('User dismissed the install prompt');
				}

				// Clear the saved prompt since it can't be used again
				this.deferredPrompt = null;
			});
		}
	}
}
