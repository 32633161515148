import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {tap} from 'rxjs/operators';
import {LocalStorageService} from '../_services/local-storage.service';
import {HttpStatusCode} from '../_enums/http-status-codes.enum';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
	constructor(private router: Router, private localStorageService: LocalStorageService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const requestClone = request.clone({
			setHeaders: {
				Authorization: `Bearer ${this.localStorageService.getToken().value}`,
			},
		});
		return next.handle(requestClone).pipe(
			tap(
				(event) => {
					if (event instanceof HttpResponse) {
					}
				},
				(error) => {
					if (error instanceof HttpErrorResponse) {
						if (error.status === HttpStatusCode.Unauthorized) {
							this.router.navigate(['./login']);
						}
					}
				}
			)
		);
	}
}
