export enum CreditType {
	ReciboDeSueldo = 1,
	TarjetaDeCredito = 2,
	PlanDNI = 3,
	Jubilados = 4,
	PlanTrabajar = 5,
	PlanBecarios = 6,
	PlanVeredas = 7,
	Domesticas = 8,
	DNI2Servicios = 9,
	DNISS = 10,
}
