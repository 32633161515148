import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {RestApiService} from '../../_services/rest-api.service';
import {AuthenticationService} from '../../_services/authentication.service';
import {Cliente} from '../../_models/cliente.model';
import {ClienteService} from '../../_services/cliente.service';
import {Optional} from 'src/app/_models/optional.model';
import {User} from 'src/app/_models/user.model';
import {Document} from '../../_models/document.model';
import {DocumentSide} from '../../_enums/document-side.enum';
import {HttpErrorResponse} from '@angular/common/http';
import {IdentityService} from '../../_services/identity.service';
import {finalize} from 'rxjs/operators';
import {EnvironmentService} from '../../_services/enviroment.service';
import {OnboardingService} from '../../_services/onboarding-steps.service';
import {OnboardingStep} from '../../_enums/onboarding-step.enum';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';
import {ImageConverter} from '../../_helpers/image-converter.support';
import {UIService} from '../../_services/ui.service';
import {AuthorizationService} from '../../_services/authorization.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {TitleService} from '../../_services/title.service';
import {StyleServiceService} from '../../_services/style-service.service';
import {DialogService} from 'src/app/_services/dialog.service';
import {DialogComponent} from 'src/app/component/dialog/dialog.component';
import {Router} from '@angular/router';
import {Routes} from '../../_enums/routes.enum';
import {DomSanitizer} from '@angular/platform-browser';
import {InternalErrorCode} from '../../_enums/internal-error-code.enum';
import {ErrorMessage} from '../../_enums/error-message.enum';
import {Step} from '../../_enums/step.enum';
import {LogType} from '../../_enums/log-type.enum';
import {Gender} from '../../_enums/gender.enum';
import {Origin} from '../../_enums/origin.enum';

@Component({
	selector: 'app-foto-dni',
	templateUrl: './foto-dni.component.html',
	styleUrls: ['./foto-dni.component.css'],
})
export class FotoDniComponent implements OnInit {
	public useCredilowStyle: boolean;
	private cliente: Cliente = new Cliente();
	private trigger: Subject<void> = new Subject<void>();
	private shouldComplete: boolean;

	public allowCameraSwitch = true;
	public showWebcam = true;
	public multipleWebcamsAvailable = false;
	public deviceId: string;
	public facingMode: string = 'environment';
	public webcamImage: WebcamImage = null;
	public automaticAuthorizerFrontEndURI: string;
	public document: Document = new Document();
	public stepCheckout: boolean = false;
	public currentUser: Optional<User>;
	public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public dniForm = this.formBuilder.group({
		frente: new UntypedFormControl(undefined, [Validators.required]),
		dorso: new UntypedFormControl(undefined, [Validators.required]),
	});
	public showPreview: boolean = false;
	public errorMessage: string;
	public errorStatusCode: string;
	public errorError: string;
	public httpErrorMessage: string;
	public httpErrorStatus: number;
	public httpErrorUrl: string;

	public productionEnv: boolean;

	public availableVideoInputs: MediaDeviceInfo[] = [];

	// switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
	private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

	private readonly phoneCamarasAvailable = 4;
	private readonly desiredIndexPhoneCamara = 3;
	private readonly mobileAspectRadio = 1080 / 1920;
	private readonly desktopAspectRadio = 1920 / 1080;

	@Output() showHeaderStepper: EventEmitter<any> = new EventEmitter<any>();

	constructor(
		public styleService: StyleServiceService,

		private readonly formBuilder: UntypedFormBuilder,
		private readonly restApiService: RestApiService,
		private readonly authenticationService: AuthenticationService,
		private readonly authorizationService: AuthorizationService,
		private readonly identityService: IdentityService,
		private readonly environmentService: EnvironmentService,
		private readonly onboardingService: OnboardingService,
		private readonly UIService: UIService,
		private readonly clienteService: ClienteService,
		private readonly uIService: UIService,
		private titleService: TitleService,
		private deviceService: DeviceDetectorService,
		private dialogService: DialogService,
		private router: Router,
		private envService: EnvironmentService,
		private sanitizer: DomSanitizer
	) {}

	public ngOnInit(): void {
		this.restApiService.monitoringLog({step: Step.CreateSolicitud, logType: LogType.Flujo}).subscribe({
			next: (data: any) => {
				this.cliente.creditFlowId = data.creditFlowId;
			},
			error: (error: HttpErrorResponse) => {
				this.uIService.responseLog('monitoringLog - ERROR', error.error.message);
			},
		});
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		});
		this.titleService.updateTitle('Fotos DNI');
		this.showHeaderStepper.emit();
		this.automaticAuthorizerFrontEndURI = this.environmentService.automaticAuthorizerFrontEndURI();
		this.authenticationService.currentUser.subscribe({
			next: (user: Optional<User>) => (this.currentUser = user),
			error: (error: Error) => console.log(error),
		});
		this.clienteService.getCliente().subscribe({
			next: (cliente: Cliente) => (this.cliente = cliente),
			error: (error: Error) => console.log(error),
		});
		this.document.recognizedDocument = true;
		this.document.side = DocumentSide.Front;
		this.authorizationService.resetAutorizacion();

		WebcamUtil.getAvailableVideoInputs().then((mediaDevices: MediaDeviceInfo[]) => {
			this.availableVideoInputs = mediaDevices;

			// no hay una manera de seleccionar la camara no wideangle, con esta camara el DNI no pasa verificacion
			// si el celular tiene 4 camaras (front back y wide angle en ambas), la numero 3 es la front no wideangle. queremos esa por defecto
			// si el celular tiene 2 camaras (sin wideangle) se selecciona la facingMode='environment'.
			if (this.availableVideoInputs.length === this.phoneCamarasAvailable) {
				this.showNextWebcam(this.availableVideoInputs[this.desiredIndexPhoneCamara].deviceId);
			}
		});
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		});
		this.productionEnv = this.environmentService.environmentProduction();
	}

	public clearDocument(): void {
		this.document.side = DocumentSide.Front;
		this.dniForm.controls.frente.reset();
		this.dniForm.controls.dorso.reset();
	}

	public import(file: File): void {
		this.UIService.successMessage('Éxito!', 'La foto se subió correctamente');
		if (this.document.side === DocumentSide.Front) {
			this.dniForm.controls.frente.setValue(file);
			this.document.side = DocumentSide.Back;
		} else {
			this.dniForm.controls.dorso.setValue(file);
		}
	}

	public documentVerification(): void {
		this.loading.next(true);
		this.restApiService.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.PreValidateDNI, logType: LogType.Flujo}).subscribe();
		this.identityService.uploadDocument(this.dniForm.controls.frente.value, this.dniForm.controls.dorso.value).subscribe({
			next: (data: any) => {
				this.cliente.identityId = data.identityId;
				this.cliente.validationId = data.validationId;
				this.cliente.nombre = data.persona.name + ' ' + data.persona.lastName;
				this.cliente.sexo = data.persona.gender;
				this.cliente.fechaNacimiento = data.persona.birthDate;
				this.cliente.dni = data.persona.dni;
				this.cliente.nuevo = data.cardNumber == null;
				this.cliente.celular = data.mobilePhone;
				this.cliente.validatedCelular = !(data.mobilePhone === undefined || data.mobilePhone === null);
				this.cliente.email = data.email;
				this.cliente.tarjeta = data.cardNumber ? data.cardNumber.substr(data.cardNumber.length - 4) : data.cardNumber;
				this.authorizationService.setUnderCommerceRisk(data.underCommerceRisk);

				if (data.underCommerceRisk) {
					this.showUnderCommerceRisk();
				}
				this.restApiService
					.monitoringLog({
						creditFlowId: this.cliente.creditFlowId,
						step: Step.ValidateDNI,
						logType: LogType.Flujo,
						gender: Gender.from(data.persona.gender),
						dni: data.persona.dni,
						origin: Origin.AC,
						validationId:data.validationId
					})
					.subscribe();
			},
			error: (error: HttpErrorResponse) => {
				this.loading.next(false);
				console.log(error);

				this.document.recognizedDocument = false;
				this.errorMessage = error.error.errors ? error.error.errors[0].message : error.error.error;
				this.errorStatusCode = error.error.errors ? error.error.errors[0].code : error.error.statusCode;

				this.restApiService
					.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.ValidateDNI, logType: LogType.Consola, error, validationId: error.error.payload._validationId})
					.subscribe();

				if (this.errorStatusCode == InternalErrorCode.GenericError) {
					this.errorMessage = ErrorMessage.GenericError;
				}
				this.errorError = error.error.error;
				this.httpErrorMessage = error.message;
				this.httpErrorStatus = error.status;
				this.httpErrorUrl = error.url;
				this.stepCheckout = true;
			},
			complete: () => {
				this.clienteService.setCliente(this.cliente);
				if (!this.cliente.nuevo) {
					this.nextStep();
				} else {
					this.loading.next(false);
					this.onboardingService.moveTo(OnboardingStep.PersonalData);
				}
			},
		});
	}

	private nextStep(): void {
		this.restApiService
			.findClient(this.cliente.identityId)
			.pipe(
				finalize(() => {
					this.loading.next(false);
				})
			)
			.subscribe({
				next: () => {
					this.stepCheckout = true;
					this.onboardingService.moveTo(OnboardingStep.PersonalData);
				},
				error: (error: HttpErrorResponse) => {
					this.uIService.responseLog('findClient - ERROR', error);
					this.uIService.errorMessage('Error!', error.error.message);
					this.goBack();
				},
			});
	}

	public cancel(): void {
		this.restApiService.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.Cancel, logType: LogType.Flujo}).subscribe();
		this.onboardingService.restart();
		this.clienteService.restart();
	}

	public goBack(): void {
		this.document.recognizedDocument = true;
		this.stepCheckout = false;
		this.clearDocument();
	}

	public triggerSnapshot(): void {
		this.trigger.next();
	}

	public handleInitError(error: WebcamInitError): void {
		if (error.mediaStreamError && error.mediaStreamError.name === 'NotAllowedError') {
			console.log('User denied camera access');
			this.restApiService
				.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: this.document.isSideFront() ? Step.TakePictureDniFront : Step.TakePictureDniBack, logType: LogType.Consola, error})
				.subscribe();
			this.uIService.errorMessage('Error!', 'El usuario no permitio el uso de la camara');
		}
	}

	public handlePreviewImage(webcamImage: WebcamImage): void {
		this.restApiService
			.monitoringLog({
				creditFlowId: this.cliente.creditFlowId,
				step: this.document.isSideFront() ? Step.TakePictureDniFront : Step.TakePictureDniBack,
				logType: LogType.Flujo,
			})
			.subscribe();
		this.webcamImage = webcamImage;
		this.showPreview = true;
	}

	public handleImage(webcamImage: WebcamImage): void {
		this.restApiService
			.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.AcceptPreviewDniPicture, logType: LogType.Flujo})
			.subscribe();
		this.showPreview = false;
		this.webcamImage = webcamImage;
		const dniSide = ImageConverter.dataURLtoFile(this.webcamImage.imageAsDataUrl, 'dniSide.jpeg');
		this.import(dniSide);
	}

	public get triggerObservable(): Observable<void> {
		return this.trigger.asObservable();
	}

	public get videoOptions(): MediaTrackConstraints {
		const result: MediaTrackConstraints = {};
		if (this.facingMode && this.facingMode !== '') {
			result.facingMode = {ideal: this.facingMode};
		}

		//ngx-webcam no acepta landscape o portrait mode. Si es mobile, la camara viene en portrait y sale un rectangulo horizontal
		//si es mobile se setea el aspect radio invertido a si es desktop, para que salga un cuadrado horizontal siempre
		if (this.deviceService.isMobile()) {
			result.aspectRatio = this.mobileAspectRadio;
		} else {
			result.aspectRatio = this.desktopAspectRadio;
		}

		return result;
	}

	public showNextWebcam(directionOrDeviceId: boolean | string): void {
		// true => move forward through devices
		// false => move backwards through devices
		// string => move to device with given deviceId
		this.nextWebcam.next(directionOrDeviceId);
	}

	public get nextWebcamObservable(): Observable<boolean | string> {
		return this.nextWebcam.asObservable();
	}

	onChange(event) {
		this.dniForm.controls.frente.setValue(event.target.files[0]);
	}
	onChangeD(event) {
		this.dniForm.controls.dorso.setValue(event.target.files[0]);
	}

	public showUnderCommerceRisk() {
		const dialogRef = this.dialogService.openDialog(DialogComponent, {
			title: 'Falla del proveedor del servicio',
			data: this.sanitizer.bypassSecurityTrustHtml(
				`En este momento no se pudieron procesar las imágenes debido a una falla del proveedor del servicio.<br> Podes continuar la autorización dejando la validación de identidad pendiente o utilizar ahora el autorizador automático disponible en el siguiente link: <a href="${this.envService.automaticAuthorizerFrontEndURI()}">www.autorizador.credilow.com.ar</a>`
			),
			buttonLabel: 'Volver',
			secondButtonLabel: 'Continuar',
			secondButtonAction: () => {
				this.shouldComplete = true;
				this.authorizationService.setAcceptedUnderCommerceRisk();
				dialogRef.close();
			},
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (!this.shouldComplete) {
				this.authorizationService.resetAutorizacion();
				this.router.navigate([Routes.Home]);
			}
		});
	}
}
