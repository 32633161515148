<div class="fill position-relative d-flex flex-column">
	<div class="credilow-monto-solicitado">
		<h6>Ingrese el Monto</h6>
		<input
			[formControl]="amountForm"
			type="text"
			placeholder="$ 0,00"
			digitOnly
			inputmode="numeric"
			autocomplete="off"
			[decimal]="true"
			decimalSeparator=","
			[ngClass]="{'is-invalid': isInvalidForm()}"
			name="monto-solicitado"
		/>
	</div>
	<div class="credilow-footer credilow-navigate space-between-buttons">
		<div class="credilow-50">
			<button class="credilow-previous-button credilow-shadow" [ngClass]="{sanii: !useCredilowStyle}" (click)="cancel()">Cancelar</button>
		</div>
		<div class="credilow-50">
			<button
				class="credilow-next-button credilow-shadow"
				[ngClass]="{sanii: !useCredilowStyle}"
				(click)="continue()"
				[disabled]="amountForm.invalid"
				id="btn-monto-next"
				appEnterClick
			>
				Continuar
			</button>
		</div>
	</div>
</div>
<app-loader *ngIf="loading | async"></app-loader>
