import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {SigningProcess} from '../../_models/signing-process.model';
import {ErrorMessage} from '../../_enums/error-message.enum';
import {SigningProcessService} from '../../_services/signing-process.service';
import {UIService} from '../../_services/ui.service';
import {ResponseStatus} from 'src/app/_enums/response-status.enum';

import {StyleServiceService} from '../../_services/style-service.service';
import {RestApiService} from '../../_services/rest-api.service';
import {Step} from '../../_enums/step.enum';
import {LogType} from '../../_enums/log-type.enum';

@Component({
	selector: 'x-product-reception',
	templateUrl: './product-reception.component.html',
	styleUrls: ['./product-reception.component.css'],
})
export class ProductReceptionComponent implements OnInit {
	public useCredilowStyle: boolean;
	public siginingProcess: SigningProcess;
	public recivedProduct: boolean = false;

	public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private signingProcessSubscription: Subscription;

	constructor(
		public styleService: StyleServiceService,
		private readonly signingProcessService: SigningProcessService,
		private readonly uIService: UIService,
		private readonly restApiService: RestApiService
	) {}

	public ngOnInit(): void {
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		});
		this.signingProcessSubscription = this.signingProcessService.getSigningProcess().subscribe({
			next: (siginingProcess: SigningProcess) => {
				this.siginingProcess = siginingProcess;
				this.restApiService
					.monitoringLog({creditFlowId: this.siginingProcess.creditFlowId, step: Step.StartProductReception, logType: LogType.Flujo})
					.subscribe(() => {
						this.unsubscribe();
					});
			},
			error: (error: Error) => {
				console.log(error);
			},
		});
	}

	private unsubscribe(): void {
		if (this.signingProcessSubscription && !this.signingProcessSubscription.closed) {
			this.signingProcessSubscription.unsubscribe();
		}
	}

	public productRecived(): void {
		this.loading.next(true);
		this.signingProcessService
			.postAcceptProductsReceipt(this.siginingProcess.authId)
			.pipe(
				finalize(() => {
					this.loading.next(false);
				})
			)
			.subscribe({
				next: (data: any) => {
					console.log(data);
					if (data.status == ResponseStatus.Error) {
						this.restApiService
							.monitoringLog({
								creditFlowId: this.siginingProcess.creditFlowId,
								step: Step.ProductReception,
								logType: LogType.Consola,
								error: data,
							})
							.subscribe();
						this.uIService.errorMessage('Error!', data.message);
					} else {
						this.recivedProduct = true;
						this.restApiService
							.monitoringLog({creditFlowId: this.siginingProcess.creditFlowId, step: Step.ProductReception, logType: LogType.Flujo})
							.subscribe();
					}
				},
				error: (error: HttpErrorResponse) => {
					this.restApiService
						.monitoringLog({
							creditFlowId: this.siginingProcess.creditFlowId,
							step: Step.ProductReception,
							logType: LogType.Consola,
							error,
						})
						.subscribe();
					this.uIService.errorMessage('Error!', ErrorMessage.CodAA05);
					console.log(error);
				},
			});
	}

	public recordReceiptProduct(): void {
		this.loading.next(true);
		this.signingProcessService
			.postRecordReceiptProductUrl(this.siginingProcess.authId)
			.pipe(finalize(() => this.loading.next(false)))
			.subscribe({
				next: (data: any) => {
					this.restApiService
						.monitoringLog({creditFlowId: this.siginingProcess.creditFlowId, step: Step.ViewReciboProducto, logType: LogType.Flujo})
						.subscribe();

					window.open(data.url, '_blank');
				},
				error: (error: HttpErrorResponse) => {
					console.log(error);
					this.restApiService
						.monitoringLog({
							creditFlowId: this.siginingProcess.creditFlowId,
							step: Step.ViewReciboProducto,
							logType: LogType.Consola,
							error,
						})
						.subscribe();
					this.uIService.errorMessage('Error!', ErrorMessage.CodAA05);
				},
			});
	}
}
