<div class="container">
	<div *ngIf="cliente.nuevo" class="mb-4 credilow-text welcome-text">BIENVENIDO A CREDILOW</div>
	<form novalidate [formGroup]="nuevaSolicitud">
		<div>
			<div class="credilow-new-client-tabs-body">
				<div class="selectCommerces">
					<x-comercio-select class="text-center" (selectedChanges)="selectComercio($event)"></x-comercio-select>
				</div>
				<div>
					<div>
						<div class="mb-2">
							<input
								class="formInput"
								type="text"
								[readOnly]="mobilePhoneReadOnly | async"
								[ngClass]="{
									'is-invalid': isInValidInput(nuevaSolicitud.controls.mobilePhone)
								}"
								placeholder="Teléfono Celular"
								formControlName="mobilePhone"
								digitOnly
								matTooltip="Ingrese Su Teléfono Celular (Ejemplo: 1194524123)"
								matTooltipHideDelay="1000"
								[ngStyle]="{
									width: isMobilePhoneInputValidated.value ? '80%' : '90%'
								}"
								inputmode="numeric"
								autocomplete="off"
							/>
							<span *ngIf="isMobilePhoneInputValidated | async" class="material-icons">check_circle</span>
							<button
								*ngIf="canEditMobilePhone | async"
								
								class="small-btn p-2 modify-btn"
								(click)="editMobilePhoneInput()"
							>
								Modificar
							</button>
							
						</div>
						<div class="mb-2">
							<input
								class="formInput"
								type="text"
								placeholder="Teléfono Fijo"
								formControlName="homePhone"
								[ngClass]="{
									'is-invalid': isInValidInput(nuevaSolicitud.controls.homePhone)
								}"
								#tooltip="matTooltip"
								matTooltip="Ingrese Su Teléfono Fijo (Ejemplo: 1142203451)"
								matTooltipHideDelay="1000"
								inputmode="numeric"
								autocomplete="off"
							/>
						</div>
					</div>
					<div>
						<div class="credilow-yes-no-title">¿Tiene trabajo en blanco o percibe jubilación o pensión?</div>
						<div class="mb-2 credilow-yes-no">
							<div
								class="credilow-yes-no-option credilow-yes-no-option-left"
								[ngClass]="{
									'credilow-yes-no-option-active': this.nuevaSolicitud.controls.blankJob.value
								}"
								(click)="changeBlankJob(true)"
								id="si-btn"
							>
								Si
							</div>
							<div
								class="credilow-yes-no-option credilow-yes-no-option-right"
								[ngClass]="{
									'credilow-yes-no-option-active': !this.nuevaSolicitud.controls.blankJob.value
								}"
								(click)="changeBlankJob(false)"
								id="no-btn"
							>
								No
							</div>
						</div>
						<div class="mt-2" *ngIf="commerceOfferDniPlan()">
							<div class="credilow-number-of-services-title">Cantidad de servicios que presenta:</div>
							<div class="credilow-number-of-services">
								<div
									class="credilow-number-of-services-option credilow-number-of-services-option-left"
									[ngClass]="{
										'credilow-number-of-services-active': this.nuevaSolicitud.controls.amountOfServices.value === 0
									}"
									(click)="changeAmountOfServices(0)"
								>
									0
								</div>
								<div
									class="credilow-number-of-services-option"
									[ngClass]="{
										'credilow-number-of-services-active': this.nuevaSolicitud.controls.amountOfServices.value === 1
									}"
									(click)="changeAmountOfServices(1)"
									id="1-serv-btn"
								>
									1
								</div>
								<div
									class="credilow-number-of-services-option credilow-number-of-services-option-right"
									[ngClass]="{
										'credilow-number-of-services-active': this.nuevaSolicitud.controls.amountOfServices.value === 2
									}"
									(click)="changeAmountOfServices(2)"
								>
									2
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="credilow-footer credilow-navigate">
			<div class="credilow-50">
				<button
					class="credilow-previous-button credilow-shadow"
					[ngClass]="{sanii: !useCredilowStyle}"
					(click)="cancel()"
					[ngClass]="{sanii: !useCredilowStyle}"
				>
					Cancelar
				</button>
			</div>
			<div class="credilow-50">
				<button
					class="credilow-next-button credilow-shadow"
					[ngClass]="{sanii: !useCredilowStyle}"
					[disabled]="invalidForm()"
					(click)="warningDialogOrNextStep()"
					[ngClass]="{sanii: !useCredilowStyle}"
					id="next-btn"
					appEnterClick
				>
					Continuar
				</button>
			</div>
		</div>
	</form>
</div>
<app-loader *ngIf="loading | async"></app-loader>
