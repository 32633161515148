import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../_services/authentication.service';
import {Optional} from '../_models/optional.model';
import {User} from '../_models/user.model';
import {OnboardingStep} from '../_enums/onboarding-step.enum';
import {OnboardingService} from '../_services/onboarding-steps.service';
import {LocalStorageService} from '../_services/local-storage.service';
import {AuthenticationToken} from '../_models/authentication-token.model';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import {Routes} from '../_enums/routes.enum';

import {StyleServiceService} from 'src/app/_services/style-service.service';

@Component({
	selector: 'app-container',
	templateUrl: './container.component.html',
	styleUrls: ['./container.component.css'],
})
export class ContainerComponent implements OnInit {
	public useCredilowStyle: boolean;
	public onboardingStep: BehaviorSubject<OnboardingStep> = new BehaviorSubject<OnboardingStep>(OnboardingStep.Home);
	public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public home: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	//MENU OPTIONS
	public showRequest: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public showMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public title: BehaviorSubject<string> = new BehaviorSubject<string>('Credilow');
	public currentUser: Optional<User>;

	constructor(
		public styleService: StyleServiceService,

		private readonly authenticationService: AuthenticationService,
		private readonly localStorageService: LocalStorageService,
		private readonly onboardingService: OnboardingService,
		private router: Router
	) {}

	public ngOnInit(): void {
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		});
		this.authenticationService.currentUser.subscribe((x) => (this.currentUser = x));
		this.checkoutValidationToken();
		this.onboardingService.step.subscribe({
			next: (currentStep: OnboardingStep) => {
				if (currentStep === OnboardingStep.Home) {
					this.backToHome();
				}
				this.onboardingStep.next(currentStep);
			},
			error: (error: Error) => {
				console.log(error);
			},
		});
		this.showRequest.subscribe({
			next: () => {
				this.onboardingService.moveTo(OnboardingStep.DocumentPhoto);
			},
			error: (error: Error) => {
				console.log(error);
			},
		});
	}

	private checkoutValidationToken(): void {
		if (this.localStorageService.getToken().isPresent()) {
			const authenticationToken = new AuthenticationToken(this.localStorageService.getToken().value);
			if (authenticationToken.timeToExpire() <= 0 || !this.currentUser) {
				this.logout();
			}
		} else {
			this.logout();
		}
	}

	public logout(): void {
		this.authenticationService.logout();
	}

	private restartPagesState(): void {
		this.showRequest.next(false);
	}

	public changePage(titlePage: string, pageToChange: BehaviorSubject<boolean>): void {
		this.showMenu.next(false);
		this.home.next(false);
		this.title.next(titlePage);
		this.restartPagesState();
		pageToChange.next(true);
	}

	public backToHome(): void {
		this.restartPagesState();
		this.showMenu.next(false);
		this.home.next(true);
	}

	public loadAdminVendedores(): void {
		this.router.navigate([Routes.AdminVendedores]);
	}

	public showQR(): void {
		this.router.navigate([Routes.ShowQR]);
	}

	public creditsByVendedor(): void {
		this.router.navigate([Routes.CreditsByVendedor]);
	}

	public creditSimulator(): void {
		this.router.navigate([Routes.CreditSimulator]);
	}

	/**
	 * @description
	 *	Permite utilizar el enum en la vista HTML.
	 */
	public get OnboardingStep(): typeof OnboardingStep {
		return OnboardingStep;
	}
}
