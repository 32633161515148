import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit, ViewChild} from '@angular/core';
import {MatStepper} from '@angular/material/stepper';
import {ActivatedRoute, Params} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {UIService} from 'src/app/_services/ui.service';
import {SigningProcessStep} from '../../_enums/signing-process-step.enum';
import {SigningProcess} from '../../_models/signing-process.model';
import {LocalStorageService} from '../../_services/local-storage.service';
import {SigningProcessStepService} from '../../_services/signing-process-steps.service';
import {SigningProcessService} from '../../_services/signing-process.service';

import {StyleServiceService} from 'src/app/_services/style-service.service';
import {ErrorPageService} from 'src/app/_services/error-page.service';
import {Routes} from 'src/app/_enums/routes.enum';
import {ErrorMessage} from 'src/app/_enums/error-message.enum';
import {InternalErrorCode} from 'src/app/_enums/internal-error-code.enum';
import {RestApiService} from '../../_services/rest-api.service';
import {Step} from '../../_enums/step.enum';
import {LogType} from '../../_enums/log-type.enum';

@Component({
	selector: 'app-signing-process-container',
	templateUrl: './signing-process-container.component.html',
	styleUrls: ['./signing-process-container.component.css'],
})
export class SigningProcessContainerComponent implements OnInit {
	public useCredilowStyle: boolean;
	public completedStepper: boolean = false;
	public stepperState: string;
	public signingProcessStep: BehaviorSubject<SigningProcessStep> = new BehaviorSubject<SigningProcessStep>(
		SigningProcessStep.SigningProcessRedirect
	);
	private signingProcess: SigningProcess = new SigningProcess();
	public redirect: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	@ViewChild('stepper') stepper: MatStepper;
	private authIdFromParams: number;

	constructor(
		public styleService: StyleServiceService,

		private readonly route: ActivatedRoute,
		private readonly signingProcessService: SigningProcessService,
		private readonly signingProcessStepService: SigningProcessStepService,
		private readonly localStorageService: LocalStorageService,
		private readonly uIService: UIService,
		private errorPageService: ErrorPageService,
		private restApiService: RestApiService
	) {
		this.route.queryParams.subscribe({
			next: (queryParams: Params) => {
				this.authIdFromParams = queryParams.authId;
				this.getDataForSigningProcess(atob(queryParams.url));
			},
			error: (error: Error) => console.log(error),
		});
	}

	public ngOnInit(): void {
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		});
		this.signingProcessStepService.step.subscribe({
			next: (signingProcessStep: SigningProcessStep) => {
				if (signingProcessStep) this.signingProcessStep.next(signingProcessStep);
			},
			error: (error: Error) => {
				console.log(error);
			},
		});
	}

	private getDataForSigningProcess(url: string) {
		this.signingProcessService.startSigningProcess(url).subscribe({
			next: (data: any) => {
				if (data.status !== 'error') {
					this.uIService.responseLog('startSigningProcess - OK');
					this.signingProcess.authId = data.dataForSignature.authId;
					this.signingProcess.metodo_retiro = data.dataForSignature.metodo_retiro;
					this.signingProcess.monto_cuota = data.dataForSignature.monto_cuota;
					this.signingProcess.capital = data.dataForSignature.capital;
					this.signingProcess.cantidad_cuotas = data.dataForSignature.cantidad_cuotas;
					this.signingProcess.validationId = data.dataForSignature.validationId;
					this.signingProcess.planId = data.dataForSignature.planId;
					this.localStorageService.setToken(data.dataForSignature.jwt);
					this.restApiService
						.monitoringLog({authId: data.dataForSignature.authId, step: Step.StartSigningProcess, logType: LogType.Flujo})
						.subscribe({
							next: (data: any) => {
								this.signingProcess.creditFlowId = data.creditFlowId;
								this.handleMonitoringLogResult();
							},
							error: (error: any) => {
								this.handleMonitoringLogResult(); 
							},
							complete: () => {
								this.handleMonitoringLogResult();
							},
						});
				} else {
					this.uIService.responseLog('startSigningProcess - ERROR', data);
					this.errorPageService.setError(null, data.message);
				}
			},
			error: (error: HttpErrorResponse) => {
				this.uIService.responseLog('startSigningProcess - ERROR', error);
				if (error.error.code == InternalErrorCode.InvalidMAC) {
					this.errorPageService.setError(null, ErrorMessage.InvalidMAC, error.error.code);
				} else {
					this.errorPageService.setError(null, error.error.message, error.error.code);
				}
			},
		});
	}

	private handleMonitoringLogResult() {
		this.signingProcessService.setSigningProcess(this.signingProcess);
		this.signingProcessStepService.moveTo(SigningProcessStep.NeutralSelfie);
		this.redirect.next(false);
	}

	public changeStepperState(): void {
		this.stepper.next();
	}

	/**
	 * @description
	 *	Permite utilizar el enum en la vista HTML.
	 */
	public get SigningProcessStep(): typeof SigningProcessStep {
		return SigningProcessStep;
	}
}
