import {AbstractControl, ValidatorFn} from '@angular/forms';

export class ReferencePhonesValidator {
	public static referencePhonesValidator(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: boolean} => {
			if (!control.value) {
				return null;
			}
			const phone1 = control.get('telephoneReference1').value;
			const phone2 = control.get('telephoneReference2').value;
			const phone3 = control.get('telephoneReference3').value;

			if (!phone2 && !phone3) {
				return null;
			}

			if (phone1 && phone2 && phone1 === phone2) {
				control.get('telephoneReference2').setErrors({incorrect: true});
			}

			if (phone1 && phone3 && phone1 === phone3) {
				control.get('telephoneReference3').setErrors({incorrect: true});
			}

			if (phone2 && phone3 && phone2 === phone3) {
				control.get('telephoneReference3').setErrors({incorrect: true});
			}

			return null;
		};
	}
}
