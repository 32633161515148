import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../_services/authentication.service';
import {User} from '../_models/user.model';
import {Optional} from '../_models/optional.model';
import {Routes} from '../_enums/routes.enum';
import {finalize} from 'rxjs/operators';
import {Endpoint} from '../_helpers/endpoint.support';
import {EnvironmentService} from '../_services/enviroment.service';
import {HttpErrorResponse} from '@angular/common/http';
import {StyleServiceService} from '../_services/style-service.service';
import {DialogService} from '../_services/dialog.service';
import { DialogComponent } from '../component/dialog/dialog.component';
import { InternalErrorCode } from '../_enums/internal-error-code.enum';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
	public useCredilowStyle: boolean;
	public currentUser: Optional<User>;
	public user: UntypedFormGroup;
	public loginError = false;
	public loading = false;
	public loginErrorMessage: string;
	public accountBlocked: boolean = false;
	public productionEnv: boolean;

	constructor(
		public styleService: StyleServiceService,
		private readonly fb: UntypedFormBuilder,
		private readonly router: Router,
		private readonly authenticationService: AuthenticationService,
		private readonly environmentService: EnvironmentService,
		private dialogService: DialogService
	) {}

	public ngOnInit(): void {
		this.authenticationService.currentUser.subscribe((currentUser) => (this.currentUser = currentUser));
		this.currentUser
			.ifPresent(() => {
				this.router.navigate([Routes.Home]);
			})
			.orElse(() => {
				this.authenticationService.logout();
			});
		this.user = this.fb.group({
			username: [undefined, Validators.required],
			password: [undefined, Validators.required],
		});
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		});
		this.productionEnv = this.environmentService.environmentProduction();
		gtag('event', 'page_view', {
			page_title: "prueba",
		  })
	}

	public login(): void {
		this.loading = true;
		const username = this.user.value.username;
		this.authenticationService
			.login(this.user.value.username, this.user.value.password)
			.pipe(
				finalize(() => {
					this.loading = false;
				})
			)
			.subscribe({
				next: (user: any) => {
					this.router.navigate([Routes.Home]);
				},
				error: (error: HttpErrorResponse) => {
					if (error.status === 403) {
						this.accountBlocked = true;
					}
					if(error.error.code == InternalErrorCode.UserHasToUpdatePassword){
						this.authenticationService.logout();
						const dialogRef = this.dialogService.openDialog(DialogComponent, {
							data: 'Es necesario cambiar su Contraseña. Sera redirigido a la página',
							buttonLabel: 'Continuar',
						});
						dialogRef.afterClosed().subscribe((result) => {
							const url = Endpoint.join([this.environmentService.creditosURI()]);
							window.open(url.href);
						});
						
					}
					this.loginErrorMessage = error.error.error;
					this.loginError = true;
				},
			});
	}

	public resetPassword(): void {
		const url = Endpoint.join([this.environmentService.creditosURI(), `password`, `reset`]);
		window.open(url.href);
	}

	toggleStyle() {
		this.styleService.toggleStyle();
	}
}
