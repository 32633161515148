export enum Routes {
	Login = '/login',
	Home = '/home',
	AdminVendedores = '/adminVendedores',
	AdminVendedoresCreate = '/adminVendedores/create',
	ShowQR = '/showQR',
	CreditsByVendedor = '/creditsByVendedor',
	CreditSimulator = '/creditSimulator',
	CreateCredit = '/createCredit',
	SigningProcess = '/signingProcess',
	Install = '/install',
	Error = '/error',
	Credilow = 'https://www.credilow.com.ar/',
	EmptyRoute = '/'
}
