import {NgSignaturePadOptions, SignaturePadComponent} from '@almothafar/angular-signature-pad';
import {HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {ErrorMessage} from '../../_enums/error-message.enum';
import {SigningProcessStep} from '../../_enums/signing-process-step.enum';
import {WithdrawalMethods} from '../../_enums/withdrawal-methods.enum';
import {Endpoint} from '../../_helpers/endpoint.support';
import {SigningProcess} from '../../_models/signing-process.model';
import {EnvironmentService} from '../../_services/enviroment.service';
import {RestApiService} from '../../_services/rest-api.service';
import {SigningProcessStepService} from '../../_services/signing-process-steps.service';
import {SigningProcessService} from '../../_services/signing-process.service';
import {UIService} from '../../_services/ui.service';

import {StyleServiceService} from 'src/app/_services/style-service.service';
import {Step} from '../../_enums/step.enum';
import {LogType} from '../../_enums/log-type.enum';

@Component({
	selector: 'x-signature',
	templateUrl: './signature.component.html',
	styleUrls: ['./signature.component.css'],
})
export class SignatureComponent implements OnInit {
	public useCredilowStyle: boolean;
	@ViewChild('signature')
	private signaturePad: SignaturePadComponent;
	public signingProcess: BehaviorSubject<SigningProcess> = new BehaviorSubject<SigningProcess>(undefined);
	public confirmedSignature: boolean = false;
	public tycChecked: boolean = false;
	public mutualAgreementUrl: string;
	public signaturePadOptions: NgSignaturePadOptions = {
		canvasWidth: 250,
		canvasHeight: 250,
		backgroundColor: 'white',
	};
	public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	@Output() changeStepperState: EventEmitter<undefined> = new EventEmitter<undefined>();
	private signingProcessSubscription: Subscription;

	constructor(
		public styleService: StyleServiceService,

		private readonly signingProcessService: SigningProcessService,
		private readonly restApiService: RestApiService,
		private readonly environmentService: EnvironmentService,
		private readonly uIService: UIService,
		private readonly signingProcessStepService: SigningProcessStepService
	) {}

	public ngOnInit(): void {
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		});
		this.signingProcessSubscription = this.signingProcessService.getSigningProcess().subscribe({
			next: (signingProcess: SigningProcess) => {
				this.signingProcess.next(signingProcess);
				this.restApiService
					.monitoringLog({creditFlowId: signingProcess.creditFlowId, step: Step.StartAcceptTyC, logType: LogType.Flujo})
					.subscribe(() => {
						this.unsubscribe();
					});
			},
			error: (error: Error) => {
				console.log(error);
			},
		});
	}
	private unsubscribe(): void {
		if (this.signingProcessSubscription && !this.signingProcessSubscription.closed) {
			this.signingProcessSubscription.unsubscribe();
		}
	}

	public drawComplete(): void {
		if (!this.signaturePad.isEmpty()) {
			this.restApiService
				.monitoringLog({creditFlowId: this.signingProcess.value.creditFlowId, step: Step.SignatureComplete, logType: LogType.Flujo})
				.subscribe();
			this.signaturePad.off();
			this.confirmedSignature = true;
		} else {
			this.uIService.errorMessage('Error', 'Completar la firma');
		}
	}

	public complete(): void {
		if (!this.signaturePad.isEmpty()) {
			this.loading.next(true);
			this.signingProcessService
				.postSignatureAndTyc(
					this.signaturePad.toDataURL(),
					this.signingProcess.value.authId,
					this.signingProcess.value.validationId,
					this.tycChecked
				)
				.pipe(finalize(() => this.loading.next(false)))
				.subscribe({
					next: (data: any) => {
						this.restApiService
							.monitoringLog({creditFlowId: this.signingProcess.value.creditFlowId, step: Step.AcceptTyC, logType: LogType.Flujo})
							.subscribe();
						this.uIService.responseLog('aceptarTerminosYCondiciones - OK', data);
						this.signingProcess.value.comercioDescripcion = data.response.comercioDescripcion;
						this.signingProcess.value.comercioNombre = data.response.comercioNombre;
						this.signingProcess.value.autorizada = data.autorizada;
						this.signingProcessService.setSigningProcess(this.signingProcess.value);
						this.changeStepperState.emit();
						this.signingProcessStepService.moveTo(SigningProcessStep.ProductRecived);
					},
					error: (error: HttpErrorResponse) => {
						this.restApiService
							.monitoringLog({
								creditFlowId: this.signingProcess.value.creditFlowId,
								step: Step.AcceptTyC,
								logType: LogType.Consola,
								error,
							})
							.subscribe();
						this.uIService.responseLog('aceptarTerminosYCondiciones - ERROR', error);
						this.uIService.errorMessage('Error!', error.statusText);
					},
				});
		} else {
			this.restApiService
				.monitoringLog({
					creditFlowId: this.signingProcess.value.creditFlowId,
					step: Step.AcceptTyC,
					logType: LogType.Consola,
					error: 'Completar la firma',
				})
				.subscribe();
			this.uIService.errorMessage('Error', 'Completar la firma');
		}
	}

	public getMutualAgreement() {
		this.loading.next(true);
		this.restApiService
			.getMutuo(
				this.signingProcess.value.authId,
				this.signingProcess.value.planId,
				this.signingProcess.value.monto_cuota,
				WithdrawalMethods[this.signingProcess.value.metodo_retiro]
			)
			.pipe(finalize(() => this.loading.next(false)))
			.subscribe({
				next: (data: any) => {
					this.restApiService
						.monitoringLog({creditFlowId: this.signingProcess.value.creditFlowId, step: Step.GetMutuo, logType: LogType.Flujo})
						.subscribe();
					window.open(data, '_blank');
				},
				error: (error: HttpErrorResponse) => {
					console.log(error);
					this.uIService.errorMessage('Error!', ErrorMessage.CodAA05);
				},
			});
	}

	public showCredilowPolicies() {
		window.open(Endpoint.join([this.environmentService.credilowFrontEndURI(), '/policies']).href, '_blank');
	}

	public drawClear(): void {
		this.signaturePad.clear();
	}
}
