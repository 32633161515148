import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class EnvironmentService {
	public identityServiceURI(): string {
		return environment.identityServiceURI;
	}

	public automaticAuthorizerURI(): string {
		return environment.automaticAuthorizerURI;
	}

	public automaticAuthorizerFrontEndURI(): string {
		return environment.automaticAuthorizerFrontEndURI;
	}

	public credilowFrontEndURI(): string {
		return environment.credilowFrontEndURI;
	}

	public creditosURI(): string {
		return environment.creditosURI;
	}

	public environmentProduction(): boolean {
		return environment.production;
	}

	public googleApiKey(): string {
		return environment.googleApiKey;
	}

	public getVersion(): string{
		return "1.0.0";
	}
}
