<div class="credilow-login-background d-flex flex-column h-100" [ngClass]="{sanii: !useCredilowStyle}">
	<div class="credilow-login">
		<h4 *ngIf="currentUser.isPresent()" style="margin-bottom: 30px">Hola {{ currentUser.value.name }}!</h4>
		<h6 style="margin-bottom: 10px">¿Qué querés hacer hoy?</h6>
		<div class="credilow-planes" style="margin: 10px 0px 0px 0px">
			<div class="credilow-plan-cuota ga4-homebtn" (click)="createCredit()" style="font-size: 20px">
				<i class="fas fa-dollar-sign home-title-icon"></i>
				<b class="home-title">Crear Solicitud</b>
				<span class="credilow-plan-cuota-amount credilow-plan-cuota-amount-active">
					<i class="fas fa-arrow-right home-title-second-icon"></i>
				</span>
			</div>
			<div class="credilow-plan-cuota ga4-homebtn" (click)="creditSimulator()" style="font-size: 20px">
				<i class="fas fa-calculator home-title-icon"></i>
				<b class="home-title">Simular Crédito</b>
				<span class="credilow-plan-cuota-amount credilow-plan-cuota-amount-active">
					<i class="fas fa-arrow-right home-title-second-icon"></i>
				</span>
			</div>
			<div class="credilow-plan-cuota ga4-homebtn" (click)="creditsByVendedor()" style="font-size: 20px">
				<i class="fas fa-list-alt home-title-icon"></i>
				<b class="home-title">Listado de Créditos</b>
				<span class="credilow-plan-cuota-amount credilow-plan-cuota-amount-active">
					<i class="fas fa-arrow-right home-title-second-icon"></i>
				</span>
			</div>
			<div class="credilow-plan-cuota ga4-homebtn" (click)="showQR()" style="font-size: 20px">
				<i class="fas fa-qrcode home-title-icon"></i>
				<b class="home-title">Mostrar QR</b>
				<span class="credilow-plan-cuota-amount credilow-plan-cuota-amount-active">
					<i class="fas fa-arrow-right home-title-second-icon"></i>
				</span>
			</div>
			<div *ngIf="showAdminSellers" class="credilow-plan-cuota ga4-homebtn" (click)="loadAdminVendedores()" style="font-size: 20px">
				<i class="fas fa-people-arrows home-title-icon"></i>
				<b class="home-title">Administrar Vendedores</b>
				<span class="credilow-plan-cuota-amount credilow-plan-cuota-amount-active">
					<i class="fas fa-arrow-right home-title-second-icon"></i>
				</span>
			</div>
		</div>
	</div>
	<div *ngIf="!productionEnv">
		<mat-slide-toggle class="container" [checked]="useCredilowStyle" (change)="toggleStyle()">Cambiar estilo</mat-slide-toggle>
	</div>
</div>
